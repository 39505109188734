import * as at from "aurelia-toolkit";

export class DataStorageService {
	data: {
		usersFilterLines?: at.IFilterLine[],
		paymentsFilterLines?: at.IFilterLine[],
		accountPaymentsFilterLines?: at.IFilterLine[],
		invoicesFilterLines?: at.IFilterLine[],
		infoStatementsFilterLines?: at.IFilterLine[],
		tenancyFilterLines?: at.IFilterLine[],
		organisationsFilterLines?: at.IFilterLine[],
	} = {};
}