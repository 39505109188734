import * as au from "aurelia";
import * as app from "app";

@au.autoinject
export class DebtOptionValueConverter {
	constructor(private i18n: au.I18N) { }

	toView(value: app.WebRequestDebtOption): string {
		if (!value) {
			return undefined;
		}
		switch (value.code) {
			case "Y":
				return this.i18n.tr("DirectDebit.OutstandingOptionYes");
			case "N":
				return this.i18n.tr("DirectDebit.OutstandingOptionNo");
			case "O":
				return this.i18n.tr("DirectDebit.OutstandingOptionOverdue");
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}