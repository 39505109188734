import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class DateRange {
	constructor(private element: Element, private taskQueue: au.TaskQueue, private validationControllerFactory: at.ValidationControllerFactory) {
		this.validationController = this.validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<DateRange, Date>(x => x.editFrom).required().satisfies(x => au.moment(x).isBefore(this.editTo)).withMessage("must be less than To")
			.ensure(x => x.editTo).required().satisfies(x => au.moment(x).isAfter(this.editFrom)).withMessage("must be greater than From")
			.on(this);
	}

	validationController: au.ValidationController;

	@au.ato.bindable.date({ defaultBindingMode: au.bindingMode.twoWay })
	from: Date;

	@au.ato.bindable.date({ defaultBindingMode: au.bindingMode.twoWay })
	to: Date;

	dropdown: HTMLDivElement;

	isOpen: boolean;

	@au.observable
	editFrom: Date;
	editFromChanged() {
		this.validationController.validate({ object: this, propertyName: "editTo" });
	}

	@au.observable
	editTo: Date;
	editToChanged() {
		this.validationController.validate({ object: this, propertyName: "editFrom" });
	}

	open() {
		this.editFrom = this.from;
		this.editTo = this.to;
		this.isOpen = true;
	}

	async ok() {
		if (!(await this.validationController.validate()).valid) {
			return;
		}
		this.from = this.editFrom;
		this.to = this.editTo;
		this.isOpen = false;
		this.taskQueue.queueTask(() => au.fireEvent(this.element, "ok"));
	}

	cancel() {
		this.isOpen = false;
	}
}