import * as app from "app";

export class AccountSummariesFilterValueConverter {
	toView(value: app.AccountBalanceInfo[], filter: string): app.AccountBalanceInfo[] {
		filter = filter ? filter.toLowerCase() : null;
		return value.filter(x => !filter || x.fullAccountNo.indexOf(filter) !== -1 || x.fullAddress.toLowerCase().indexOf(filter) !== -1);
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}
