import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import CSSJSON from "css-to-json";
import { ApplicationInsights } from '@microsoft/applicationinsights-analytics-js';

@au.autoinject
export class QuickStream {
	constructor(private settingsService: app.SettingsService, private appInsights: ApplicationInsights) {
		this.logger = au.getLogger("QuickStream");
		this.settings = this.settingsService.paymentSettings.quickStream;
	}

	id: string = `payway-credit-card-${Date.now().toString()}`;
	logger: au.Logger;
	settings: app.QuickStreamSettings;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	valid: boolean;

	loading: boolean;
	frame: QuickstreamApi.ITrustedFrame;
	frameErr: any;

	labelStyle: any;
	fieldStyle: any;
	focusedStyle: any;
	invalidStyle: any;

	async attached() {
		this.loading = true;
		try {
			await at.loadScript(`${this.settings.url}/quickstream-api-1.0.min.js`);
			if (!window.QuickstreamAPI) {
				throw new Error("Could not initialise the API");
			}
			QuickstreamAPI.init({ publishableApiKey: this.settings.publishableApiKey });
			this.loadStyles();
			this.frame = await this.createFrame();
			this.setHandlers();
		}
		catch (e) {
			this.logger.error("API error", e);
			this.appInsights.trackException(e, { method: "QuickStream.attached" });
			this.frameErr = e;
		}
		finally {
			this.loading = false;
		}
	}

	async detached() {
		if (this.frame) {
			try {
				await this.teardown();
				this.frame = undefined;
			}
			catch (e) {
				this.logger.error("Teardown error", e);
				this.appInsights.trackException(e, { method: "QuickStream.detached" });
			}
		}
	}

	loadStyles() {
		let secondaryColour = this.settingsService.browserSettings.colours.secondaryColour;
		let errorColour = this.settingsService.browserSettings.colours.errorColour;

		let css = require("!css-loader!./quick-stream.css").toString();
		css = css.replace(/secondaryColour/g, secondaryColour).replace(/errorColour/g, errorColour);
		let styleJson = CSSJSON.toJSON(css);
		let style = {};
		for (let c in styleJson.children) {
			style[c] = styleJson.children[c].attributes;
		}
		this.labelStyle = style[".label"];
		this.fieldStyle = style[".field"];
		this.focusedStyle = style[".focused"];
		this.invalidStyle = style[".invalid"];
	}

	createFrame(): Promise<QuickstreamApi.ITrustedFrame> {
		let options: QuickstreamApi.ITrustedFrameConfigObject = {
			config: {
				supplierBusinessCode: this.settings.supplierBusinessCode
			},
			iframe: { width: 270, height: 420 },
			labels: { style: this.labelStyle },
			cardholderName: { style: this.fieldStyle },
			cardNumber: { style: this.fieldStyle },
			expiryDateMonth: { style: this.fieldStyle },
			expiryDateYear: { style: this.fieldStyle },
			cvn: { style: this.fieldStyle }
		};
		return new Promise((resolve, reject) => {
			QuickstreamAPI.creditCards.createTrustedFrame(options, (errors, data) => {
				if (errors) {
					reject(errors);
				}
				else {
					resolve(data.trustedFrame);
				}
			});
		});
	}

	setHandlers() {
		this.frame.setEventHandler("cardholderName", "focus", this.focusEventHandler);
		this.frame.setEventHandler("cardholderName", "blur", this.blurEventHandler);
		this.frame.setEventHandler("cardholderName", "error", this.errorEventHandler);

		this.frame.setEventHandler("cardNumber", "focus", this.focusEventHandler);
		this.frame.setEventHandler("cardNumber", "blur", this.blurEventHandler);
		this.frame.setEventHandler("cardNumber", "error", this.errorEventHandler);

		this.frame.setEventHandler("expiryDateMonth", "focus", this.focusEventHandler);
		this.frame.setEventHandler("expiryDateMonth", "blur", this.blurEventHandler);
		this.frame.setEventHandler("expiryDateMonth", "error", this.errorEventHandler);


		this.frame.setEventHandler("expiryDateYear", "focus", this.focusEventHandler);
		this.frame.setEventHandler("expiryDateYear", "blur", this.blurEventHandler);
		this.frame.setEventHandler("expiryDateYear", "error", this.errorEventHandler);

		this.frame.setEventHandler("cvn", "focus", this.focusEventHandler);
		this.frame.setEventHandler("cvn", "blur", this.blurEventHandler);
		this.frame.setEventHandler("cvn", "error", this.errorEventHandler);
	}

	focusEventHandler = (data: QuickstreamApi.IEventHandlerData) => {
		this.frame.changeStyle(data.fieldName, this.invalidFields.find(x => x === data.fieldName) ? this.invalidStyle : this.focusedStyle, null);
	};

	blurEventHandler = (data: QuickstreamApi.IEventHandlerData) => {
		this.frame.changeStyle(data.fieldName, this.invalidFields.find(x => x === data.fieldName) ? this.invalidStyle : this.fieldStyle, null);
	};

	invalidFields: string[] = [];
	errorEventHandler = (data: QuickstreamApi.IEventHandlerData) => {
		this.invalidFields.push(data.fieldName);
		this.frame.changeStyle(data.fieldName, this.invalidStyle, null);
	};

	getToken(): Promise<QuickstreamApi.ISingleUseToken> {
		return new Promise((resolve, reject) => {
			if (!this.frame) {
				reject({ message: "Error connecting with the bank" });
			}
			else {
				// reset validation
				this.invalidFields = [];
				this.frame.changeStyle("cardholderName", this.fieldStyle, null);
				this.frame.changeStyle("cardNumber", this.fieldStyle, null);
				this.frame.changeStyle("expiryDateMonth", this.fieldStyle, null);
				this.frame.changeStyle("expiryDateYear", this.fieldStyle, null);
				this.frame.changeStyle("cvn", this.fieldStyle, null);

				this.frame.submitForm((errors, data) => {
					if (errors === undefined) {
						resolve(data.singleUseToken);
					}
					else {
						errors.data = data;
						reject(errors);
					}
				});
			}
		});
	}

	teardown(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.frame.teardown((errors, data) => {
				if (errors) {
					reject(errors);
				}
				else {
					resolve(data);
				}
			});
		});
	}
}