import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class RegisterB2C {

	constructor(private msalService: app.MsalService, private alertService: at.AlertService, private taskQueue: au.TaskQueue) { }

	success: boolean;

	canActivate(params: app.IEmailConfirmationRequest) {
		if (!params || !params.code) {
			return new au.RedirectToRoute(app.Route.loginB2CError);
		}
	}

	async activate(params: app.IEmailConfirmationRequest, routeConfig: au.RouteConfig): Promise<any> {
		this.taskQueue.queueTask(() => {
			this.alertService.showProgress();
			this.msalService.registerRedirect(params.code);
		});
	}
}