import * as numeral from "numeral";

export class BalanceValueConverter {
	toView(value: number): string {
		let credit = value < 0 ? " in credit." : "";
		return numeral(Math.abs(value)).format("$0,0.00") + credit;
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}