import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Tenant {
	constructor(private element: Element, validationControllerFactory: at.ValidationControllerFactory, private dateService: at.DateService, private settingsService: app.SettingsService,
		private addressClient: app.AddressClient) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<app.WebRequestTenant, string>(x => x.surname).required().maxLength(40)
			.ensure(x => x.firstName).required().maxLength(40)
			.ensure(x => x.middleName).maxLength(40)
			.ensure(x => x.birthDate).required().then().satisfies(async x => au.moment(x).isBetween(au.moment(await this.dateService.getServerDate()).subtract(90, "year"), au.moment(await this.dateService.getServerDate()).subtract(16, "year"))).withMessage("age must be between 16 and 90 years.")
			.ensure(x => x.phone).required()
			.ensure(x => x.email).email().required()
			.ensure(x => x.streetNo).required().maxLength(12)
			.ensure(x => x.street).required().maxLength(80)
			.ensure(x => x.suburb).required().maxLength(80)
			.ensure(x => x.postCode).required()
			.rules;
		au.ValidationRules
			.ensure<Tenant, boolean>(x => x.addressLookup).satisfies(x => !!this.tenant.streetNo && !!this.tenant.street && !!this.tenant.suburb && !!this.tenant.postCode).withMessage("is required.")
			.on(this);
	}

	validationController: au.ValidationController;
	rules: au.Rule<app.WebRequestTenant, any>[][];
	settings = this.settingsService.browserSettings;
	addressLookup: boolean;

	@au.bindable
	tenant: app.WebRequestTenant;
	tenantChanged(newValue: app.WebRequestTenant, oldValue: app.WebRequestTenant) {
		if (oldValue) {
			this.validationController.removeObject(oldValue);
		}
		if (newValue && !this.readonly) {
			this.validationController.addObject(newValue, this.rules);
			this.updateAddressLookup();
		}
	}

	@au.bindable
	newTenant: boolean;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	readonly: boolean;
	readonlyChanged() {
		if (this.readonly) {
			this.validationController.removeObject(this.tenant);
		}
		else {
			this.validationController.addObject(this.tenant, this.rules);
		}
	}

	detached() {
		if (this.tenant) {
			this.validationController.removeObject(this.tenant);
		}
	}

	remove() {
		au.fireEvent(this.element, "remove");
	}

	getAddresses = (criteria: au.ILookupOptionsFunctionParameter<app.Address>): Promise<app.Address[]> => {
		if (criteria.filter !== undefined) {
			if (criteria.filter === "") {
				return Promise.resolve([]);
			}
			else {
				return this.addressClient.autocomplete(criteria.filter);
			}
		}
		else {
			return Promise.resolve([criteria.value]);
		}
	}

	async prevAddressSelected(address: app.Address) {
		if (!address) {
			return;
		}
		if (address.detailsInSeparateCall) {
			address = await this.addressClient.metadata(address.id);
		}
		this.tenant.unitNo = address.flatNumber ? address.flatNumber.toString() : undefined;
		this.tenant.streetNo = address.numberFirst ? address.numberFirst.toString() : undefined;
		this.tenant.street = `${address.streetName} ${address.streetType}`;
		this.tenant.suburb = address.suburb;
		this.tenant.postCode = address.postCode ? parseInt(address.postCode, 10) : undefined;
		this.updateAddressLookup();
	}

	updateAddressLookup() {
		this.addressLookup = !this.tenant.unitNo && !this.tenant.streetNo && !this.tenant.street && !this.tenant.suburb && !this.tenant.postCode;
		console.log("updateLookupAddress", this.addressLookup);
	}

	toggleAddressLookup() {
		this.addressLookup = !this.addressLookup;
	}
}