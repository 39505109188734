import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class BalanceCheck {
	constructor(validationControllerFactory: at.ValidationControllerFactory, private accountClient: app.AccountClient, private settingsService: app.SettingsService,
		private twAuthService: app.TwAuthService) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.validateTrigger = au.validateTrigger.manual;
		au.ValidationRules
			.ensure<BalanceCheck, app.Roles>(x => x.accountNumber)
			/**/.required().withMessage("Account number not recognised. Please check that your account number is entered as it is displayed on your bill.")
			/**/.satisfies(x => !this.accountNotRecognised).withMessage("Account number not recognised. Please check that your account number is entered as it is displayed on your bill.")
			.on(BalanceCheck);
	}

	externalPaymentUrl: string;
	validationController: au.ValidationController;
	busy: boolean;
	error: boolean;
	accountNotRecognised: boolean;
	browserSettings = this.settingsService.browserSettings;

	accountNumber: string;

	@au.observable
	rawAccountNumber: string;
	rawAccountNumberChanged() {
		this.balance = null;
		this.error = false;
		this.accountNotRecognised = false;
		this.validationController.reset();
	}

	@au.observable
	balance: number = null;
	balanceChanged() {
		let url = this.settingsService.paymentSettings.externalPaymentUrl;
		if (url && this.accountNumber && this.balance > 0) {
			this.externalPaymentUrl = url.replace("${account}", this.accountNumber).replace("${amount}", this.balance.toString());
		}
		else {
			this.externalPaymentUrl = null;
		}
	}

	activate() {
		this.twAuthService.authService.config.storageChangedReload = false;
		this.twAuthService.authService.config.storageChangedRedirect = undefined;
		this.twAuthService.authService.config.expiredRedirect = undefined;
	}

	async go() {
		if (!(await this.validationController.validate()).valid) {
			return;
		}

		try {
			this.busy = true;
			this.balance = await this.accountClient.getBalanceAnonymous(this.accountNumber);
			this.accountNotRecognised = false;
			this.error = false;
		}
		catch (e) {
			var ex = e as at.Exception;
			if (ex instanceof app.NotFoundException) {
				this.balance = null;
				this.accountNotRecognised = true;
			}
			else {
				this.balance = null;
				this.error = true;
			}
		}
		finally {
			this.busy = false;
		}
		await this.validationController.validate();
	}
}