import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

class OrganisationExt extends app.Organisation2 {
	abnRaw?: string;
}

@au.autoinject
export class EditOrganisation extends app.EditBase {
	constructor(private organisationClient: app.OrganisationClient, alertService: at.AlertService, private eventAggregator: au.EventAggregator,
		validationControllerFactory: at.ValidationControllerFactory, authService: app.IAuthService) {
		super(alertService, validationControllerFactory, authService);
		this.rules = au.ValidationRules
			.ensure<OrganisationExt, string>(x => x.name).required().maxLength(80)
			.ensure(x => x.abn).required().withMessage("must include 11 digits.").when(x => !!x.abnRaw)
			.rules;
	}

	rules: au.Rule<{}, any>[][];
	organisation: OrganisationExt;

	async load(id: string) {
		if (this.organisation) {
			this.validationController.removeObject(this.organisation);
		}
		if (id) {
			this.organisation = await this.organisationClient.get(parseInt(id));
		}
		else {
			this.organisation = new OrganisationExt();
		}
		this.organisation.abnRaw = this.organisation.abn;
		this.validationController.addObject(this.organisation, this.rules);
	}

	async saveInternal(): Promise<string> {
		var o = await this.organisationClient.post(this.organisation);
		this.organisation.id = o.id;
		this.organisation.timestamp = o.timestamp;
		return o.id.toString();
	}

}