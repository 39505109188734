import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Home {
	constructor(private authService: app.IAuthService, private settingsService: app.SettingsService) {
		this.settings = this.settingsService.browserSettings;
	}

	settings: app.BrowserSettings;

	async canActivate(params: any, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<boolean | au.RedirectToRoute> {
		if (!await this.authService.isAuthenticatedAsync()) {
			return new au.RedirectToRoute(app.Route.login);
		}
		let token = this.authService.getTokenPayload();
		switch (token.role) {
			case app.Roles.admin:
			case app.Roles.supervisor:
				return new au.RedirectToRoute(app.Route.adminDashboard);
			case app.Roles.staff:
				return new au.RedirectToRoute(app.Route.users);
			case app.Roles.businessCustomer:
			case app.Roles.residentialCustomer:
				return new au.RedirectToRoute(app.Route.customer);
			case app.Roles.agent:
				return new au.RedirectToRoute(app.Route.agent);
			case app.Roles.agencyManager:
				return new au.RedirectToRoute(app.Route.agencyManager);
			case app.Roles.conveyancer:
				return new au.RedirectToRoute(app.Route.conveyancer);
		}
	}
}