import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class PaymentArrangement {
	constructor(private webRequestClient: app.WebRequestClient, private validationControllerFactory: at.ValidationControllerFactory, private alertService: at.AlertService,
		private router: au.Router, private i18n: au.I18N, private dateService: at.DateService, private accountClient: app.AccountClient,
		private authService: app.IAuthService, private taskQueue: au.TaskQueue) {
		this.validationController = this.validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensureEx((x: PaymentArrangement) => x.selectedAccount).required()
			/**/.satisfies(x => !x || !x.payArrangement || x.payArrangement.fkPayFrequency !== PayFrequency.InFull).withMessage(this.i18n.tr("PaymentArrangement.PaymentExtensionError"))
			/**/.satisfies(x => !x || !!x.lastInvoice).withMessage("PaymentArrangement.NoInvoicesError")
			.ensure(x => x.payFrequency).required()
			.rules;
		this.validationController.addObject(this, this.rules);
		this.validationController.subscribe(e => {
			const selectedAccountResults = e.results.filter(x => x.propertyName === "selectedAccount")
			this.selectedAccountValid = !!selectedAccountResults.length && !selectedAccountResults.find(x => !x.valid);
		});
	}

	validationController: au.ValidationController;
	rules: au.Rule<PaymentArrangement, any>[][];
	accounts: app.AccountBalanceInfo[];
	payFrequencies: app.PayFrequency[];

	@au.observable
	payFrequency: app.PayFrequency;

	amount: number;
	startDate: Date;
	isTherePendingRequest: boolean;

	@au.observable
	selectedAccount: app.AccountBalanceInfo;
	async selectedAccountChanged() {
		this.isTherePendingRequest = await this.webRequestClient.isTherePendingRequest(this.selectedAccount.pkAccount, WebRequestType.PaymentArrangement);
	}
	selectedAccountValid: boolean;

	async canActivate(): Promise<any> {
		return await this.alertService.usingProgress(async () => {
			this.accounts = await this.accountClient.getAccountSummaries(true, false);
			if (!this.accounts.length) {
				await this.alertService.alert("You don't have accounts eligible for payment agrrangement.", "warning", "orange");
				return false;
			}
			else if (this.accounts.length === 1) {
				this.selectedAccount = this.accounts[0];
			}
		}, async e => { await this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e); return false; });
	}

	bind() {
		if (this.selectedAccount) {
			this.validationController.validate({ object: this, propertyName: "selectedAccount" });
		}
	}

	async activate(params: IHaveId) {
		await this.alertService.usingProgress(async () => {
			if (params && params.id) {
				this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
			}
			this.payFrequencies = await this.webRequestClient.getPayFrequencies();
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async submit() {
		let res = await this.validationController.validate();
		if (!res.valid) {
			await this.alertService.error("Please correct the invalid fields");
			return;
		}
		if (!await this.alertService.confirm("Are you sure you would like to submit this Payment Arrangement application?", "warning", "orange")) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			let wrpa = new app.WebRequestPaymentArrangement({
				fk_pay_frequency: this.payFrequency.code,
				amount: this.amount,
				start_date: this.startDate.asUtc(),
				pk: 0,
				fk_web_request: 0
			});
			let request = new app.WebRequest({ fkAccount: this.selectedAccount.pkAccount, paymentArrangement: wrpa, pk: 0, guid: null, dateRequested: null });
			await this.webRequestClient.submitPaymentArrangement(request);
			await this.alertService.alert(app.Strings.webRequestSuccess);
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.criticalError("Error occurred while submitting the payment arrangement application", e));
	}

}