import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Payment {
	constructor(private paymentClient: app.PaymentClient, private alertService: at.AlertService, private authService: app.IAuthService) { }

	@au.bindable
	payment: app.Payment;

	@au.bindable
	showDetail: boolean;

	isCustomer: boolean;

	PaymentStatus = app.PaymentStatus;

	attached() {
		let role = this.authService.getTokenPayload().role;
		this.isCustomer = role == app.Roles.businessCustomer || role == app.Roles.residentialCustomer;
	}

	async sendReceipt() {
		await this.alertService.usingProgress(async () => {
			await this.paymentClient.sendReceipt(this.payment.id);
			await this.alertService.alert("The receipt has been sent to your email");
		}, e => this.alertService.error("Error ocurred while sending the receipt"));
	}
}
