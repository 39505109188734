import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { AlertModal } from "aurelia-toolkit/src/elements/alert-modal/alert-modal";

@au.autoinject
export class TwAuthService implements app.IAuthService {
	constructor(public authService: at.AuthService, private router: au.Router, private fetchConfig: au.FetchConfig, private http: au.HttpClient,
		private eventAggregator: au.EventAggregator, private dateService: at.DateService, private templatingEngine: au.TemplatingEngine, private settingsService: app.SettingsService) {
		this.logger = au.getLogger("TwAuthService");
	}

	logger: au.Logger;
	refreshToken: string;

	isAuthenticatedAsync(): Promise<boolean> {
		try {
			return this.authService.isAuthenticatedAsync();
		}
		catch {
			return Promise.resolve(false);
		}
	}

	getAccessToken(): Promise<string> {
		return Promise.resolve(this.authService.getAccessToken());
	}

	getTokenPayload(): at.ITokenPayload {
		return this.authService.getTokenPayload();
	}

	async logout(noRedirect: boolean): Promise<any> {
		await this.authService.logout("");
		if (!noRedirect) {
			this.router.navigateToRoute(app.Route.login);
		}
	}

	async login(credentials?: any): Promise<any> {
		await this.authService.login(credentials, null);
	}

	async init() {
		// !!! The order of calls here is critical due to possible client-server time differences
		// !!! Token expiration must be adjusted before any other calls are made

		// this will force this.dateService.differenceWithServer calculation which is needed for correct token expiry date
		await this.dateService.getServerDate();

		// supply a custom function to mitigate possible client-server time difference by calculating a "local" token expiry date
		this.authService.config.getExpirationDateFromResponse = (r: app.GenerateTokenResponse) => {
			if (!r) {
				return undefined;
			}
			return au.moment(r.tokenExpiryDate).add(this.dateService.differenceWithServer, "milliseconds").unix();
		};

		this.fetchConfig.configure(this.http);
		// this will force token update if there is a refresh token
		await this.isAuthenticatedAsync();
		this.http.configure(c => {
			c.withInterceptor({ request: r => this.addRememberMeIndicator(r), response: r => this.useNewToken(r) });
		});

		if (this.settingsService.browserSettings.sessionExpiryWarningThreshold) {
			this.eventAggregator.subscribe("authentication-change", () => this.closeWarningOnLogout());
			au.PLATFORM.global.setTimeout(() => this.monitorExpiry(), 3000);
		}
	}

	async useNewToken(r: Response): Promise<Response> {
		let newToken = r.headers.get("new_token");
		let refreshToken = r.headers.get("refresh_token");
		if (newToken || refreshToken) {
			this.logger.debug("New tokens spotted", newToken, refreshToken);
			// save new token keeping the existing refresh token if there is no new one
			this.authService.setResponseObject({
				token: newToken,
				refreshToken: refreshToken || this.authService.getRefreshToken(),
				tokenExpiryDate: r.headers.get("token_expiry_date")
			});
		}

		return r;
	}

	addRememberMeIndicator(r: Request): Request {
		if (this.isRefreshTokenSet()) {
			r.headers.set("remember-me", "true");
		}
		return r;
	}

	isRefreshTokenSet() {
		let refreshToken = this.authService.getRefreshToken();
		return refreshToken && refreshToken !== "none";
	}

	alertModal: AlertModal;
	async closeWarningOnLogout() {
		if (!await this.authService.isAuthenticatedAsync() && this.alertModal) {
			this.alertModal.options.button2Click();
		}
	}

	previousExp: number;
	async monitorExpiry() {
		if (await this.authService.isAuthenticatedAsync() && !this.isRefreshTokenSet()) {
			let exp = await this.authService.getExp();
			let warningDate = au.moment.unix(exp).subtract(this.settingsService.browserSettings.sessionExpiryWarningThreshold, "seconds");
			let date = au.moment();
			this.logger.debug(`date=${au.moment(date).format("hh:mm:ss")}, expiryDate=${au.moment.unix(exp).format("hh:mm:ss")}, warningDate=${warningDate.format("hh:mm:ss")}`);
			if (date.isAfter(warningDate) && exp !== this.previousExp && this.router.currentInstruction.config.name !== app.Route.balanceCheck) {
				// do not warn for the same token
				this.previousExp = exp;
				await this.displayExpiryWarning();
			}
		}
		au.PLATFORM.global.setTimeout(() => this.monitorExpiry(), 3000);
	}

	private showModal(message: string, icon: string, iconColour: string, button1Text: string, button2Text: string): Promise<boolean> {
		let html = document.createElement("alert-modal");
		let view = this.templatingEngine.enhance(html);
		view.bind({});
		view.attached();
		document.querySelector("[aurelia-app]").appendChild(html);
		this.alertModal = html.au["alert-modal"].viewModel;
		return new Promise<boolean>(resolve => this.alertModal.open({
			icon,
			iconColour,
			message,
			button1Text,
			button2Text,
			button1Click: () => resolve(true),
			button2Click: () => resolve(false)
		})).then(x => {
			html.remove();
			view.unbind();
			view.detached();
			this.alertModal = null;
			return x;
		});
	}


	async displayExpiryWarning() {
		if (await this.showModal("Your session is about to expire. Would you like to extend it?", "warning", "orange", "Yes", "No")) {
			await this.authService.getMe();
		}
		else {
			if (await this.isAuthenticatedAsync()) {
				this.logout(false);
			}
		}
	}
}
