import * as au from "aurelia";
import * as app from "app";

@au.autoinject
export class Farewell {
	constructor(private settingsService: app.SettingsService) {
		this.settings = settingsService.browserSettings;
	}

	settings: app.BrowserSettings;
}