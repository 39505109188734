import * as app from "app";

export class PaymentStatusValueConverter {
	toView(value: app.PaymentStatus): string {
		return app.PaymentStatuses.find(x => x.code === value).description;
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}