import * as au from "aurelia";
import * as app from "app";

@au.autoinject
export class LoginB2CError {
	constructor(private msalService: app.MsalService) {
	}

	login() {
		this.msalService.loginRedirect();
	}
}