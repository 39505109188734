import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class EditAddress {
	constructor(validationControllerFactory: at.ValidationControllerFactory, private taskQueue: au.TaskQueue, private bindingEngine: au.BindingEngine) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<app.IAddressEx, string>(x => x.street).required().when(x => x.isChanged).maxLength(50)
			.ensure(x => x.suburb).required().when(x => x.isChanged).maxLength(50)
			.ensure(x => x.fkState).required().when(x => !x.isOverseas && x.isChanged)
			.ensure(x => x.postcode).required().when(x => !x.isOverseas && x.isChanged)
			.ensure(x => x.postalName).required().when(x => x.isChanged).then()
			.satisfies(x => {
				var lines = x.split("\n").filter(l => l.trim());
				return lines.length <= 2;
			}).withMessage("must have maximum 2 lines.")
			.satisfies(x => {
				var lines = x.split("\n").filter(l => l.trim());
				return !lines.find(l => l.length > 50);
			}).withMessage("must have lines shorter than 50 characters.")
			.ensure(x => x.dateFrom).required().when(x => !!x.fkAccount && x.isChanged)
			.ensure(x => x.dateTo).satisfies((x, a) => {
				return !x || au.moment(x).isAfter(a.dateFrom);
			}).when(x => !!x.fkAccount).withMessage("must be after 'From' date.")
			.rules;
	}

	public validationController: au.ValidationController;
	rules: au.Rule<app.IAddressEx, any>[][];
	isOverseasSubscribtion: au.Disposable;

	@au.bindable
	address: app.IAddressEx;
	addressChanged(newValue: app.IAddressEx, oldValue: app.IAddressEx) {
		if (oldValue) {
			this.validationController.removeObject(oldValue);
		}
		if (this.isOverseasSubscribtion) {
			this.isOverseasSubscribtion.dispose();
		}
		this.validationController.addObject(this.address, this.rules);
		this.validationController.reset();
		this.taskQueue.queueTask(() => this.validationController.reset());
		this.isOverseasSubscribtion = this.bindingEngine.propertyObserver(this.address, "isOverseas").subscribe(() => this.isOverseasChanged());
	}

	isOverseasChanged() {
		if (this.address.isOverseas) {
			this.address.fkState = null;
			this.address.postcode = null;
		}
	}

	@au.bindable
	states: app.State[];

	dateFromOptions = {
		onClose: () => {
			this.taskQueue.queueTask(() => this.validationController.validate({ object: this.address, propertyName: "DateTo" }));
		}
	}

	resetChanges() {
		this.address.init(this.address.old.toJSON());
		this.taskQueue.queueTask(() => {
			this.address.isChanged = false;
			this.validationController.reset();
		});
	}

	changed() {
		this.address.isChanged = true;
	}
}
