import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ResetPassword {

	constructor(private userClient: app.UserClient, private alertService: at.AlertService, private settingsService: app.SettingsService,
		validationControllerFactory: at.ValidationControllerFactory, private i18n: au.I18N) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<ResetPassword, string>(x => x.password).required().then().password(this.settingsService.passwordOptions)
			.ensure(x => x.passwordConfirm).required().satisfiesRule("mustMatch", "password")
			.on(ResetPassword);
	}

	confirmation: app.IEmailConfirmationRequest;
	password: string;
	passwordConfirm: string;
	passwordReset: boolean;
	validationController: au.ValidationController;
	isTokenValid: boolean;

	async activate(params: app.IEmailConfirmationRequest, routeConfig: au.RouteConfig): Promise<any> {
		this.confirmation = params;
		await this.alertService.usingProgress(async () => {
			this.isTokenValid = await this.userClient.isResetPasswordTokenValid(new app.EmailConfirmationRequest(this.confirmation));
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async reset() {
		let errors = await this.validationController.validate();
		if (!errors.valid) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			this.confirmation.password = this.password;
			await this.userClient.resetPassword(new app.EmailConfirmationRequest(this.confirmation));
			this.passwordReset = true;
		}, e => this.alertService.error(this.i18n.tr("ResetPassword.InvalidToken")));
	}

	capsLockOn: boolean;
	passwordKeyUp(event: KeyboardEvent) {
		this.capsLockOn = event.getModifierState("CapsLock");
	}

}
