import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class RegistrationsWidget extends app.BaseWidget {
	constructor(private analyticsClient: app.AnalyticsClient, dateService: at.DateService) {
		super(dateService);
	}

	data: Chart.ChartData = { datasets: [], labels: [] };
	options: Chart.ChartOptions = {
		responsive: true,
		legend: { display: false },
		scales: { yAxes: [{ ticks: { callback: v => Math.floor(v) === v ? v : null } }] },
		plugins: { datalabels: { display: false } }
	};

	async attached() {
		this.loadDateRange("registrationsRange", this.range);
		this.load();
	}

	async load() {
		try {
			this.loading = true;
			let response = await this.analyticsClient.getMonthlyUserRegistrations(new app.GetStatisticsRequest({ from: this.range.from.asUtc(), to: this.range.to.asUtc() }))

			let labels: string[] = [];
			let data: number[] = [];
			for (let k of response.monthlyUserRegistrations) {
				labels.push(`${k.month}/${k.year}`);
				data.push(k.count);
			}
			let colour = au.colors.palettes.blue.base;

			this.data = {
				datasets: [{ backgroundColor: colour, borderColor: colour, data, fill: false }],
				labels
			};


			this.saveDateRange("registrationsRange", this.range);
		}
		finally {
			this.loading = false;
		}
	}
}