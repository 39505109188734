import * as au from "aurelia";
import * as at from "aurelia-toolkit";
import * as app from "app";
import { saveAs } from "file-saver";

@au.autoinject
export class PaymentService {
	constructor(private alertService: at.AlertService, private accountClient: app.AccountClient, private i18n: au.I18N) { }

	canMakeAnotherPayment(p: app.Payment): boolean {
		let failedPaymentStatuses: app.PaymentStatus[] = [app.PaymentStatus.Failed, app.PaymentStatus.NotSent, app.PaymentStatus.RolledBack];
		return !p || failedPaymentStatuses.indexOf(p.status) >= 0;
	}

	getStatusSummary(payment: app.Payment): IPaymentStatusSummary {
		switch (payment.status) {
			case app.PaymentStatus.Approved: return { icon: "done", colour: "green", description: this.i18n.tr("AccountPayment.PaymentApproved") };
			case app.PaymentStatus.ConditionallyApproved: return { icon: "done", colour: "orange", description: this.i18n.tr("AccountPayment.PaymentConditionalyApproved") };
			case app.PaymentStatus.NotSent:
			case app.PaymentStatus.Failed: return { icon: "error", colour: "red", description: this.i18n.tr("AccountPayment.PaymentFailed") };
			case app.PaymentStatus.Indeterminate: return { icon: "watch_later", colour: "blue", description: this.i18n.tr("AccountPayment.PaymentIndeterminate") };
			case app.PaymentStatus.RolledBack: return { icon: "error", colour: "red", description: this.i18n.tr("AccountPayment.PaymentRolledBack") };
		}

	}

	savePayments(payments: app.AccountPayment[]) {
		let s = `"Date","Amount","Source","Account Number","Address"`;
		payments.forEach(x => s += `\n"${au.moment(x.date).format("DD/MM/YYYY")} ","${x.amount} ","${x.paymentSource} ","${x.fullAccountNo} ","${x.fullAddress} "`);
		let csv = new Blob([s], { type: "text/csv" });
		saveAs(csv, "payments.csv");
	}

	async requestPaymentExtension(balanceInfo: app.AccountBalanceInfo): Promise<any> {
		if (!await this.alertService.confirm(`Are you sure you want to request a payment extension to pay in full by ${balanceInfo.paymentExtensionDueDate.toLocaleDateString("en-AU")}?`, "warning", "orange")) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			await this.accountClient.requestPaymentExtension(new app.PaymentExtensionRequest({
				fkAccount: balanceInfo.pkAccount,
				amount: balanceInfo.amount,
				dateNextDue: balanceInfo.paymentExtensionDueDate.asUtc()
			}));
			balanceInfo.paymentExtensionDueDate = null;
			balanceInfo.hasPendingPaymentExtensionRequest = true;
			await this.alertService.alert(this.i18n.tr("PaymentExtension.SubmitSuccess"));
		}, e => this.alertService.error(this.i18n.tr("PaymentExtension.SubmitError")));
	}
}