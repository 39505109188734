import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Customer {
	constructor(private accountClient: app.AccountClient, private invoiceService: app.InvoiceService, private alertService: at.AlertService,
		private i18n: au.I18N, private dateService: at.DateService, private paymentService: app.PaymentService, private settingsService: app.SettingsService) {
	}

	accountSummaries: app.AccountBalanceInfo[];
	Routes = app.Route;
	showInvoice = !!this.i18n.tr("Customer.LatestInvoiceLabel");
	serverDate: Date;
	isViewAccountVisible = !!this.i18n.tr("Customer.ViewAccount");

	@au.observable
	activeOnly: boolean;
	async activeOnlyChanged() {
		if (this.activated) {
			this.load();
		}
	}

	async canActivate() {
		if (await this.accountClient.getAccountsCount() >= this.settingsService.browserSettings.bulkCustomer.bulkCustomerThreshold) {
			return new au.RedirectToRoute(app.Route.bulkCustomer);
		}
		else {
			return true;
		}
	}

	activated: boolean;
	async activate() {
		this.activeOnly = true;
		await this.load();
		await this.invoiceService.checkEBilling(this.accountSummaries);
		this.activated = true;
	}

	async load() {
		return this.alertService.usingProgress(async () => {
			await Promise.all([
				this.accountClient.getAccountSummaries(this.activeOnly, false).then(x => this.accountSummaries = x),
				this.dateService.getServerDate().then(x => this.serverDate = x)
			]);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	getLastInvoice(a: app.AccountBalanceInfo) {
		this.alertService.usingProgress(async () => {
			await this.invoiceService.openPdf(a.lastInvoice.pk);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async requestPaymentExtension(balanceInfo: app.AccountBalanceInfo): Promise<any> {
		await this.paymentService.requestPaymentExtension(balanceInfo);
	}

}