import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ConfirmEmail {

	constructor(private authService: app.IAuthService, private userClient: app.UserClient, private router: au.Router) { }

	success: boolean;

	async activate(params: app.IEmailConfirmationRequest, routeConfig: au.RouteConfig): Promise<any> {
		await this.authService.logout(true);
		try {
			await this.userClient.confirmEmail(new app.EmailConfirmationRequest(params));
			this.success = true;
		}
		catch (e) {
		}
	}
}