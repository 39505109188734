import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Bpoint {
	constructor(private element: Element, private settingsService: app.SettingsService, private bpointClient: app.BpointClient, validationControllerFactory: at.ValidationControllerFactory,
		private taskQueue: au.TaskQueue) {
		this.logger = au.getLogger("Bpoint");
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<Bpoint, string>(x => x.cardholderName).required().maxLength(50)
			.on(this);
	}

	logger: au.Logger;
	validationController: au.ValidationController;
	frameUrl: string;
	loading: boolean;
	error: boolean;
	settings = this.settingsService.paymentSettings;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	authKey: string;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	cardholderName: string;

	async attached() {
		try {
			this.loading = true;
			this.authKey = await this.bpointClient.getAuthKey();
			this.frameUrl = `${this.settings.bpoint.url}/txns/iframe/${this.authKey}`;
			this.cardholderName = null;
			this.taskQueue.queueTask(() => this.validationController.reset())
		}
		catch (e) {
			this.error = true;
		}
		finally {
			this.loading = false;
		}
	}
}