import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ChangeEmail {

	constructor(private authService: app.IAuthService, private userClient: app.UserClient, private router: au.Router) { }

	success: boolean;

	async activate(params: app.IChangeEmailRequest, routeConfig: au.RouteConfig): Promise<any> {
		try {
			await this.userClient.changeEmail(new app.ChangeEmailRequest(params));
			await this.authService.logout(true);
			this.success = true;
		}
		catch (e) {
		}
	}
}