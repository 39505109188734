import * as au from "aurelia";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ToastHintCustomAttribute {
	constructor(private element: Element, private alertService: at.AlertService) { }

	value: string;
	label: HTMLLabelElement;

	attached() {
		if (this.element.tagName !== "MD-INPUT") {
			return;
		}

		this.label = this.element.querySelector(".input-field>label") as HTMLLabelElement;
		let a = document.createElement("a");
		a.classList.add("hint");
		let i = document.createElement("i");
		i.classList.add("material-icons");
		i.classList.add("tiny");
		i.classList.add("cursor-pointer");
		i.style.verticalAlign = "middle";
		i.innerText = "help_outline";
		i.addEventListener("mousedown", () => this.alertService.confirmToast(this.value), true);
		a.appendChild(i);
		this.label.appendChild(a);
	}

	detached() {
		if (!this.label) {
			return;
		}

		let hint = this.label.querySelector("a.hint");
		if (hint) {
			this.label.removeChild(hint);
		}
	}
}