import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Profile extends app.EditBase {
	constructor(alertService: at.AlertService, private userClient: app.UserClient, private eventAggregator: au.EventAggregator,
		validationControllerFactory: at.ValidationControllerFactory, authService: app.IAuthService, private emailClient: app.EmailClient, private i18n: au.I18N,
		private msalService: app.MsalService) {
		super(alertService, validationControllerFactory, authService);
		au.ValidationRules
			.ensure<Profile, string>(x => x.email).required().email()
			.ensure(x => x.surname).required().maxLength(80)
			.ensure(x => x.otherNames).required().maxLength(80)
			.ensure(x => x.phoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("04")).withMessage("must start with 04")
			/**/.satisfies(x => !this.rawPhoneNumber || !!x).withMessage("must have 10 digits")
			.ensure(x => x.homePhoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("0")).withMessage("must start with an area code")
			/**/.satisfies(x => !this.rawHomePhoneNumber || !!x).withMessage("must have 10 digits and start with an area code")
			.on(this);
	}

	user: app.UserInfo;
	canEditNames: boolean;
	isResidential: boolean;
	isNonResidential: boolean;
	isB2CEnabled: boolean;
	emailInvoices: boolean = true;
	rawPhoneNumber: string;
	allowEbilling: boolean;

	@au.computedFrom("user.surname")
	get surname() {
		return this.user.surname;
	}
	set surname(value: string) {
		this.user.surname = value;
	}

	@au.computedFrom("user.otherNames")
	get otherNames() {
		return this.user.otherNames;
	}
	set otherNames(value: string) {
		this.user.otherNames = value;
	}

	@au.computedFrom("user.email")
	get email() {
		return this.user.email;
	}
	set email(value: string) {
		this.user.email = value;
		this.emailChanged = true;
	}

	@au.computedFrom("user.phoneNumber")
	get phoneNumber() {
		return this.user.phoneNumber;
	}
	set phoneNumber(value: string) {
		this.user.phoneNumber = value;
	}

	@au.computedFrom("user.homePhoneNumber")
	get homePhoneNumber() {
		return this.user.homePhoneNumber;
	}
	set homePhoneNumber(value: string) {
		this.user.homePhoneNumber = value;
	}

	rawHomePhoneNumber: string;

	emailChanged: boolean;

	async load(id: string) {
		await Promise.all([
			this.userClient.getSelf().then(x => this.user = x),
			this.userClient.allowEBillingOnEmailUpdate().then(x => this.allowEbilling = x)
		]);
		this.isB2CEnabled = this.msalService.isEnabled();
		this.canEditNames = this.authService.getTokenPayload().permission.includes(app.PermissionCode[app.PermissionCode.EditProfileName]);
		this.isResidential = this.authService.getTokenPayload().role === app.Roles.residentialCustomer;
		this.isNonResidential = this.authService.getTokenPayload().role === app.Roles.businessCustomer;
		this.emailChanged = false;
	}

	async saveInternal(): Promise<string> {
		try {
			var u = await this.userClient.patchProfile(new app.PatchProfileRequest({
				surname: this.user.surname,
				otherNames: this.user.otherNames,
				email: this.user.email,
				emailInvoices: this.emailInvoices,
				phoneNumber: this.user.phoneNumber,
				homePhoneNumber: this.user.homePhoneNumber,
				timestamp: this.user.timestamp
			}));
		}
		catch (e) {
			if (e instanceof app.EmailTakenException) {
				e = new Error("This email is already taken. Please choose another one.");
			}
			throw e;
		}
		if (this.user.email !== u.email) {
			await this.alertService.alert("We've sent an email to your new address which you must confirm before the change becomes permanent.")
		}
		this.user.id = u.id;
		this.user.timestamp = u.timestamp;
		this.user.email = u.email;
		this.eventAggregator.publish(app.Events.profileChanged);
		return u.id;
	}

	async sendResetPassword() {
		if (this.emailChanged || this.isB2CEnabled) {
			return;
		}

		try {
			this.alertService.showProgress();
			await this.emailClient.sendResetPasswordEmail(this.email);
			this.alertService.alert(this.i18n.tr("Login.LinkHasBeenSent"));
		}
		catch (e) {
			this.alertService.error("Error sending the link");
		}
		finally {
			this.alertService.hideProgress();
		}
	}
}