import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class WebRequestsWidget extends app.BaseWidget {
	constructor(private analyticsClient: app.AnalyticsClient, dateService: at.DateService) {
		super(dateService);
	}

	data: Chart.ChartData = { datasets: [], labels: [] };
	options: Chart.ChartOptions = {
		responsive: true,
		legend: { display: false },
		scales: { xAxes: [{ ticks: { suggestedMin: 0 } as Chart.LinearTickOptions }] }
	};

	async attached() {
		this.loadDateRange("webRequestsRange", this.range);
		this.load();
	}

	async load() {
		try {
			this.loading = true;
			let response = await this.analyticsClient.getWebRequestCounts(new app.GetStatisticsRequest({ from: this.range.from.asUtc(), to: this.range.to.asUtc() }))

			let labels: string[] = [];
			let data: number[] = [];
			for (let k in response.webRequests) {
				labels.push(k);
				data.push(response.webRequests[k]);
			}
			let colours = [
				au.colors.palettes.green.base, au.colors.palettes.red.base, au.colors.palettes.grey.base, au.colors.palettes.purple.base, au.colors.palettes.indigo.base,
				au.colors.palettes.blue.base, au.colors.palettes.cyan.base, au.colors.palettes.yellow.base, au.colors.palettes.orange.base, au.colors.palettes.brown.base
			];

			this.data = {
				datasets: [{ backgroundColor: colours, hoverBorderColor: colours, data }],
				labels
			};

			this.saveDateRange("webRequestsRange", this.range);
		}
		finally {
			this.loading = false;
		}
	}
}