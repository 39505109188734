import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import * as jwtDecode from "jwt-decode";
import { ApplicationInsights } from '@microsoft/applicationinsights-analytics-js';
import { PublicClientApplication, LogLevel, AuthenticationResult, AccountInfo, AuthError } from '@azure/msal-browser';

@au.autoinject
export class MsalService implements app.IAuthService {
	constructor(private settingsService: app.SettingsService, private router: au.Router, private taskQueue: au.TaskQueue, private http: au.HttpClient,
		private dateService: at.DateService, private eventAggregator: au.EventAggregator, private appInsights: ApplicationInsights) {
		this.logger = au.getLogger("MsalService");
	}

	logger: au.Logger;
	app: PublicClientApplication;

	async init() {
		// fetch the date so that the diff is calculated before a token is requested
		await this.dateService.getServerDate();
		this.http.configure(c => {
			c.withInterceptor({
				request: async r => {
					const token = await this.getAccessToken();
					if (token) {
						r.headers.set("Authorization", `Bearer ${token}`);
					}
					return r;
				},
				responseError: (e, r) => {
					if (e instanceof at.UnauthorizedException) {
						this.taskQueue.queueTask(() => this.router.navigateToRoute(app.Route.expired));
					}
					throw e;
				}
			});
		});

		this.app = new PublicClientApplication({
			auth: {
				clientId: this.settingsService.azureB2CSettings.clientId,
				authority: this.settingsService.azureB2CSettings.signInAuthority,
				knownAuthorities: [this.settingsService.azureB2CSettings.knownAuthority],
				redirectUri: `${location.origin}${location.pathname}${location.pathname.endsWith('/') ? '' : '/'}`,
				navigateToLoginRequestUrl: false,
				postLogoutRedirectUri: this.settingsService.azureB2CSettings.postLogoutRedirectUri
			},
			cache: {
				storeAuthStateInCookie: true,
				cacheLocation: "localStorage",

			},
			system: {
				loadFrameTimeout: this.settingsService.azureB2CSettings.loadFrameTimeout,
				loggerOptions: { loggerCallback: (level: LogLevel, message: string) => this.logger.debug(message, level) }
			}
		});
		try {
			// this will process the hash state in the URL if there is any
			await this.app.handleRedirectPromise();
		} catch (e) {
			const error = e as AuthError;
			if (error.errorCode === 'access_denied' && error.errorMessage.startsWith('AADB2C90118:')) {
				// user requested to change the password
				// successful password change will redirect to the application root with the id token of the password reset authority
				this.app.loginRedirect({
					scopes: [this.settingsService.azureB2CSettings.apiScope],
					authority: this.settingsService.azureB2CSettings.passwordResetAuthority
				});
			} else {
				// at this point the root view has not been attached and routes are not configured
				// plus app insights are not initialised yet
				// so we have to wait until this happens
				this.rootAttachedSubscription = this.eventAggregator.subscribe('root-attached', () => this.taskQueue.queueTask(() => {
					this.appInsights.trackEvent({ name: 'MsalService:PublicClientApplication:Error', properties: { errorCode: error.errorCode, errorMessage: error.errorMessage } });
					this.router.navigateToRoute(app.Route.loginB2CError, error);
				}));
			}
		}
		const account = this.getAccount();
		if (account) {
			try {
				await this.getAccessToken(true);
			} catch {
				this.logout();
			}
			const tokenPayload = this.getTokenPayload();
			// when password gets reset a user remains logged in under the wrong 'password reset' authority
			// logging out is critical to smooth user experience
			if (this.settingsService.azureB2CSettings.passwordResetAuthority.toLowerCase().endsWith(tokenPayload['acr'].toLowerCase())) {
				this.logger.info('User password has been reset');
				this.logout();
			}
		}
	}

	rootAttachedSubscription: au.Subscription;

	async loginPopup(): Promise<AuthenticationResult> {
		return this.app.loginPopup({ scopes: [this.settingsService.azureB2CSettings.apiScope] });
	}

	registerRedirect(token: string): void {
		this.app.loginRedirect({
			scopes: [this.settingsService.azureB2CSettings.apiScope],
			redirectStartPage: `${location.origin}${location.pathname}${location.pathname.endsWith('/') ? '' : '/'}`,
			extraQueryParameters: {
				client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
				client_assertion: token
			}
		});
	}

	async loginRedirect() {
		await this.app.loginRedirect({ scopes: [this.settingsService.azureB2CSettings.apiScope] });
	}

	getAccount(): AccountInfo {
		const accounts = this.app.getAllAccounts();
		return accounts.length ? accounts[0] : undefined;
	}

	async getTokenSilent(): Promise<AuthenticationResult> {
		try {
			return await this.app.acquireTokenSilent({ scopes: [this.settingsService.azureB2CSettings.apiScope], account: this.getAccount() });
		} catch (err) {
			const error = err as AuthError;
			// in case the session does not exist anymore or refresh token expired fallback to redirect to get a token
			if (error.errorMessage.includes('AADB2C90077') || error.errorCode === 'silent_sso_error') {
				await this.app.acquireTokenRedirect({
					authority: this.settingsService.azureB2CSettings.signInAuthority,
					scopes: [this.settingsService.azureB2CSettings.apiScope]
				});
				// this code will never be reached because of the redirect
				return;
			}
			throw err;
		}
	}

	async logout(): Promise<void> {
		this.app.logout();
		return Promise.resolve();
	}

	isEnabled(): boolean {
		return !!this.settingsService.azureB2CSettings.clientId;
	}

	async isAuthenticatedAsync(): Promise<boolean> {
		// the cached token might be expired but until the next request consider a user authenticated
		return Promise.resolve(!!this.cachedAccessToken);
	}

	cachedAccessToken: string;
	cachedIdToken: string;
	cachedAccessTokenExpiry: au.moment.Moment;
	async getAccessToken(ignoreEmptyCache: boolean = false): Promise<string> {
		// empty cache means a user never logged in, so we shouldn't try to get a token unless ignore flag is on
		if (!this.cachedAccessToken && !ignoreEmptyCache) {
			return null;
		}

		if (!this.cachedAccessToken || this.cachedAccessTokenExpiry.isBefore(this.dateService.now())) {
			try {
				const authResult = await this.getTokenSilent();
				this.cachedAccessToken = authResult.accessToken;
				this.cachedIdToken = authResult.idToken;
				const decoded = jwtDecode<at.ITokenPayload>(this.cachedAccessToken);
				this.cachedAccessTokenExpiry = au.moment.unix(decoded.exp);
			} catch (e) {
				this.logger.info('Failed to get an access token', e);
				this.cachedAccessToken = null;
			}
			this.eventAggregator.publish('authentication-change');
		}
		return this.cachedAccessToken;
	}

	getTokenPayload(): at.ITokenPayload {
		if (this.cachedAccessToken) {
			return jwtDecode<at.ITokenPayload>(this.cachedIdToken);
		} else {
			return null;
		}
	}

	async login(): Promise<void> {
		this.loginRedirect();
		return Promise.resolve();
	}

}
