import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class DirectDebit {
	constructor(private webRequestClient: app.WebRequestClient, private validationControllerFactory: at.ValidationControllerFactory, private alertService: at.AlertService,
		private router: au.Router, private accountClient: app.AccountClient, private i18n: au.I18N, private dateService: at.DateService,
		private invoiceClient: app.InvoiceClient, private settingsService: app.SettingsService, private validator: au.Validator) {
		this.validationController = this.validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<DirectDebit, string>(x => x.bsb).required()
			.ensure(x => x.account).required()
			.ensure(x => x.accountTitle).required().maxLength(32)
			.ensure(x => x.debtOption).required().when(x => this.displayOptions)
			.ensure(x => x.confirmed).satisfies(x => x).withMessage("tick to confirm")
			.ensure(x => x.holder1).required().maxLength(80)
			.ensure(x => x.holder2).required().when(x => x.jointAccount).maxLength(80)
			.ensure(x => x.selectedAccount).required().then()
			/**/.satisfies(x => !x.isClosed && x.isConnected).withMessage("must not be closed")
			/**/.satisfies(x => !x.payArrangement).withMessage("")
			.ensure(x => x.processingDate).required().when(x => this.processingDateRequired && x.debtOption && x.debtOption.code === "O")
			.then().satisfies(async x => {
				let now = au.moment(await this.dateService.getServerDate());
				return au.moment(x).isAfter(now) && au.moment(x).isBefore(now.add(14, "days"));
			}).withMessage("must be in the following 14 days").when(x => this.processingDateRequired && x.debtOption && x.debtOption.code === "O")
			.rules;
		this.validationController.addObject(this, this.rules);
		this.validationController.subscribe(async e => {
			const result = await this.validator.validateObject(this, this.rules);
			this.canSubmit = result.every(x => x.valid);
		});
	}

	validationController: au.ValidationController;
	rules: au.Rule<DirectDebit, any>[][];
	accounts: app.AccountBalanceInfo[];
	allDebtOptions: app.WebRequestDebtOption[];
	debtOptions: app.WebRequestDebtOption[];
	showWarning: boolean = !!this.i18n.tr("DirectDebit.OverdueBillWarning");
	jointAccount: boolean = false;
	lastBankDetails: app.GetLastDirectDebitBankDetailsResponse;
	processingDateRequired = this.settingsService.browserSettings.directDebit.processingDateRequired;
	displayOptions: boolean;
	isPaymentExtension: boolean;
	isPaymentArrangement: boolean;
	canSubmit: boolean;
	isTherePendingRequest: boolean;

	@au.observable
	selectedAccount: app.AccountBalanceInfo;
	async selectedAccountChanged() {
		const type = await this.webRequestClient.getDirectDebitType(this.selectedAccount.pkAccount);
		this.displayOptions = this.selectedAccount && this.selectedAccount.amount > 0 && type !== "I" && this.selectedAccount.balanceStatus !== app.BalanceStatus.SmallOverdue;
		this.isPaymentArrangement = this.selectedAccount && this.selectedAccount.payArrangement && this.selectedAccount.payArrangement.fkPayFrequency !== "I" /* In Full */;
		this.isPaymentExtension = this.selectedAccount && this.selectedAccount.payArrangement && this.selectedAccount.payArrangement.fkPayFrequency === "I" /* In Full */;
		this.isTherePendingRequest = await this.webRequestClient.isTherePendingRequest(this.selectedAccount.pkAccount, WebRequestType.DirectDebit);
		this.debtOption = null;
		this.updateDebtOptions();
	}

	bankName: string;
	bsb: string;
	account: string;
	accountTitle: string;

	@au.observable
	debtOption: app.WebRequestDebtOption = null;
	debtOptionChanged() {
		if (this.validationController) {
			this.validationController.validate({ propertyName: "processingDate", object: this })
		}
	}

	processingDate: Date = null;
	holder1: string;
	holder2: string;
	confirmed: boolean;

	async canActivate(): Promise<any> {
		return await this.alertService.usingProgress(async () => {
			this.accounts = await this.accountClient.getAccountSummaries(true, false);
			if (!this.accounts.length) {
				await this.alertService.alert("You don't have Direct Debit eligible accounts.", "warning", "orange");
				return false;
			}
			else if (this.accounts.length === 1) {
				this.selectedAccount = this.accounts[0];
			}
		}, async e => { await this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e); return false; });
	}

	async activate() {
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.webRequestClient.getDebtOptions().then(x => this.allDebtOptions = x),
				this.webRequestClient.getLastDirectDebitBankDetails().then(x => this.lastBankDetails = x)
			]);
			this.updateDebtOptions();
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	validateDebtOption() {
		this.validationController.validate({ object: this, propertyName: "debtOption" });
	}

	async submit() {
		let res = await this.validationController.validate();
		if (!res.valid) {
			let invalidText = document.querySelector(".input-field span.helper-text[data-error]") as HTMLSpanElement;
			if (invalidText) {
				invalidText.scrollIntoView();
				window.scrollBy(0, -150);
			}
			await this.alertService.error("Please correct the invalid fields");
			return;
		}
		if (!await this.alertService.confirm("Are you sure you would like to submit this Direct Debit application?", "warning", "orange")) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			let dd = new app.WebRequestDirectDebit({
				bankName: this.bankName, bsbNo: this.bsb, accountNo: this.account, processingDate: this.processingDate ? this.processingDate.asUtc() : null,
				accountTitle: this.accountTitle, fkDebtOption: this.debtOption ? this.debtOption.code : null, accountHolder1: this.holder1, accountHolder2: this.holder2, pk: 0, fkWebRequest: 0
			});
			let request = new app.WebRequest({ fkAccount: this.selectedAccount ? this.selectedAccount.pkAccount : null, directDebit: dd, pk: 0, guid: null, dateRequested: null });
			await this.webRequestClient.submitDirectDebit(request);
			await this.alertService.alert(app.Strings.webRequestSuccess);
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.error("Error occurred while submitting the direct debit application"));
	}

	async prefill() {
		this.bsb = this.lastBankDetails.bsbNo;
		this.account = this.lastBankDetails.accountNo;
		this.accountTitle = this.lastBankDetails.accountTitle;
	}

	async updateDebtOptions() {
		if (!this.allDebtOptions) {
			return;
		}
		const isOverdue = this.selectedAccount
			&& (this.selectedAccount.balanceStatus === app.BalanceStatus.Overdue
				|| this.selectedAccount.balanceStatus === app.BalanceStatus.PaymentArrangement
				&& this.selectedAccount.lastInvoice
				&& au.moment(await this.dateService.getServerDate()).isAfter(this.selectedAccount.lastInvoice.dateDue));
		this.debtOptions = this.allDebtOptions.filter(x => x.code === "N" || x.code === "Y" && !isOverdue || x.code === "O" && isOverdue);
	}
}