import * as au from "aurelia";
import * as app from "app";

@au.autoinject
export class PayArrangement {
	constructor(private settingsService: app.SettingsService) { }

	dateFormat = this.settingsService.browserSettings.dateFormat;

	@au.bindable
	value: app.PayArrangement;

	@au.ato.bindable.booleanMd
	showUnderReviewNotice: boolean;
}
