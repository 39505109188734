import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

export class BaseWidget {
	constructor(protected dateService: at.DateService) { }
	range: IDateRange = {};
	loading: boolean;

	loadDateRange(widgetName: string, range: IDateRange) {
		let saved = window.localStorage.getItem(widgetName);
		if (saved) {
			let r = JSON.parse(saved) as IDateRange;
			range.from = au.moment(r.from).toDate();
			range.to = au.moment(r.to).toDate();
		}
		else {
			range.to = this.dateService.now();
			range.from = au.moment(range.to).add(-1, "month").toDate();
		}
	}

	saveDateRange(widgetName: string, range: IDateRange) {
		window.localStorage.setItem(widgetName, JSON.stringify({ from: range.from.asUtc(), to: range.to.asUtc() }));
	}
}