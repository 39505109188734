import * as au from "aurelia";

@au.autoinject
export class ScriptInjector {
	constructor(element: Element) {
		this.element = element as HTMLElement;
	}

	element: HTMLElement;

	@au.bindable
	url: string;

	@au.bindable
	text: string;

	@au.bindable
	isAsync: boolean;

	@au.bindable({ defaultBindingMode: au.bindingMode.oneWay })
	scriptElement: HTMLScriptElement;

	bind() { }

	attached() {
		if (!this.url && !this.text) {
			return;
		}

		this.scriptElement = document.createElement('script');
		if (this.isAsync) {
			this.scriptElement.async = true;
		}

		if (this.url) {
			this.scriptElement.setAttribute('src', this.url);
		}
		else {
			this.scriptElement.innerHTML = this.text;
		}
		document.head.appendChild(this.scriptElement);
	}

	detached() {
		if (this.scriptElement) {
			this.scriptElement.remove();
		}
	}
}