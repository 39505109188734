export function utcDate(date: Date): Date | null | undefined {
	return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : date;
}

declare global {
	export interface Date {
		asUtc(): Date;
	}
}

Date.prototype.asUtc = function (this: Date) {
	return this ? new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())) : this;
}