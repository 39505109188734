import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class InfoStatementPorg {
	constructor(private element: Element, validationControllerFactory: at.ValidationControllerFactory, private taskQueue: au.TaskQueue) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<app.WebRequestInfoStmtPorg, string>(x => x.surname).required().when(x => x.fk_porg_type == PorgType.person).maxLength(40)
			.ensure(x => x.first_name).required().when(x => x.fk_porg_type == PorgType.person).maxLength(40)
			.ensure(x => x.organisation_name).required().when(x => x.fk_porg_type == PorgType.organisation).maxLength(80)
			.rules;
	}

	public validationController: au.ValidationController;
	rules: au.Rule<app.WebRequestInfoStmtPorg, any>[][];

	@au.bindable
	porg: app.WebRequestInfoStmtPorg;
	porgChanged(newValue: app.WebRequestInfoStmtPorg, oldValue: app.WebRequestInfoStmtPorg) {
		if (oldValue) {
			this.validationController.removeObject(oldValue);
		}
		this.validationController.addObject(this.porg, this.rules);
		this.taskQueue.queueTask(() => this.validationController.reset());
	}

	@au.bindable
	porgTypes: app.PorgType[];

	@au.bindable
	readonly: boolean;
	readonlyChanged() {
		if (this.readonly) {
			this.validationController.removeObject(this.porg);
		} else {
			this.validationController.addObject(this.porg, this.rules);
		}
	}

	@au.computedFrom("porg.fk_porg_type")
	get isOrganisation(): boolean {
		return this.porg.fk_porg_type === PorgType.organisation;
	}

	remove(e: Event) {
		au.fireEvent(this.element, "remove");
		e.stopPropagation();
	}
}