import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class EditUser extends app.EditBase {
	constructor(private userClient: app.UserClient, alertService: at.AlertService, private eventAggregator: au.EventAggregator,
		validationControllerFactory: at.ValidationControllerFactory, private accountClient: app.AccountClient, authService: app.IAuthService,
		private emailClient: app.EmailClient, private msalService: app.MsalService, private dateService: at.DateService, private roleClient: app.RoleClient,
		private taskQueue: au.TaskQueue) {
		super(alertService, validationControllerFactory, authService);
		this.rules = au.ValidationRules
			.ensure<app.UserInfo, string>(x => x.otherNames).required().maxLength(80)
			.ensure(x => x.phoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("04")).withMessage("must start with 04")
			/**/.satisfies(x => !this.rawPhoneNumber || !!x).withMessage("must have 10 digits")
			.ensure(x => x.homePhoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("0")).withMessage("must start with an area code")
			/**/.satisfies(x => !this.rawHomePhoneNumber || !!x).withMessage("must have 10 digits and start with an area code")
			.ensure(x => x.surname).required().maxLength(80)
			.rules;
	}

	user: app.UserInfo;
	statuses: app.StatusInfo[] = [];
	accounts: app.AccountInfo[] = [];
	accountsLoading: boolean;
	rules: au.Rule<{}, any>[][];
	roleView: string;
	isB2CEnabled: boolean;
	isLocked: boolean;
	canChangeStatus: boolean;
	canResetPassword: boolean = true;
	rawPhoneNumber: string;
	rawHomePhoneNumber: string;
	roles: app.Role[];
	role: app.Role;

	@au.computedFrom("user.otherNames", "user.surname")
	get title() {
		return `${this.user.otherNames} ${this.user.surname}`;
	}

	accountToLink: app.AccountInfo;

	async load(id: string) {
		if (this.user) {
			this.validationController.removeObject(this.user);
		}
		let r = await Promise.all([
			this.userClient.get(id).then(x => this.user = x),
			this.userClient.getStatuses().then(x => this.statuses = x),
			this.loadAccounts(id).then(x => this.accounts = x),
			this.dateService.getServerDate(),
			this.roleClient.getInternal()
		]);
		this.isLocked = !this.isB2CEnabled && this.user.lockoutEnd && au.moment(this.user.lockoutEnd).isAfter(r[3]);
		this.roleView = this.user.role.name.toLowerCase().replace(' ', '-');
		this.isB2CEnabled = this.msalService.isEnabled();
		let currentUserRole = this.authService.getTokenPayload().role;
		this.canChangeStatus = (currentUserRole === app.Roles.admin || currentUserRole === app.Roles.supervisor) && this.user.canUpdate;
		this.canResetPassword = this.user.status === app.Status.Active && this.user.canUpdate;
		this.validationController.addObject(this.user, this.rules);
		const internalRoles = r[4];
		const role = internalRoles.find(x => x.id === this.user.role.id);
		if (role && currentUserRole === app.Roles.admin) {
			this.roles = internalRoles;
			this.role = role;
		}
		else {
			this.roles = [this.user.role];
			this.role = this.user.role;
		}
	}

	async loadAccounts(userId: string): Promise<app.AccountInfo[]> {
		try {
			this.accountsLoading = true;
			return await this.accountClient.userAccounts(userId);
		}
		finally {
			this.accountsLoading = false;
		}
	}

	async saveInternal(): Promise<string> {
		var u = await this.userClient.patch(new app.PatchUserRequest({
			email: this.user.email,
			id: this.user.id,
			status: this.user.status,
			timestamp: this.user.timestamp,
			phoneNumber: this.user.phoneNumber,
			homePhoneNumber: this.user.homePhoneNumber,
			role: this.role.name
		}));
		this.user.id = u.id;
		this.user.timestamp = u.timestamp;
		if (u.id === this.authService.getTokenPayload().userid) {
			this.eventAggregator.publish(app.Events.profileChanged);
		}
		this.canResetPassword = this.user.status === app.Status.Active;
		return u.id;
	}

	async sendResetPassword() {
		if (this.isChanged) {
			return;
		}

		if (!await this.alertService.confirm("Are you sure you would like to send a reset password link to this user?", "warning", "orange")) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			await this.emailClient.sendResetPasswordEmail(this.user.email);
			this.alertService.alert("The link has been sent");
		}, e => this.alertService.error("Error occurred while sending the link"));
	}

	async unlock() {
		if (!await this.alertService.confirm("Are you sure you would like to unlock this account?", "warning", "orange")) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			await this.userClient.unlock(this.user.id);
			await this.load(this.user.id);
		}, e => this.alertService.error("Error occurred while resetting the counter"));
	}

}