import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { SearchRequest } from "../../app";

@au.autoinject
export class Register {
	constructor(private userClient: app.UserClient, private router: au.Router, private alertService: at.AlertService,
		validationControllerFactory: at.ValidationControllerFactory, private organisationClient: app.OrganisationClient, private authService: app.IAuthService, private i18n: au.I18N,
		private settingsService: app.SettingsService, private b2cClient: app.B2CClient, private msalService: app.MsalService, private taskQueue: au.TaskQueue) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<Register, app.Roles>(x => x.role).required()
			.ensure(x => x.surname).required().maxLength(80)
			.ensure(x => x.otherNames).required().maxLength(80)
			.ensure(x => x.email).email().required()
			.ensure(x => x.phoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("04")).withMessage("must start with 04")
			/**/.satisfies(x => !this.rawPhoneNumber || !!x).withMessage("must have 10 digits")
			.ensure(x => x.homePhoneNumber).maxLength(10)
			/**/.satisfies(x => !x || x.startsWith("0")).withMessage("must start with an area code")
			/**/.satisfies(x => !this.rawHomePhoneNumber || !!x).withMessage("must have 10 digits and start with an area code")
			.ensure(x => x.agencyOrganisationId).required().when(x => x.isAgent)
			.ensure(x => x.password).required().when(x => !x.isAzureB2CEnabled).then().password(this.settingsService.passwordOptions)
			.ensure(x => x.passwordConfirm).required().when(x => !x.isAzureB2CEnabled).then().mustMatch("password")
			.ensure(x => x.accountNumber).required().when(x => x.isCustomer).withMessage("must include 13 digits")
			.ensure(x => x.invoice).required().when(x => x.isInvoiceRequired)
			.ensure(x => x.dateOfBirth).required().when(x => x.isDateOfBirthRequired).then()
			/**/.satisfies((x: Date) => au.moment(x).isBetween(au.moment().subtract(110, "years"), au.moment().subtract(15, "years")))
			/**/.withMessage("age must be between 15 and 110 years")
			.ensure(x => x.organisationName).required().when(x => x.isBusinessCustomer || !!x.sundryDebtorAccountNumber)
			.ensure(x => x.abn).required().withMessage("must include 11 digits").when(x => this.settingsService.browserSettings.register.abnRequired || !!x.abnRaw)
			.ensure(x => x.confirmed).satisfies(x => x).withMessage("tick to agree")
			.on(Register);
	}

	isSelfRegister: boolean;
	isAzureB2CEnabled: boolean;

	validationController: au.ValidationController;
	roles: app.Role[] = [];
	organisations: app.Organisation2[];

	@au.observable
	role: app.Role;
	async roleChanged() {
		await this.validationController.validate({ object: this, propertyName: "role" });
		if (this.role) {
			this.isCustomer = this.role.name == app.Roles.residentialCustomer || this.role.name == app.Roles.businessCustomer;
			this.isInvoiceRequired = this.settingsService.aquaRateSettings.validateInvoice;
			this.isDateOfBirthRequired = this.role.name == app.Roles.residentialCustomer && this.settingsService.aquaRateSettings.dateOfBirthConstraint !== app.DateOfBirthConstraint.None;
			this.isBusinessCustomer = this.role.name == app.Roles.businessCustomer;
			this.isConveyancer = this.role.name == app.Roles.conveyancer;
			this.isAgent = this.role.name == app.Roles.agent;
		}
	}

	isInvoiceRequired: boolean;
	isDateOfBirthRequired: boolean;
	isCustomer: boolean;
	isBusinessCustomer: boolean;
	isConveyancer: boolean;
	isAgent: boolean;
	isAgencyManagerInviting: boolean;

	surname: string;
	otherNames: string;
	email: string;
	phoneNumber: string;
	rawPhoneNumber: string;
	homePhoneNumber: string;
	rawHomePhoneNumber: string;
	password: string;
	passwordConfirm: string;
	accountNumber: string;
	invoice: number;
	agencyOrganisationId: number;
	organisationName: string;
	abn: string;
	abnRaw: string;
	sundryDebtorAccountNumber: number;
	dateOfBirth: Date;
	confirmed: boolean;

	async activate(params: any, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<any> {
		this.alertService.usingProgress(async () => {
			this.isAzureB2CEnabled = this.msalService.isEnabled();
			this.isSelfRegister = navigationInstruction.config.name === app.Route.register;
			if (this.isSelfRegister) {
				this.roles = await this.userClient.getRegisterRoles();
			}
			else {
				var token = this.authService.getTokenPayload();
				this.isAgencyManagerInviting = token.role == app.Roles.agencyManager;
				this.roles = await this.userClient.getInviteRoles();

				if (!this.isAgencyManagerInviting && this.roles.find(x => x.name === app.Roles.agencyManager || x.name === app.Roles.agent)) {
					this.organisations = (await this.organisationClient.index(new SearchRequest())).items;
				}

				this.role = null;
				this.taskQueue.queueTask(() => this.validationController.reset());
			}
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async register() {
		let errors = await this.validationController.validate();
		if (!errors.valid)
			return;

		let request = new app.NewUserDetails({
			roleId: this.role.id,
			surname: this.surname,
			otherNames: this.otherNames,
			email: this.email,
			phoneNumber: this.phoneNumber,
			homePhoneNumber: this.homePhoneNumber,
			password: this.password,
			accountNumber: this.accountNumber,
			invoice: this.invoice,
			agencyOrganisationId: this.agencyOrganisationId,
			organisationName: this.organisationName,
			abn: this.abn,
			sundryDebtorAccountNumber: this.sundryDebtorAccountNumber,
			dateOfBirth: this.isDateOfBirthRequired && this.dateOfBirth ? this.dateOfBirth.asUtc() : null
		});

		await this.alertService.usingProgress(async () => {
			if (this.isSelfRegister) {
				if (this.isAzureB2CEnabled) {
					let token = await this.b2cClient.getRegistrationToken(request);
					this.taskQueue.queueTask(() => {
						this.alertService.showProgress();
						this.msalService.registerRedirect(token);
					});
				}
				else {
					await this.userClient.register(request);
					this.router.navigateToRoute(app.Route.login, { emailConfirmationNeeded: true });
				}
			}
			else {
				await this.userClient.invite(request);
				await this.alertService.alert(this.i18n.tr("Registration.SubmitSuccess"));
				this.router.navigateToRoute(app.Route.users);
			}
		}, async e => {
			let message = e.message || this.i18n.tr("Registration.SubmitError");
			if (e instanceof app.IdentityException) {
				if (e.data.DuplicateUserName) {
					message = "A user with this email is already registered";
				}
			}
			else if (e instanceof app.NotMatchingBirthDateException) {
				message = this.i18n.tr("Registration.DateOfBirthOrMobileNumberDontMatch");
			}
			return this.alertService.error(message);
		});
	};

	getOrganisations(p: au.ILookupOptionsFunctionParameter<number>): Promise<app.Organisation2[]> {
		if (p.value) {
			return Promise.resolve([this.organisations.find(x => x.id === p.value)]);
		}
		else {
			return Promise.resolve(this.organisations.filter(x => !p.filter || x.name.includes(p.filter)));
		}
	}

	capsLockOn: boolean;
	passwordKeyUp(event: KeyboardEvent) {
		this.capsLockOn = event.getModifierState("CapsLock");
	}
}