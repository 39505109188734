import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import "./last-tenancy-requests.scss";

@au.autoinject
export class LastTenancyRequests extends app.PaginatedBase {
	constructor(private router: au.Router, private webRequestClient: app.WebRequestClient, alertService: at.AlertService, private settingsService: app.SettingsService,
		private dataStorageService: app.DataStorageService) {
		super(alertService);
	}

	requests: app.TenancyWebRequestInfo[];
	statuses: app.WebRequestStatus[];
	types: app.WebRequestType[];

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.tenancyFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.tenancyFilterLines = value;
	}

	async attached() {
		try {
			await Promise.all([
				this.webRequestClient.getStatuses().then(x => this.statuses = x),
				this.webRequestClient.getTenancyTypes().then(x => this.types = x),
			]);
			if (this.savedFilterLines && this.savedFilterLines.length) {
				this.filterLines = this.savedFilterLines;
			}
			else {
				this.filterLines = [];
			}
			await this.search()
			this.isActivated = true;
		}
		catch (e) {
			this.alertService.error(e.message);
		}
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfTenancyWebRequestInfo> {
		let response = await this.webRequestClient.getLastTenancyRequests(request);
		this.requests = response.items;
		return response;
	}

	open(wr: app.TenancyWebRequestInfo) {
		this.router.navigateToRoute(wr.type === "New Tenant" ? app.Route.newTenant : app.Route.vacatingTenant, { guid: wr.guid } as IHaveGuid);
	}

}