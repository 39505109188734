import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { CreditCard } from "custom-elements/credit-card/credit-card";

@au.autoinject
export class AccountPayment {
	constructor(private accountClient: app.AccountClient, private alertService: at.AlertService, private router: au.Router, private paymentClient: app.PaymentClient,
		private settingsService: app.SettingsService) { }

	accounts: app.AccountInfo[];

	@au.observable
	selectedAccount: app.AccountInfo;
	async selectedAccountChanged() {
		let balanceInfo = await this.accountClient.getBalance(this.selectedAccount.pkAccount);
		if (balanceInfo.amount > 0) {
			this.amount = balanceInfo.amount;
		} else {
			this.amount = 0;
		}
	}

	amount: number;

	creditCard: CreditCard;
	payment: app.Payment;
	failedPayment: boolean;
	dateFormat = this.settingsService.browserSettings.dateFormat;

	determineActivationStrategy() {
		return au.activationStrategy.replace; //replace the viewmodel with a new instance
	}

	async activate(params: IHaveId): Promise<any> {
		await Promise.all([
			this.accountClient.index().then(x => this.accounts = x.filter(x => !x.isClosed))
		]);
		if (params && params.id) {
			this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
		}
		if (!this.selectedAccount) {
			this.selectedAccount = this.accounts[0];
		}
	}

	async pay() {
		var vr = await this.creditCard.validate();
		if (!vr.valid) {
			return;
		}

		let tokenData: app.IPaymentTokenInfo;
		await this.alertService.usingProgress(async () => {
			tokenData = await this.creditCard.getPaymentTokenInfo();
			this.payment = await this.paymentClient.takeAccountPayment(new app.TakeAccountPaymentRequest({
				accountPk: this.selectedAccount.pkAccount,
				amount: this.amount,
				paymentTokenInfo: new app.PaymentTokenInfo({
					token: tokenData.token,
					cardholderName: tokenData.cardholderName,
					cardScheme: tokenData.cardScheme,
					maskedCardNumber: tokenData.maskedCardNumber,
					surchargePercentage: tokenData.surchargePercentage
				})
			}));
			this.failedPayment = !![app.PaymentStatus.Failed, app.PaymentStatus.RolledBack].find(x => x === this.payment.status);
		}, async e => {
			if (e.status === 422) {
				await this.alertService.error("Please make sure credit card details are correct");
			}
			else {
				await this.alertService.error("We were unable to process the payment");
				return;
			}
		});
	}

	tryAgain() {
		this.router.navigateToRoute(app.Route.accountPayment, { id: this.selectedAccount.pkAccount }, { replace: true });
	}
}