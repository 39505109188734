import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Payments extends app.PaginatedBase {
	constructor(private paymentClient: app.PaymentClient, alertService: at.AlertService, private dataStorageService: app.DataStorageService, private taskQueue: au.TaskQueue) {
		super(alertService);
	}

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.paymentsFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.paymentsFilterLines = value;
	}

	statuses: app.IPaymentStatus[];
	payments: app.PaymentInfo[]
	payment: app.Payment;
	modal: au.MdModal;

	async activate() {
		await this.alertService.usingProgress(async () => {
			this.statuses = app.PaymentStatuses;
			if (this.savedFilterLines && this.savedFilterLines.length) {
				this.filterLines = this.savedFilterLines;
			}
			else {
				this.filterLines = [];
				await this.search();
				this.isActivated = true;
			}
		}, e => this.alertService.error("Could not initialise the screen"));
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfPaymentInfo> {
		let response = await this.paymentClient.getPayments(request);
		this.payments = response.items;
		return response;
	}

	async open(p: app.PaymentInfo): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.payment = await this.paymentClient.get(p.id);
			this.taskQueue.queueMicroTask(() => this.modal.open());
		}, () => this.alertService.error("Could not retrieve payment receipt"));
	}
}