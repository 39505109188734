import * as au from "aurelia";
import * as app from "app";
import "./select-account.scss";

@au.autoinject
export class SelectAccount {
	constructor(private element: Element) { }

	@au.bindable
	accounts: app.AccountInfo[];

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	value: app.AccountInfo;

	@au.bindable
	label: string;

	@au.bindable
	readonly: boolean;

	@au.bindable
	allowEmpty: boolean;

	@au.observable
	atSelectElement: HTMLElement;

	@au.bindable
	emptyLabel: string[] = ["Select", "Account"];

	@au.bindable
	hint: string = "Select Account";

	attached() {
		this.element.mdRenderValidateResults = this.mdRenderValidateResults;
		this.element.mdUnrenderValidateResults = this.mdUnrenderValidateResults;
	}

	detached() {
		this.element.mdRenderValidateResults = null;
		this.element.mdUnrenderValidateResults = null;
	}

	selectAccount(a: app.AccountInfo) {
		this.value = a;
	}

	mdUnrenderValidateResults = (results: au.ValidateResult[], renderer: au.MaterializeFormValidationRenderer) => {
		this.atSelectElement.mdUnrenderValidateResults(results, renderer);
	}

	mdRenderValidateResults = (results: au.ValidateResult[], renderer: au.MaterializeFormValidationRenderer) => {
		this.atSelectElement.mdRenderValidateResults(results, renderer);
	}

}