import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Expired {
	constructor(private eventAggregator: au.EventAggregator, private authService: app.IAuthService, private msalService: app.MsalService,
		private router: au.Router) {

	}

	async canActivate() {
		if (await this.authService.isAuthenticatedAsync()) {
			return new au.RedirectToRoute(app.Route.home);
		}
	}

	async activate() {
		// this will force routes update
		await this.eventAggregator.publish("authentication-change");
	}

	login() {
		if (this.msalService.isEnabled()) {
			this.msalService.login();
		}
		else {
			this.router.navigateToRoute(app.Route.login);
		}
	}
}