import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ChangeName {
	constructor(private userClient: app.UserClient, private alertService: at.AlertService, validationControllerFactory: at.ValidationControllerFactory,
		private router: au.Router, private settingsService: app.SettingsService, private i18n: au.I18N, private webRequestClient: app.WebRequestClient) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<ChangeName, string>(x => x.surname).required().when(x => !x.isOrganisation).maxLength(40)
			.ensure(x => x.otherNames).required().when(x => !x.isOrganisation).maxLength(80)
			.ensure(x => x.organisationName).required().when(x => x.isOrganisation).maxLength(80)
			.ensure(x => x.files)
			/**/.satisfies(x => !x.find(y => !this.settingsService.isFileAllowed(y.fileName))).withMessage("not allowed file selected")
			.rules;
	}

	validationController: au.ValidationController;
	rules: au.Rule<ChangeName, any>[][];
	userOld: app.UserInfo;
	maxFileSize = this.settingsService.browserSettings.maxFileSize;
	files: at.IFileRow[] = [];
	surname: string;
	otherNames: string;
	organisationName: string;
	isOrganisation: boolean;
	isTherePendingRequest: boolean;
	allowedExtensions = this.settingsService.browserSettings.allowedFileExtensions;

	async activate() {
		try {
			await Promise.all([
				this.userClient.getSelf().then(x => {
					this.surname = x.surname;
					this.otherNames = x.otherNames;
					this.organisationName = x.aquaRateOrganisationName;
					this.userOld = new app.UserInfo(x)
				}),
				await this.webRequestClient.isTherePendingRequest(null, WebRequestType.ChangeName).then(x => this.isTherePendingRequest = x)
			]);
			this.isOrganisation = !!this.organisationName;
			this.validationController.addObject(this, this.rules);
		}
		catch (e) {
			this.alertService.error("Error occurred while loading the page");
			throw e;
		}
	}

	async submit(): Promise<any> {
		const res = await this.validationController.validate();
		if (!res.valid) {
			return;
		}

		if (!this.isOrganisation && this.surname === this.userOld.surname && this.otherNames === this.userOld.otherNames
			|| this.isOrganisation && this.organisationName === this.userOld.aquaRateOrganisationName) {
			this.alertService.error("You have not changed the names");
			return;
		}

		if (this.files.length > this.settingsService.browserSettings.maxFilesPerRequest) {
			this.alertService.error(this.i18n.tr("MaxFileCountError"));
			return;
		}

		if (this.files.find(x => x.isTooBig)) {
			this.alertService.error(this.i18n.tr("MaxFileSizeError"));
			return;
		}

		await this.alertService.usingProgress(async () => {
			await this.userClient.changeName(
				this.isOrganisation ? undefined : this.surname,
				this.isOrganisation ? undefined : this.otherNames,
				this.isOrganisation ? this.organisationName : undefined,
				this.files.map(x => ({ data: x.file, fileName: `${x.fileName}` })));
			await this.alertService.alert(this.i18n.tr("ChangeName.SubmitSuccess"));
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.error(this.i18n.tr("ChangeName.SubmitError")));
	}

	filesChanged() {
		this.validationController.validate({ object: this, propertyName: "files" });
	}
}
