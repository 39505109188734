import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { Bpoint } from "../bpoint/bpoint";
import { QuickStream } from "../quick-stream/quick-stream";

@au.autoinject
export class CreditCard {
	constructor(private element: Element, private settingsService: app.SettingsService, private paymentClient: app.PaymentClient,
		validationControllerFactory: at.ValidationControllerFactory) {
		this.logger = au.getLogger("CreditCard");
		this.logger.debug("settings", this.settings);
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<CreditCard, number>(x => x.amount).required().when(x => x.showAmount).then().satisfies(x => x > 0).when(x => x.showAmount).withMessage("must be greater than zero")
			.on(this);
	}

	logger: au.Logger;
	validationController: au.ValidationController;
	settings = this.settingsService.paymentSettings;
	PaymentProvider = app.PaymentProvider;
	surcharges: app.Surcharge[];

	bpointAuthKey: string;
	bpointCardholderName: string;

	bpoint: Bpoint;
	quickStream: QuickStream;

	@au.bindable
	showAmount: boolean;

	@au.bindable
	amount: number;
	suppressAmountChanged: boolean;
	amountChanged() {
		if (this.suppressAmountChanged) {
			this.suppressAmountChanged = false;
			return;
		}
		this.suppressAmountTextChanged = true;
		this.amountText = this.amount ? this.amount.toString() : null;
	}

	@au.observable
	amountText: string;
	suppressAmountTextChanged: boolean;
	amountTextChanged() {
		if (this.suppressAmountTextChanged) {
			this.suppressAmountTextChanged = false;
			return;
		}
		this.suppressAmountChanged = true;
		this.amount = au.numeral(this.amountText).value();
	}

	async attached() {
		try {
			this.surcharges = await this.paymentClient.getSurcharges();
		}
		catch {
			this.surcharges = [];
		}
	}

	async refresh() {
		switch (this.settings.provider) {
			case app.PaymentProvider.Bpoint:
				await this.bpoint.attached();
				break;
		}
	}

	async getPaymentTokenInfo(): Promise<app.IPaymentTokenInfo> {
		switch (this.settings.provider) {
			case app.PaymentProvider.QuickStream:
				let qst = await this.quickStream.getToken();
				return {
					token: qst.singleUseTokenId,
					cardholderName: qst.creditCard.cardholderName,
					cardScheme: qst.creditCard.cardScheme,
					maskedCardNumber: qst.creditCard.cardNumber,
					surchargePercentage: parseFloat(qst.creditCard.surchargePercentage)
				};
			case app.PaymentProvider.Bpoint:
				return {
					token: this.bpointAuthKey,
					cardholderName: this.bpointCardholderName,
					surchargePercentage: null
				};
			default:
				throw new Error(`Unsupported payment provider ${this.settings.provider}`)
		}
	};

	async validate(): Promise<au.ControllerValidateResult> {
		return await this.validationController.validate();
	}
}