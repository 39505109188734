import * as moment from "moment";
import * as app from "app";
import * as au from "aurelia";

@au.autoinject
export class AccountBalanceInfoValueConverter {
	constructor(private i18n: au.I18N) { }

	toView(value: app.AccountBalanceInfo): string {
		switch (value.balanceStatus) {
			case app.BalanceStatus.Payed:
				return this.i18n.tr("Account.ThankYouForPromptPayment");
			case app.BalanceStatus.SmallOverdue:
				return this.i18n.tr("Account.OverdueInvoiceDescriptionSmallAmount");
			case app.BalanceStatus.Overdue:
				return this.i18n.tr("Account.OverdueInvoiceDescription");
			case app.BalanceStatus.PaymentArrangement:
				return this.i18n.tr("Account.PaymentArrangementInPlace");
			case app.BalanceStatus.DirectDebit:
				return this.i18n.tr("Account.WillBePayedViaDirectDebit", { replace: { date: value.lastInvoice.dateDue } });
			case app.BalanceStatus.None:
				return "";
			case app.BalanceStatus.ToBePayed:
			default:
				return this.i18n.tr("Account.PleasePayBy", { replace: { date: value.lastInvoice.dateDue } });
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}