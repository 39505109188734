import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

type ISearchResponse = {
	pageCount?: number | undefined;
}

export abstract class PaginatedBase {
	constructor(protected alertService: at.AlertService) { }

	filterLines: at.IFilterLine[] = [];
	abstract savedFilterLines: at.IFilterLine[];
	pageSizes = [10, 20, 30, 50, 100];

	isActivated: boolean;

	@au.observable
	pageSize: number = this.pageSizes[0];
	pageSizeChanged() {
		if (this.isActivated) {
			this.search();
		}
	}


	pageCount: number;
	activePage: number;
	fo = at.FilterOperator;

	abstract async fetch(request: app.SearchRequest): Promise<ISearchResponse>;

	async search(): Promise<any> {
		await this.alertService.usingProgress(async () => {
			let request = new app.SearchRequest({ filterItems: this.filterLines.map(x => new app.FilterItem(x.toJson())), pageSize: this.pageSize });
			let response = await this.fetch(request);
			this.pageCount = response.pageCount;
			this.activePage = 1;
			this.savedFilterLines = this.filterLines;
		}, e => this.alertService.criticalError(e.message ? e.message : app.Strings.errorWhileLoadingPage, e));
	}

	async pageChanged(e: { detail: number }) {
		await this.alertService.usingProgress(async () => {
			let request = new app.SearchRequest({ filterItems: this.savedFilterLines.map(x => new app.FilterItem(x.toJson())), page: e.detail, pageSize: this.pageSize });
			let response = await this.fetch(request);
		}, e => this.alertService.error(e.message));
	}

}
