import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { EditAddress } from "custom-elements/edit-address/edit-address";

@au.autoinject
export class ChangeAddress {
	constructor(private userClient: app.UserClient, private alertService: at.AlertService, private router: au.Router, private i18n: au.I18N,
		private webRequestClient: app.WebRequestClient) { }

	addresses: app.IAddressesEx;
	states: app.State[];
	residentialVM: EditAddress;

	@au.observable
	selectedAccount: app.AccountInfo;
	selectedAccountChanged(newValue: app.AccountInfo) {
		this.addresses.overrides.forEach(x => x.isSelected = (x.fkAccount === newValue.pkAccount));
	}

	addressTabs: au.MdTabs;
	addressTabsElement: Element;
	isTherePendingRequest: boolean;

	async activate(): Promise<any> {
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.userClient.getAddresses().then(x => {
					this.addresses = x;
					this.addresses.residential.old = new app.ArAddress(this.addresses.residential);
					this.addresses.postal.old = new app.ArAddress(this.addresses.postal);
					this.addresses.overrides.forEach(y => { y.old = new app.ArAddress(y); y.isSelected = false; });
				}),
				this.userClient.getStates().then(x => this.states = x),
				await this.webRequestClient.isTherePendingRequest(null, WebRequestType.ChangeAddresses).then(x => this.isTherePendingRequest = x)
			]);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async submit() {
		if (!this.addresses.residential.isChanged) {
			this.alertService.alert("You have not changed the address");
			return;
		}

		if (this.addresses.residential.isChanged && !(await this.residentialVM.validationController.validate()).valid) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			await this.userClient.changeAddress(new app.Addresses({
				residential: this.addresses.residential.isChanged ? new app.ArAddress(this.addresses.residential) : null,
				postal: null,
				overrides: []
			}));
			await this.alertService.alert(this.i18n.tr("ChangeAddresses.SubmitSuccess"));
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.error(this.i18n.tr("ChangeAddresses.SubmitError")));
	}
}
