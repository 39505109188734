import * as app from "app";

export class roleToMenuSizeValueConverter {
	toView(value: string): string {
		switch (value) {
			case undefined: return "420";
			case app.Roles.admin: return "830";
			case app.Roles.residentialCustomer: return "1090";
			case app.Roles.businessCustomer: return "800";
			case app.Roles.agent: return "500";
			case app.Roles.conveyancer: return "500";
			case app.Roles.agencyManager: return "630";
			default: return "1200";
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}