import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Users extends app.PaginatedBase {
	constructor(private userClient: app.UserClient, alertService: at.AlertService, private router: au.Router, private dataStorageService: app.DataStorageService,
		private authService: app.IAuthService, private settingsService: app.SettingsService) {
		super(alertService);
	}

	users: app.UserInfo[];
	roles: app.Role[];
	statuses: app.StatusInfo[] = [];

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.usersFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.usersFilterLines = value;
	}

	async activate() {
		await this.alertService.usingProgress(async () => {
			let r = await Promise.all([
				this.userClient.getAllRoles().then(x => this.roles = x),
				this.userClient.getStatuses().then(x => this.statuses = x),
			]);

			if (this.savedFilterLines && this.savedFilterLines.length) {
				this.filterLines = this.savedFilterLines;
			}
			else {
				this.filterLines = [];
			}
			await this.search();
			this.isActivated = true;
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	getRoles = (p: au.ILookupOptionsFunctionParameter<string>): Promise<app.Role[]> => {
		if (p.value) {
			return Promise.resolve([this.roles.find(x => x.id === p.value)]);
		}
		else {
			return Promise.resolve(this.roles.filter(x => x.name.includes(p.filter)));
		}
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfUserInfo> {
		request.filterItems.filter(x => x.value instanceof Date).forEach(x => x.value = x.value.asUtc());
		let response = await this.userClient.getUsers(request);
		this.users = response.items;
		return response;
	}

	edit(user: app.UserInfo) {
		if (app.isRouteAllowed(this.router.routes.find(x => x.name === app.Route.editUser), this.authService.getTokenPayload(), this.settingsService)) {
			this.router.navigateToRoute(app.Route.editUser, { id: user.id });
		}
	}
}