import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { detect } from "detect-browser";

@au.autoinject
/**
 * This service provides a method to open an invoice Pdf in a new tab.
 * Other methods can be used from InvoiceClient
 */
export class InvoiceService {
	constructor(protected authService: app.IAuthService, private invoiceClient: app.InvoiceClient, private alertService: at.AlertService,
		private accountClient: app.AccountClient, private i18n: au.I18N, private userClient: app.UserClient, private router: au.Router) { }

	async openPdf(id: number) {
		let browser = detect();
		let pdf = await this.invoiceClient.pdfStream(id);
		if (browser.name === "edge" || browser.name === "ie") {
			window.navigator.msSaveOrOpenBlob(pdf.data, pdf.fileName);
		}
		else {
			var pdfUrl = URL.createObjectURL(pdf.data);
			let newWin = window.open(pdfUrl);
			if (!newWin || newWin.closed || typeof newWin.closed === "undefined") {
				await this.alertService.error("To open invoices please enable popups on this page")
			}
		}
	}

	async checkEBilling(accountSummaries: app.AccountBalanceInfo[]) {
		let res = await this.accountClient.checkEBilling();
		switch (res) {
			case app.CheckEbillingResponse.GuideSingleAccount:
				if (await this.alertService.confirm(this.i18n.tr("Customer.EBillingGuide"))) {
					await this.alertService.usingProgress(async () => {
						await this.userClient.emailInvoices(accountSummaries[0].pkAccount);
						await this.alertService.alert(this.i18n.tr("Customer.EBillingAlert", this.authService.getTokenPayload()));
					}, e => this.alertService.error(this.i18n.tr("EmailInvoices.SubmitError")));
				}
				break;
			case app.CheckEbillingResponse.ConfirmSwitch:
				if (await this.alertService.confirm(this.i18n.tr("Customer.EBillingConfirmSwitch"))) {
					this.router.navigateToRoute(app.Route.emailInvoices);
				}
				break;
			case app.CheckEbillingResponse.Guide:
				if (await this.alertService.confirm(this.i18n.tr("Customer.EBillingGuide"))) {
					this.router.navigateToRoute(app.Route.emailInvoices);
				}
				break;
			case app.CheckEbillingResponse.None:
			default:
		}
	}

}
