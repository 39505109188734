import * as app from "app";

export class BalanceStatusToColourValueConverter {
	toView(value: app.BalanceStatus): string {
		switch (value) {
			case app.BalanceStatus.Payed:
				return "secondary-text";
			case app.BalanceStatus.SmallOverdue:
			case app.BalanceStatus.Overdue:
				return "error-text";
			case app.BalanceStatus.DirectDebit:
				return "secondary-text";
			case app.BalanceStatus.PaymentArrangement:
				return "secondary-text";
			case app.BalanceStatus.ToBePayed:
			case app.BalanceStatus.None:
			default:
				return "";
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}