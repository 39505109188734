import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ChangeCustomerDetails {
	constructor(validationControllerFactory: at.ValidationControllerFactory, private userClient: app.UserClient, private alertService: at.AlertService,
		private webRequestClient: app.WebRequestClient, private i18n: au.I18N, private router: au.Router, private taskQueue: au.TaskQueue,
		private addressClient: app.AddressClient) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<app.WebRequestCustomerDetails, string>(x => x.po_box).required().when(x => this.isPoRequired())
			.ensure(x => x.po_suburb).required().when(x => this.isPoRequired())
			.ensure(x => x.fk_state_po).required().when(x => this.isPoRequired())
			.ensure(x => x.po_postcode).required().when(x => this.isPoRequired())
			.ensure(x => x.street_address_1).required().when(x => this.isAddress1Required())
			.ensure(x => x.suburb_1).required().when(x => this.isAddress1Required())
			.ensure(x => x.fk_state_1).required().when(x => this.isAddress1Required())
			.ensure(x => x.postcode_1).required().when(x => this.isAddress1Required())
			.ensure(x => x.street_address_2).required().when(x => this.isAddress2Required())
			.ensure(x => x.suburb_2).required().when(x => this.isAddress2Required())
			.ensure(x => x.fk_state_2).required().when(x => this.isAddress2Required())
			.ensure(x => x.postcode_2).required().when(x => this.isAddress2Required())
			.ensure(x => x.care_of).required().when(x => this.careOf)
			/**/.satisfies(x => /^((?![cC]\/[oO-]).)*$/gm.test(x)).withMessage("must not contain C/O or C/-")
			.rules;
		this.vmRules = au.ValidationRules
			.ensure<ChangeCustomerDetails, app.Address>(x => x.address).required().when(x => x.addressLookup)
			.ensure(x => x.residentialAddress).required().when(x => !x.isResidential && x.residentialAddressLookup)
			.ensure(x => x.addressType).required().when(x => !x.addressLookup).withMessage("select the address type")
			.rules;
		this.cd = new app.WebRequestCustomerDetails();
		this.validationController.addObject(this.cd, this.rules);
		this.validationController.addObject(this, this.vmRules);
	}

	cd: app.WebRequestCustomerDetails;
	careOf: boolean;
	rawPhoneNumber: string;
	rawHomePhoneNumber: string;
	addressLookup: boolean = true;
	residentialAddressLookup: boolean = true;

	@au.observable
	address: app.Address;
	async addressChanged() {
		if (!this.address) {
			return;
		}
		if (this.address.detailsInSeparateCall) {
			this.address = await this.addressClient.metadata(this.address.id);
		}
		else {
			this.addressType = !!this.address.box_type ? "pobox" : "street";
		}
	}

	residentialAddress: app.Address;

	@au.observable
	addressType: "pobox" | "street" | undefined = undefined;
	addressTypeChanged() {
		if (!this.cd) {
			return;
		}

		if (this.addressType === "pobox") {
			this.clearAddress1();
			this.isResidential = true;
		}
		else {
			this.clearPoBox()
		}

		this.taskQueue.queueTask(() => this.validationController.reset());
	}

	states: app.State[];

	@au.observable
	isResidential: true | "" = true;
	isResidentialChanged() {
		if (this.cd && this.isResidential) {
			this.clearAddress2();
		}
	}

	public validationController: au.ValidationController;
	rules: au.Rule<app.WebRequestCustomerDetails, any>[][];
	vmRules: au.Rule<ChangeCustomerDetails, any>[][];
	isTherePendingRequest: boolean;

	clearPoBox() {
		this.cd.po_box = undefined;
		this.cd.po_suburb = undefined;
		this.cd.fk_state_po = undefined;
		this.cd.po_postcode = undefined;
	}

	clearAddress1() {
		this.cd.street_address_1 = undefined;
		this.cd.suburb_1 = undefined;
		this.cd.fk_state_1 = undefined;
		this.cd.postcode_1 = undefined;
	}

	clearAddress1Lookup() {
		this.address = undefined;
		this.addressType = undefined;
	}

	clearAddress2() {
		this.cd.street_address_2 = undefined;
		this.cd.suburb_2 = undefined;
		this.cd.fk_state_2 = undefined;
		this.cd.postcode_2 = undefined;
		this.residentialAddress = undefined;
	}

	isPoRequired(): boolean {
		return !this.addressLookup && this.addressType === "pobox";
	}

	isAddress1Required(): boolean {
		return !this.addressLookup && this.addressType === "street";
	}

	isAddress2Required(): boolean {
		return !this.residentialAddressLookup && !this.isResidential;
	}

	async activate(): Promise<any> {
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.userClient.getStates().then(x => this.states = x),
				await this.webRequestClient.isTherePendingRequest(null, WebRequestType.ChangeCustomerDetails).then(x => this.isTherePendingRequest = x)
			]);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async submit() {
		let res = await this.validationController.validate();
		if (!res.valid) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			if (this.address) {
				if (this.address.detailsInSeparateCall) {
					this.address = await this.addressClient.metadata(this.address.id);
				}
				if (this.address.box_type) {
					this.cd.po_postcode = parseInt(this.address.postCode);
					this.cd.fk_state_po = this.address.state;
					this.cd.po_suburb = this.address.suburb;
					this.cd.po_box = this.address.streetAddress.substring(0, this.address.streetAddress.lastIndexOf(","));
				}
				else {
					this.cd.postcode_1 = parseInt(this.address.postCode);
					this.cd.fk_state_1 = this.address.state;
					this.cd.suburb_1 = this.address.suburb;
					this.cd.street_address_1 = this.address.streetAddress.substring(0, this.address.streetAddress.lastIndexOf(","));
				}
			}
			if (this.residentialAddress) {
				if (this.residentialAddress.detailsInSeparateCall) {
					this.residentialAddress = await this.addressClient.metadata(this.residentialAddress.id);
				}
				this.cd.postcode_2 = parseInt(this.residentialAddress.postCode);
				this.cd.fk_state_2 = this.residentialAddress.state;
				this.cd.suburb_2 = this.residentialAddress.suburb;
				this.cd.street_address_2 = this.residentialAddress.streetAddress.substring(0, this.residentialAddress.streetAddress.lastIndexOf(","));
			}
			await this.webRequestClient.submitChangeCustomerDetails(new app.WebRequest({ customerDetails: this.cd, pk: 0, guid: null, dateRequested: null }));
			await this.alertService.alert(this.i18n.tr("ChangeCustomerDetails.SubmitSuccess"));
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.error(this.i18n.tr("ChangeCustomerDetails.SubmitError")));
	}

	getAddresses = (criteria: au.ILookupOptionsFunctionParameter<app.Address>): Promise<app.Address[]> => {
		if (criteria.filter !== undefined) {
			if (criteria.filter === "") {
				return Promise.resolve([]);
			}
			else {
				return this.addressClient.autocomplete(criteria.filter);
			}
		}
		else {
			return Promise.resolve([criteria.value]);
		}
	}

	toggleAddressLookup() {
		this.addressLookup = !this.addressLookup;
		this.clearAddress1();
		this.clearAddress1Lookup();
	}

	toggleResidentialAddressLookup() {
		this.residentialAddressLookup = !this.residentialAddressLookup;
		this.clearAddress2();
	}
}