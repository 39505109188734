import { PropertyAccessor, ValidationRules, FluentRules } from "aurelia-validation";

declare module "aurelia-validation" {
	namespace ValidationRules {
		function ensureEx<TObject, TValue>(property: PropertyAccessor<TObject, TValue>): FluentRules<TObject, TValue>;
	}

	interface FluentRuleCustomizer<TObject, TValue> {
		ensure<TValue>(subject: string | ((model: TObject) => TValue)): FluentRules<TObject, TValue>;
	}
}

ValidationRules.ensureEx = function <TObject, TValue>(property: PropertyAccessor<TObject, TValue>): FluentRules<TObject, TValue> {
	return ValidationRules.ensure(property);
}