import { detect } from "detect-browser";

var browser = detect();
var minVersion = 0;
switch (browser.name) {
	case "chrome": minVersion = 61; break;
	case "edge": minVersion = 16; break;
	case "firefox": minVersion = 56; break;
	case "ie": minVersion = 11; break;
	case "safari": minVersion = 9; break;
	default: minVersion = 0;
}
if (minVersion > parseInt(browser.version.split(".")[0])) {
	window.location.href = "old_browser.html";
}

