import "materialize-css/dist/css/materialize.css";
import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import "./root.scss";
import { configure as gaConfigure } from "aurelia-google-analytics";
import { ApplicationInsights, RemoteDependencyData, IDependencyTelemetry } from "@microsoft/applicationinsights-web";

declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build

@au.autoinject
export class Root {
	constructor(private http: au.HttpClient, private exceptionService: at.ExceptionService, private twAuthService: app.TwAuthService,
		private diagnosticsClient: app.DiagnosticsClient, private settingsService: app.SettingsService, private router: au.Router, private eventAggregator: au.EventAggregator,
		private dateService: at.DateService, private taskQueue: au.TaskQueue, private i18N: au.I18N, private msalService: app.MsalService, private container: au.Container,
		private aurelia: au.Aurelia, private alertService: at.AlertService, private appInsights: ApplicationInsights) {
		this.registerExceptions();
		this.logger = au.getLogger("Root");
	}

	settings: app.BrowserSettings;
	versions: app.GetVersionsResponse;
	navModels: au.NavModel[];
	role: string;
	logger: au.Logger;
	navBar: at.AppNavBar;
	authService: app.IAuthService;

	configureRouter(config: au.RouterConfiguration) {
		config.title = this.i18N.tr("Company");
		config.addAuthorizeStep(app.RoleCheckStep);
		config.addPipelineStep("postcomplete", app.PostCompleteStep);
		config.addPipelineStep("modelbind", at.AppInsightsStep);
		config.addPipelineStep("modelbind", app.AnalyticsStep);
		const pc = app.PermissionCode;
		let t = this.i18N.tr.bind(this.i18N);
		let requestMenu = { title: t("Menu.Request"), order: 5 };
		let menuRoutes: at.IAppRouteConfig[] = [
			{ route: app.Route.home, name: app.Route.home, title: t("Menu.Home"), order: 1 },
			{ route: app.Route.login, title: t("Menu.Login"), auth: at.AuthStatus.All, order: 2 },
			{ route: app.Route.register, title: t("Menu.Register"), auth: at.AuthStatus.NonAuthenticated, order: 3 },
			{ route: app.Route.account + "/:pkAccount?", href: app.Route.account, name: app.Route.account, permission: pc.GetAccountDetails, title: t("Menu.Account"), order: 4 },
			{ route: app.Route.accountPayment + "/:id?", href: app.Route.accountPayment, name: app.Route.accountPayment, permission: pc.PayAccount, title: t("Menu.Pay"), order: 7 },
			{ route: app.Route.adminDashboard, moduleId: "views/admin-dashboard/admin-dashboard", title: t("Menu.Dashboard"), permission: pc.GetStatistics, order: 7 },
			{ route: app.Route.roles, title: t("Menu.Roles"), permission: pc.ManageRoles, order: 8 },
			{ route: app.Route.users, title: t("Menu.Users"), permission: pc.SearchUsers, order: 9 },
			{ route: app.Route.organisations, title: t("Menu.Organisations"), permission: pc.ManageOrganisations, order: 10 },
			{ route: app.Route.editSettings, title: t("Menu.Settings"), permission: pc.EditSettings, order: 11 },
			// request menu
			{ route: app.Route.changeName, title: t("Menu.ChangeName"), permission: pc.ChangeName, menu: requestMenu, webRequestType: WebRequestType.ChangeName },
			{ route: app.Route.changeCustomerDetails, title: t("Menu.ChangeCustomerDetails"), permission: pc.ChangeCustomerDetails, menu: requestMenu, webRequestType: WebRequestType.ChangeCustomerDetails },
			{ route: app.Route.changeAddress, title: t("Menu.ChangeAddress"), permission: pc.ChangeAddress, menu: requestMenu, webRequestType: WebRequestType.ChangeAddresses },
			{ route: app.Route.meterReading, title: t("Menu.MeterReading"), permission: pc.SubmitMeterReading, menu: requestMenu, webRequestType: WebRequestType.MeterReading },
			{ route: app.Route.emailInvoices, title: t("Menu.EmailInvoices"), permission: pc.SubmitEmailInvoice, menu: requestMenu, webRequestType: WebRequestType.EmailInvoices },
			{ route: app.Route.directDebit, title: t("Menu.DirectDebit"), permission: pc.SubmitDirectDebit, menu: requestMenu, webRequestType: WebRequestType.DirectDebit },
			{ route: app.Route.concession, title: t("Menu.Concession"), permission: pc.SubmitConcession, menu: requestMenu, webRequestType: WebRequestType.Concession },
			{ route: app.Route.paymentArrangement, title: t("Menu.PaymentArrangement"), permission: pc.SubmitPaymentArrangement, menu: requestMenu, webRequestType: WebRequestType.PaymentArrangement },
			{ route: app.Route.newTenant + "/:guid?", title: t("Menu.NewTenant"), href: app.Route.newTenant, name: app.Route.newTenant, moduleId: "views/tenancy/tenancy", permission: pc.SubmitTenancy, menu: requestMenu, webRequestType: WebRequestType.NewTenant },
			{ route: app.Route.vacatingTenant + "/:guid?", title: t("Menu.VacatingTenant"), href: app.Route.vacatingTenant, name: app.Route.vacatingTenant, moduleId: "views/tenancy/tenancy", permission: pc.SubmitTenancy, menu: requestMenu, webRequestType: WebRequestType.VacatingTenant },
			{ route: app.Route.infoStatement + "/:guid?", title: t("Menu.InformationStatement"), href: app.Route.infoStatement, name: app.Route.infoStatement, permission: pc.SubmitInformationStatement, menu: requestMenu, webRequestType: WebRequestType.InfoStatement },

			{ route: app.Route.invoices + "/:id?", href: app.Route.invoices, name: app.Route.invoices, moduleId: "views/invoices/invoices", title: t("Menu.SearchInvoices"), permission: pc.SearchInvoices, order: 12 },
			{ route: app.Route.payments, title: t("Menu.Payments"), permission: pc.SearchPayments, order: 13 },
			{ route: app.Route.profile, title: t("Menu.Profile"), auth: at.AuthStatus.Authenticated, order: 99 },
			{ route: app.Route.logout, title: t("Menu.Logout"), navigationStrategy: i => this.logoutNavStrategy(i), auth: at.AuthStatus.Authenticated, order: 100 },
		];
		if (this.settings.showDailyWaterUse) {
			menuRoutes.push({ route: app.Route.volumesPeriod, moduleId: "views/volumes-period/volumes-period", title: t("Menu.MeterVolume"), permission: pc.SubmitMeterReading, order: 14 });
		}
		menuRoutes.forEach(x => { x.nav = true; x.tooltip = x.title; });
		let routes: at.IAppRouteConfig[] = [
			...menuRoutes,
			{ route: app.Route.accountPayments + "/:id?", href: app.Route.accountPayments, name: app.Route.accountPayments, moduleId: "views/account-payments/account-payments", title: t("Menu.SearchPayments") || "View Payments", permission: pc.SearchAccountPayments, order: 13, nav: !!t("Menu.SearchPayments") },
			{ route: ["", app.Route.home], name: app.Route.home },
			{ route: app.Route.forbidden },
			{ route: app.Route.expired },
			{ route: app.Route.farewell },
			{ route: app.Route.notfound },
			{ route: app.Route.loginB2C },
			{ route: app.Route.loginB2CError },
			{ route: app.Route.registerB2C, auth: at.AuthStatus.NonAuthenticated },
			{ route: app.Route.confirmEmail, title: "Confirm email" },
			{ route: app.Route.changeEmail, title: "Change email" },
			{ route: app.Route.resetPassword, title: "Reset password" },
			{ route: app.Route.role + "/:id?", name: app.Route.role, permission: pc.ManageRoles },
			{ route: app.Route.editUser + "/:id", name: app.Route.editUser, permission: pc.EditUsers },
			{ route: app.Route.inviteUser, moduleId: "views/register/register", permission: pc.InviteUsers },
			{ route: app.Route.customer, permission: pc.GetAccountDetails, title: t("Customer.Title") },
			{ route: app.Route.bulkCustomer, permission: pc.GetAccountDetails, title: t("Customer.Title") },
			{ route: app.Route.agent, role: [app.Roles.agent] },
			{ route: app.Route.agencyManager, role: [app.Roles.agencyManager] },
			{ route: app.Route.conveyancer, role: [app.Roles.conveyancer] },
			{ route: app.Route.editOrganisation + "/:id?", name: app.Route.editOrganisation, permission: pc.ManageOrganisations },
			{ route: app.Route.balanceCheck, auth: at.AuthStatus.All }
		];
		routes.forEach(r => {
			if (!r.name) {
				r.name = <string>r.route;
			}
			if (!r.moduleId && r.route !== app.Route.logout) {
				r.moduleId = `views/${r.name}/${r.name}`;
			}
			r.title = r.title ? r.title : r.name.toString();
			if (r.role || r.permission) {
				r.auth = at.AuthStatus.Authenticated;
			}
		});
		config.map(routes);
		config.mapUnknownRoutes(`views/${app.Route.notfound}/${app.Route.notfound}`);
	}

	async attached() {
		this.eventAggregator.subscribe("authentication-change", () => this.authenticationChange());
		await this.authenticationChange();

		// this is used by msal to redirect to b2c-error page after routes have been registered
		this.eventAggregator.publish("root-attached");
	}

	async authenticationChange() {
		await this.refreshRoutes();
		if (await this.authService.isAuthenticatedAsync()) {
			let lastLoginDate = window.localStorage.getItem("lastLoginDate");
			if (!lastLoginDate || au.moment(lastLoginDate).isBefore(au.moment(this.dateService.now()).add(-7, "days"))) {
				this.taskQueue.queueTask(() => this.navBar.highlightMenuButton());
				window.localStorage.setItem("lastLoginDate", this.dateService.now().toISOString());
			}
		}
	}

	async activate() {
		try {
			this.http.configure(c => {
				c.baseUrl = ".";
				// if need to save cookies uncomment the following
				// c.defaults.credentials = "same-origin";
				c.withInterceptor({ response: r => this.assertResponse(r), responseError: e => this.responseError(e) });
			});
			await this.settingsService.loadSettings();

			this.authService = this.msalService.isEnabled() ? this.msalService : this.twAuthService;
			this.container.registerInstance(app.IAuthService, this.authService);

			if (!document.location.href.endsWith(app.Route.balanceCheck)) {
				this.logger.debug("this.authService.init");
				await this.authService.init();
			}

			this.settings = this.settingsService.browserSettings;

			this.appInsights.config = {
				instrumentationKey: this.settings.appInsightsInstrumentationKey,
				disableFetchTracking: false
			};
			this.appInsights.loadAppInsights();
			this.appInsights.addTelemetryInitializer(function (item) {
				// 422 means an Api error, not a dependency failure
				if (item.baseType === RemoteDependencyData.dataType && item.baseData && (item.baseData as IDependencyTelemetry).responseCode === 422) {
					item.baseData.success = true;
				}
			});

			if (this.settings.googleAnalytics.id) {
				let fc = new au.FrameworkConfiguration(this.aurelia);
				gaConfigure(fc, config => {
					config.init(this.settings.googleAnalytics.id);
					config.attach({
						logging: {
							enabled: IS_DEV_BUILD
						},
						pageTracking: {
							enabled: this.settings.googleAnalytics.pageTracking,
							getUrl: payload => "/" + payload.instruction.config.name
						},
						clickTracking: {
							enabled: this.settings.googleAnalytics.clickTracking,
						},
						exceptionTracking: {
							enabled: this.settings.googleAnalytics.exceptionTracking
						}
					});
				});
			}

			this.diagnosticsClient.getVersions().then(x => this.versions = x);

			let favicon = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement;
			if (favicon && this.settings.faviconUrl) {
				favicon.href = this.settings.faviconUrl;
			}
		}
		catch (e) {
			this.alertService.error("Error occurred while loading the portal");
			this.appInsights.trackException(e);
			throw e;
		}
	}

	async assertResponse(r: Response): Promise<Response> {
		if (r.ok) {
			return r;
		}
		else if (r.status === 401) {
			throw new at.UnauthorizedException();
		}
		else if (r.status === 403) {
			throw new at.ForbiddenException();
		}
		else {
			return r.json().then<any>((d: at.IApiException) => this.exceptionService.throw(d));
		}
	}

	responseError(error: any): Promise<Response> {
		this.appInsights.trackException(error);
		throw error;
	}

	async logoutNavStrategy(instruction: au.NavigationInstruction): Promise<void> {
		await this.authService.logout(false);
		instruction.config.redirect = app.Route.login;
	}

	async refreshRoutes() {
		let authenticated = await this.authService.isAuthenticatedAsync();
		this.logger.info("Refreshing routes, authenticated = ", authenticated);
		let token: at.ITokenPayload;
		if (authenticated) {
			token = this.authService.getTokenPayload();
			this.role = token.role as string;
			this.appInsights.setAuthenticatedUserContext(token.userid);
		}
		else {
			this.appInsights.clearAuthenticatedUserContext();
			this.role = undefined;
		}
		this.navModels = this.router.navigation
			.filter(x => app.isRouteAllowed(x.configEx, token, this.settingsService)
				&& (x.config.name != app.Route.login || !authenticated));
	}

	registerExceptions() {
		this.exceptionService.register("AspNetToolkit.Exceptions.UnconfirmedEmailException", at.UnconfirmedEmailException);
		this.exceptionService.register("AspNetToolkit.Exceptions.InvalidCredentialsException", at.InvalidCredentialsException);
		this.exceptionService.register("AspNetToolkit.Exceptions.TooManyRecordsException", at.TooManyRecordsException);
		this.exceptionService.register("AspNetToolkit.Exceptions.IdentityException", app.IdentityException);
		this.exceptionService.register("AspNetToolkit.Exceptions.LockedOutException", app.LockedOutException);
		this.exceptionService.register("AquaWeb.Common.Exceptions.EmailTakenException", app.EmailTakenException);
		this.exceptionService.register("AquaWeb.Common.Exceptions.InactiveUserException", app.InactiveUserException);
		this.exceptionService.register("AquaWeb.Common.Exceptions.NotMatchingBirthDateException", app.NotMatchingBirthDateException);
		this.exceptionService.register("AquaWeb.Common.Exceptions.NotFoundException", app.NotFoundException);
	}
}