import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class InfoStatementTitle {
	constructor(private element: Element, validationControllerFactory: at.ValidationControllerFactory, private taskQueue: au.TaskQueue) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<app.WebRequestInfoStmtTitle, string>(x => x.volume_no).maxLength(10)
			.ensure(x => x.folio_no).maxLength(10)
			.ensure(x => x.lot_no).maxLength(30)
			.ensure(x => x.plan_no).maxLength(30)
			.ensure(x => x.crown_allotment).maxLength(50)
			.ensure(x => x.section).maxLength(30)
			.ensure(x => (<any>x).parishFilter).satisfies(x => !x).when(x => !x.fk_parish).withMessage("Select parish or clear field")
			.rules;
	}

	public validationController: au.ValidationController;
	rules: au.Rule<app.WebRequestInfoStmtTitle, any>[][];

	@au.bindable
	title: app.WebRequestInfoStmtTitle;
	titleChanged(newValue: app.WebRequestInfoStmtTitle, oldValue: app.WebRequestInfoStmtTitle) {
		if (!this.readonly) {
			if (oldValue) {
				this.validationController.removeObject(oldValue);
			}
			this.validationController.addObject(this.title, this.rules);
		}
		this.taskQueue.queueTask(() => this.validationController.reset());
	}

	@au.bindable
	planTypes: app.PlanType[];

	@au.bindable
	parishes: app.Parish[];

	@au.bindable
	readonly: boolean;
	readonlyChanged() {
		if (this.readonly) {
			this.validationController.removeObject(this.title);
		} else {
			this.validationController.addObject(this.title, this.rules);
		}
	}

	remove(e: Event) {
		au.fireEvent(this.element, "remove");
		e.stopPropagation();
	}

	getParishes(p: au.ILookupOptionsFunctionParameter<string>): Promise<app.Parish[]> {
		if (p.filter !== undefined) {
			return Promise.resolve(this.parishes.filter(x => x.description.toUpperCase().includes(p.filter.toUpperCase())));
		}
		else {
			return Promise.resolve(p.value ? this.parishes.filter(x => x.code === p.value) : []);
		}
	}
}