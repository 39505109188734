import * as moment from "moment";
import * as app from "app";

export class AccountBalanceInfoToClassValueConverter {
	toView(value: app.AccountBalanceInfo | app.AccountInfo): string {
		switch (value.fkAccountStatus) {
			case app.AccountStatus.Active:
				return value.isClosed ? "closed" : "active"; // status might be active but account is closed because an owner sold a property
			case app.AccountStatus.Closed:
				return "closed";
			case app.AccountStatus.New:
				return "new";
			case app.AccountStatus.WaitingForClosure:
				return "waiting-closure";
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}