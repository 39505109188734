import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Concession {
	constructor(private webRequestClient: app.WebRequestClient, private validationControllerFactory: at.ValidationControllerFactory, private alertService: at.AlertService,
		private router: au.Router, private i18n: au.I18N, private dateService: at.DateService, private accountClient: app.AccountClient,
		private authService: app.IAuthService, private taskQueue: au.TaskQueue) {
		this.validationController = this.validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules
			.ensure<Concession, app.AccountBalanceInfo>(x => x.selectedAccount).required()
			/**/.satisfies(x => !x || !x.concessionApplied).withMessage(this.i18n.tr("Concession.ConcessionAppliedError"))
			.ensure(x => x.consentGiven).satisfies(x => x).withMessage(this.i18n.tr("Concession.ConsentError"))
			.ensure(x => x.isResident).satisfies(x => x).withMessage(this.i18n.tr("Concession.NotResidentError"))
			.ensure(x => x.isNameCorrect).satisfies(x => x).withMessage(this.i18n.tr("Concession.NotCorrectNameError"))
			.ensure(x => x.pensionNo).required()
			/**/.satisfies(x => !x || this.pensionNoRegExp.test(x))
			.ensure(x => x.pensionStartDate).required()
			/**/.satisfies(async x => !x || au.moment(x).isBefore(await this.dateService.getServerDate())).withMessage("cannot be in the future")
			.rules;
		this.validationController.addObject(this, this.rules);
		this.validationController.subscribe(e => {
			const selectedAccountResults = e.results.filter(x => x.propertyName === "selectedAccount")
			this.selectedAccountValid = !!selectedAccountResults.length && !selectedAccountResults.find(x => !x.valid);
		});
	}

	validationController: au.ValidationController;
	rules: au.Rule<Concession, any>[][];
	accounts: app.AccountBalanceInfo[];
	pensionTypes: app.PensionType[];

	@au.observable
	pensionType: app.PensionType;
	pensionTypeChanged() {
		this.updatePensionNoRegExp();
		this.pensionNo = undefined;
		this.taskQueue.queueTask(() => this.validationController.reset({ object: this, propertyName: "pensionNo" }));
	}

	cardholderName: string;
	pensionNo: string;
	pensionStartDate: Date;
	pensionNoRegExp: RegExp;
	consentGiven: boolean;
	isResident: boolean;
	isNameCorrect: boolean;
	isTherePendingRequest: boolean;

	@au.observable
	selectedAccount: app.AccountBalanceInfo;
	async selectedAccountChanged() {
		this.isTherePendingRequest = await this.webRequestClient.isTherePendingRequest(this.selectedAccount.pkAccount, WebRequestType.Concession);
	}
	selectedAccountValid: boolean;

	updatePensionNoRegExp() {
		if (!this.pensionType || this.pensionType.code === "H" || this.pensionType.code === "S") {
			this.pensionNoRegExp = /[0-9]{9}[a-zA-Z]/;
		}
		else {
			this.pensionNoRegExp = /^[0-9]{3}$|^[0-9]{9}[a-zA-Z]$|^[TVNQSW][a-zA-Z][a-zA-Z0-9]([a-zA-Z0-9](\d(\d(\d([a-zA-Z0-9]?[a-zA-Z]?)?)?)?)?)?$/;
		}
	}

	async canActivate(): Promise<any> {
		return await this.alertService.usingProgress(async () => {
			this.accounts = await this.accountClient.getAccountSummaries(true, true);
			if (!this.accounts.length) {
				await this.alertService.alert("You don't have accounts eligible for concession.", "warning", "orange");
				return false;
			}
			else if (this.accounts.length === 1) {
				this.selectedAccount = this.accounts[0];
			}
		}, async e => { await this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e); return false; });
	}

	bind() {
		if (this.selectedAccount) {
			this.validationController.validate({ object: this, propertyName: "selectedAccount" });
		}
	}

	async activate(params: IHaveId) {
		await this.alertService.usingProgress(async () => {
			if (params && params.id) {
				this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
			}
			this.pensionTypes = await this.webRequestClient.getPensionTypes();
			this.cardholderName = this.authService.getTokenPayload().given_name + " " + this.authService.getTokenPayload().family_name;
			this.updatePensionNoRegExp();
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async submit() {
		let res = await this.validationController.validate();
		if (!res.valid) {
			await this.alertService.error("Please correct the invalid fields");
			return;
		}
		if (!await this.alertService.confirm("Are you sure you would like to submit this Concession application?", "warning", "orange")) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			let wrc = new app.WebRequestConcession({
				fk_pension_type: this.pensionType.code,
				pension_no: this.pensionNo,
				pension_start_date: this.pensionStartDate.asUtc(),
				pk: 0,
				fk_web_request: 0
			});
			let request = new app.WebRequest({ fkAccount: this.selectedAccount.pkAccount, concession: wrc, pk: 0, guid: null, dateRequested: null });
			await this.webRequestClient.submitConcession(request);
			await this.alertService.alert(app.Strings.webRequestSuccess);
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.criticalError("Error occurred while submitting the concession application", e));
	}

}