import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { setTimeout } from 'timers';

@au.autoinject
export class SettingsService {
	constructor(private settingsClient: app.SettingsClient) {
	}

	browserSettings: app.BrowserSettings;
	paymentSettings: app.PaymentSettings;
	aquaRateSettings: app.AquaRateSettings;
	passwordOptions: app.PasswordSettings;
	azureB2CSettings: app.AzureB2CSettings;
	localTimestamp: string;
	allowedWebRequests: string[];
	allowedFileExtensions: string[];

	async loadSettings(): Promise<any> {
		await Promise.all([
			this.settingsClient.getAquaRateSettings().then(x => this.aquaRateSettings = x),
			this.settingsClient.getBrowserSettings().then(x => this.browserSettings = x),
			this.settingsClient.getPasswordOptions().then(x => this.passwordOptions = x),
			this.settingsClient.getPaymentSettings().then(x => this.paymentSettings = x),
			this.settingsClient.getAzureB2CSettings().then(x => this.azureB2CSettings = x)
		]);
		this.allowedWebRequests = this.aquaRateSettings.webRequestTypes.split(",");
		this.allowedFileExtensions = this.browserSettings.allowedFileExtensions.split(",");
		this.checkRefreshTimestamp();
	}

	isWebRequestAllowed(webRequestType: string): boolean {
		return this.allowedWebRequests.indexOf(webRequestType) !== -1;
	}

	isFileAllowed(filename: string) {
		return this.allowedFileExtensions.indexOf("." + filename.split(".").pop()) !== -1;
	}

	async checkRefreshTimestamp() {
		try {
			let timestamp = await this.settingsClient.getRefreshTimestamp();
			if (!this.localTimestamp) {
				this.localTimestamp = timestamp;
			}
			else if (this.localTimestamp !== timestamp) {
				this.localTimestamp = timestamp;
				document.location.reload();
			}
		}
		catch {

		}
		setTimeout(() => this.checkRefreshTimestamp(), this.browserSettings.refreshTimestampCheckTimeout * 1000);
	}


}