import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class MeterReading {
	constructor(private meterClient: app.MeterClient, private alertService: at.AlertService, private router: au.Router,
		validationConrollerFactory: at.ValidationControllerFactory, private settingsService: app.SettingsService, private i18n: au.I18N) {
		this.validationController = validationConrollerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<MeterReading, number>(x => x.reading).required()
			.ensure(x => x.meter).required()
			.ensure(x => x.files).satisfies(x => x.length > 0).withMessage("must be attached")
			/**/.satisfies(x => !x.find(y => !this.settingsService.isFileAllowed(y.fileName))).withMessage("not allowed file selected")
			.on(this);
	}

	meters: app.MeterInfo[] = [];
	meter: app.MeterInfo;
	files: at.IFileRow[] = [];
	maxFileSize = this.settingsService.browserSettings.maxFileSize;
	allowedExtensions = this.settingsService.browserSettings.allowedFileExtensions;

	@au.computedFrom("meter")
	get mask() {
		return this.meter ? `9{1,${this.meter.meter.noOfDials}}` : "9{1,}";
	}

	@au.ato.bindable.numberMd
	reading: number;

	validationController: au.ValidationController;

	async activate(): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.meters = await this.meterClient.getConnected();
			if (this.meters.length === 1) {
				this.meter = this.meters[0];
			}
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async submit(): Promise<any> {
		let res = await this.validationController.validate();
		if (!res.valid) {
			return;
		}

		if (this.reading < this.meter.lastReading.readingValue
			&& !await this.alertService.confirm(this.i18n.tr("MeterReading.LessThanPreviousWarning"), "warning", "orange")) {
			return;
		}

		if (this.reading > this.meter.lastReading.readingValue + this.settingsService.browserSettings.highReadWarningThreshold
			&& !await this.alertService.confirm(this.i18n.tr("MeterReading.HighReadWarning", { highReadWarningThreshold: this.settingsService.browserSettings.highReadWarningThreshold }), "warning", "orange")) {
			return;
		}

		if (this.files.length > this.settingsService.browserSettings.maxFilesPerRequest) {
			this.alertService.error(this.i18n.tr("MaxFileCountError"));
			return;
		}

		if (this.files.find(x => x.isTooBig)) {
			this.alertService.error(this.i18n.tr("MaxFileSizeError"));
			return;
		}

		await this.alertService.usingProgress(async () => {
			await this.meterClient.saveMeterReading(this.meter.account.pkAccount, this.meter.meter.pk, this.meter.meter.serialNo, this.reading,
				this.files.map(x => ({ data: x.file, fileName: `${x.fileName}` }))
			);
			await this.alertService.alert(this.i18n.tr("MeterReading.SubmitSuccess"));
			this.router.navigateToRoute(app.Route.home);
		}, e => this.alertService.error(this.i18n.tr("MeterReading.SubmitError")));
	}

	filesChanged() {
		this.validationController.validate({ object: this, propertyName: "files" });
	}
}