import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class DashboardCard {
	constructor(private element: Element) { }

	@au.ato.bindable.stringMd
	title: string;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	from: Date;

	@au.bindable({ defaultBindingMode: au.bindingMode.twoWay })
	to: Date;

	@au.bindable
	isLoading: boolean;

	datesChanged() {
		au.fireEvent(this.element, "dates-changed");
	}
}