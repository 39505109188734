export class invoiceTypeValueConverter {
	toView(value: string, colourInvoices: boolean): string {
		if (!colourInvoices) {
			return "";
		}

		switch (value) {
			case "F": return "red-text";
			case "R": return "green-text";
			case "I":
			default: return "";
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}