export class Roles {
	static admin: string = "Admin";
	static supervisor: string = "Supervisor";
	static staff: string = "Staff";
	static agent: string = "Agent";
	static agencyManager: string = "Agency Manager";
	static residentialCustomer: string = "Residential Customer";
	static businessCustomer: string = "Business Customer";
	static conveyancer: string = "Conveyancer";
	static customers: string[] = [Roles.residentialCustomer, Roles.businessCustomer];
	static all: string[] = [Roles.residentialCustomer, Roles.businessCustomer, Roles.agent, Roles.admin, Roles.conveyancer, Roles.agencyManager];
}