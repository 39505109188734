import * as app from "app";
import * as au from "aurelia";
import * as at from "aurelia-toolkit";

declare module "../../services/api" {
	// tslint:disable:interface-name
	interface PermissionOfPermissionCode {
		isSelected?: boolean;
	}
	// tslint:enable:interface-name
}

@au.autoinject
export class Role {
	constructor(private roleClient: app.RoleClient, private alertService: at.AlertService, private router: au.Router,
		validationControllerFactory: at.ValidationControllerFactory) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.rules = au.ValidationRules.ensure<app.RoleDto, string>(x => x.displayName).required()
			.rules;
	}

	validationController: au.ValidationController;
	role: app.RoleDto;
	rules: au.Rule<app.RoleDto, any>[][];

	determineActivationStrategy() {
		return au.activationStrategy.invokeLifecycle;
	}

	async activate(params: at.IHaveId) {
		await this.alertService.usingProgress(async () => {
			if (this.role) {
				this.validationController.removeObject(this.role);
			}
			let permissions: app.PermissionOfPermissionCode[];
			await Promise.all([
				this.roleClient.permissions().then(x => permissions = x),
				this.roleClient.get(params.id).then(x => this.role = x)
			]);
			permissions.forEach(x => x.isSelected = !!this.role.permissions.find(y => y.code === x.code));
			this.role.permissions = permissions.sort((a, b) => (a.isSelected !== b.isSelected) ? (Number(b.isSelected) - Number(a.isSelected)) : a.name.localeCompare(b.name));
			this.validationController.addObject(this.role, this.rules);
		}, e => this.alertService.error("Error fetching the role"));
	}

	async revert() {
		await this.activate({ id: this.role.id });
	}

	async save() {
		const validationResult = await this.validationController.validate();
		if (!validationResult.valid) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			this.role.permissions = null; // no need to pass these as they are not editable
			const id = await this.roleClient.post(this.role);
			this.router.navigateToRoute(app.Route.role, { id }, { replace: true });
		}, e => this.alertService.error("Error saving the role"));
	}
}
