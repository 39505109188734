import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { Tenant } from "custom-elements/tenant/tenant";

@au.autoinject
export class NewTenancy {
	constructor(validationControllerFactory: at.ValidationControllerFactory, private propertyClient: app.PropertyClient, private organisationClient: app.OrganisationClient,
		private dateService: at.DateService, private alertService: at.AlertService, private router: au.Router, private webRequestClient: app.WebRequestClient,
		private taskQueue: au.TaskQueue, private i18n: au.I18N) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.requestRules = au.ValidationRules
			.ensure<app.SubmitTenancyRequest, number>(x => x.fkProperty).required()
			.rules;
		this.rules = au.ValidationRules
			.ensure<app.WebRequestTenancy, Date>(x => x.dateMoving).required().then().satisfies(async x => au.moment(x).isAfter(await this.dateService.getServerDate())).withMessage("must be in the future.")
			.ensure(x => x.meterNumber).maxLength(20)
			.ensure(x => x.meterReading).required().when(x => !!x.meterNumber)
			.ensure(x => x.dateRead).required().when(x => !!x.meterNumber)
			.ensure(x => x.meterReaderName).required().when(x => !!x.meterNumber).maxLength(80)
			.rules;
	}

	validationController: au.ValidationController;
	organisation: app.Organisation2;
	request: app.SubmitTenancyRequest;
	tenantVMs: Tenant[] = [];
	isNewTenancy: boolean;
	isReadonly: boolean;
	fullAddress: string;
	rules: au.Rule<app.WebRequestTenancy, any>[][];
	requestRules: au.Rule<app.SubmitTenancyRequest, any>[][];

	determineActivationStrategy() {
		return au.activationStrategy.replace;
	}

	async activate(params: IHaveGuid, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<any> {
		if (params.guid) {
			let wr = await this.webRequestClient.getTenancyRequest(params.guid);
			this.request = new app.SubmitTenancyRequest(wr);
			this.isReadonly = wr.fkRequestStatus !== "N";
		}
		else {
			this.request = app.SubmitTenancyRequest.fromJS({ Tenancy: {}, Tenants: [{ isSelected: true }] });
			this.fullAddress = "";
			this.isReadonly = false;
		}
		if (!this.isReadonly) {
			this.validationController.addObject(this.request.tenancy, this.rules);
			this.validationController.addObject(this.request, this.requestRules);
		}
		this.isNewTenancy = navigationInstruction.config.name === app.Route.newTenant;
		this.request.fkRequestType = this.isNewTenancy ? "NT" : "VT";
		this.organisation = await this.organisationClient.getMy();
	}

	deactivate() {
		this.validationController.removeObject(this.request.tenancy);
	}

	getProperties(criteria: au.ILookupOptionsFunctionParameter<number>): Promise<app.PropertyInfo[]> {
		if (criteria.filter) {
			return this.propertyClient.find(criteria.filter);
		}
		else if (criteria.value) {
			return this.propertyClient.getPropertyInfo(criteria.value).then(x => [x]);
		}
		else {
			return Promise.resolve([]);
		}
	}

	addTenant() {
		this.unselectTenant();
		let tenant = new app.WebRequestTenant();
		(<any>tenant).isSelected = true;
		this.request.tenants.push(tenant);
	}

	removeTenant(t: app.WebRequestTenant) {
		this.request.tenants.splice(this.request.tenants.indexOf(t), 1);
		this.validationController.removeObject(t);
	}

	async submit() {
		if (!this.request.tenants.length) {
			this.alertService.error("Please add at least one tenant");
			return;
		}
		let results = this.tenantVMs.filter(x => x !== null).map(x => x.validationController.validate());
		results.push(this.validationController.validate());
		if ((await Promise.all(results)).find(x => !x.valid)) {
			return;
		}

		try {
			this.alertService.showProgress();
			await this.webRequestClient.submitTenancy(this.request);
			await this.alertService.alert(this.i18n.tr(this.isNewTenancy ? "NewTenant.SubmitSuccess" : "VacatingTenant.SubmitSuccess"));
			this.router.navigateToRoute(app.Route.home);
		}
		catch (e) {
			this.alertService.error(this.i18n.tr(this.isNewTenancy ? "NewTenant.SubmitError" : "VacatingTenant.SubmitError"));
		}
		finally {
			this.alertService.hideProgress();
		}

	}

	unselectTenant() {
		this.request.tenants.forEach(x => (<any>x).isSelected = false);
	}

	selectTenant(t: app.WebRequestTenant) {
		this.unselectTenant();
		(<any>t).isSelected = true;
		return true;
	}

}