import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class AnalyticsStep implements au.PipelineStep {
	constructor(private analyticsClient: app.AnalyticsClient, private dateService: at.DateService, private authService: app.IAuthService) {
		this.logger = au.getLogger("AnalyticsStep");
		this.logger.debug("Created");
	}

	logger: au.Logger;

	run(navigationInstruction: au.NavigationInstruction, next: au.Next) {
		this.internalRun(navigationInstruction, next);
		return next();
	}

	async internalRun(navigationInstruction: au.NavigationInstruction, next: au.Next) {
		if (!await this.authService.isAuthenticatedAsync() || !navigationInstruction.config.name) {
			return;
		}
		let origin = window.location.pathname + window.location.hash;
		let path = origin.replace("/#/", "/").replace("#", "");
		this.logger.debug(`Tracking for ${path}`);
		try {
			await this.analyticsClient.track(new app.AnalyticsEvent({
				id: 0,
				type: "Page",
				name: navigationInstruction.config.name,
				data: Object.getOwnPropertyNames(navigationInstruction.params).length ? JSON.stringify(navigationInstruction.params) : null,
				duration: "00:00",
				success: true,
				date: this.dateService.now()
			}));
		}
		catch {

		}
	}
}
