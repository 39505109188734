import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Account {
	constructor(private invoiceClient: app.InvoiceClient, private invoiceService: app.InvoiceService, private accountClient: app.AccountClient, private alertService: at.AlertService,
		private dateService: at.DateService, private taskQueue: au.TaskQueue, private router: au.Router, private settingsService: app.SettingsService, private authService: app.IAuthService,
		private paymentClient: app.PaymentClient, private i18n: au.I18N, private paymentService: app.PaymentService) {
		this.logger = au.getLogger(this.constructor.name);
	}

	balanceInfo: app.AccountBalanceInfo;
	invoices: app.InvoiceHeader[];
	payments: app.AccountPayment[];
	lastInvoice: app.InvoiceHeader;
	isPayed: boolean;
	isToBePayed: boolean;
	isOverdue: boolean;
	serverDate: Date;
	volumes: Chart.ChartData = {};
	avgVolumes: Chart.ChartData = {};
	logger: au.Logger;
	showFixedAddress: boolean;
	accounts: app.AccountInfo[];
	payment: app.Payment;
	modal: au.MdModal;
	Routes = app.Route;
	totalChart: HTMLUnknownElement;
	avgChart: HTMLUnknownElement;
	avgChartVm: any;
	showDailyUse = this.settingsService.browserSettings.showDailyWaterUse;
	showInvoiceDates = this.settingsService.browserSettings.account.showInvoiceDates;
	totalWaterUseUom = this.settingsService.browserSettings.account.totalWaterUseUom;
	WaterUseUom = app.WaterUseUom;
	dateFormat = this.settingsService.browserSettings.dateFormat;
	colourInvoices = this.settingsService.browserSettings.account.colourInvoices;
	showShortcuts = this.settingsService.browserSettings.account.showShortcuts;
	minVolumeDate: Date;
	bulkCustomer: boolean;
	isDatesUsageFilter = this.settingsService.browserSettings.account.waterUsageFilter === app.WaterUsageFilter.Dates;
	billsUsageFilterOptions = Array.from(Array(this.settingsService.browserSettings.account.waterUsageBillsFilterMax), (x, i) => i + 1);
	concessionAllowed: boolean;

	@au.observable
	billsUsageFilter = this.settingsService.browserSettings.account.waterUsageBillsFilterDefault;
	billsUsageFilterChanged() {
		if (!this.isDatesUsageFilter && this.balanceInfo) {
			this.loadVolumes(this.balanceInfo.pkAccount);
		}
	}

	@au.observable
	selectedAccount: app.AccountInfo;
	selectedAccountChanged(newValue: app.AccountInfo) {
		if (!this.bulkCustomer) {
			this.router.navigateToRoute(app.Route.account, { pkAccount: newValue.pkAccount });
		}
	}

	@au.observable
	volumesDateFrom: Date;
	suppressVolumesDateFromChanged: boolean;
	volumesDateFromChanged() {
		if (this.suppressVolumesDateFromChanged) {
			this.suppressVolumesDateFromChanged = false;
			return;
		}
		if (this.balanceInfo) {
			this.loadVolumes(this.balanceInfo.pkAccount);
		}
	}

	@au.observable
	volumesDateTo: Date;
	suppressVolumesDateToChanged: boolean;
	volumesDateToChanged(newValue) {
		if (this.suppressVolumesDateToChanged) {
			this.suppressVolumesDateToChanged = false;
			return;
		}
		if (this.balanceInfo) {
			this.loadVolumes(this.balanceInfo.pkAccount);
		}
	}

	volumesLoading: boolean;

	totalChartOptions: Chart.ChartOptions = {
		legend: {
			display: false
		},
		scales: {
			xAxes: [{ scaleLabel: { display: true, labelString: this.i18n.tr("Account.TotalChartXAxis") } }],
			yAxes: [{ stacked: true, ticks: { min: 0, suggestedMax: 1 } as any, scaleLabel: { display: true } }]
		},
		tooltips: {
			enabled: false
		}
	};

	dailyUsageTarget = this.settingsService.browserSettings.account.dailyUsageTarget;

	avgChartOptions: Chart.ChartOptions = {
		legend: {
			display: false
		},
		scales: {
			xAxes: [{ scaleLabel: { display: true, labelString: this.i18n.tr("Account.AverageChartXAxis") } }],
			yAxes: [{ stacked: true, ticks: { min: 0, suggestedMax: this.dailyUsageTarget ? this.dailyUsageTarget : 1 } as any, scaleLabel: { display: true, labelString: "L" } }]
		},
		tooltips: {
			enabled: false
		},
		annotation: this.dailyUsageTarget ? {
			annotations: [{
				drawTime: "afterDatasetsDraw",
				type: "line",
				mode: "horizontal",
				scaleID: "y-axis-0",
				value: this.dailyUsageTarget,
				borderColor: this.settingsService.browserSettings.colours.errorColour,
				borderWidth: 2,
				label: {
					backgroundColor: this.settingsService.browserSettings.colours.errorColour,
					content: this.dailyUsageTarget + " L",
					enabled: true,
					cornerRadius: 2
				},
			}]
		} : undefined
	};

	chartDataLabels: Chart.ChartDataSets["datalabels"] = {
		anchor: "end",
		align: "start",
		color: "white"
	};

	@au.observable
	peopleInHousehold: number;
	async peopleInHouseholdChanged() {
		if (this.selectedAccount) {
			await this.accountClient.updatePeopleInHousehold(new app.UpdatePeopleInHouseholdRequest({ pkAccount: this.selectedAccount.pkAccount, peopleInHousehold: this.peopleInHousehold }));
		}
		if (this.balanceInfo) {
			this.loadVolumes(this.balanceInfo.pkAccount);
		}
	}

	isResidentialCustomer: boolean;

	determineActivationStrategy() {
		return au.activationStrategy.replace; //replace the viewmodel with a new instance
	}

	async canActivate(params: any, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<boolean | au.RedirectToRoute> {
		if (!params || !params.pkAccount) {
			let accounts = await this.accountClient.index();
			if (!accounts.length) {
				this.alertService.error("There are no accounts available");
				return false;
			}
			return new au.RedirectToRoute(app.Route.account, { pkAccount: accounts[0].pkAccount });
		}
		return true;
	}

	async activate(params: any) {
		this.minVolumeDate = au.moment(await this.dateService.getServerDate()).subtract(this.settingsService.aquaRateSettings.volumeHistoryYears, "years").toDate();
		await this.load(parseInt(params.pkAccount));
	}

	async load(pkAccount: number) {
		this.isResidentialCustomer = this.authService.getTokenPayload().role === app.Roles.residentialCustomer;
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.accountClient.index().then(x => this.accounts = x),
				this.accountClient.getBalance(pkAccount).then(x => this.balanceInfo = x),
				this.accountClient.getLastPayments(pkAccount).then(x => this.payments = x),
				this.invoiceClient.getLast(pkAccount).then(x => this.invoices = x),
				this.dateService.getServerDate().then(x => this.serverDate = x),
				this.loadVolumes(pkAccount),
				this.isResidentialCustomer ? this.accountClient.getPeopleInHousehold(pkAccount).then(x => this.peopleInHousehold = x) : Promise.resolve(0)
			]);
			this.bulkCustomer = this.accounts.length >= this.settingsService.browserSettings.bulkCustomer.bulkCustomerThreshold;
			this.selectedAccount = this.accounts.find(x => x.pkAccount === pkAccount);
			this.lastInvoice = this.balanceInfo.lastInvoice && this.balanceInfo.lastInvoice.aquaweb_show_flag !== "N" ? this.balanceInfo.lastInvoice : null;
			this.isPayed = this.balanceInfo.balanceStatus === app.BalanceStatus.Payed;
			this.isToBePayed = this.balanceInfo.balanceStatus === app.BalanceStatus.ToBePayed;
			this.isOverdue = this.balanceInfo.balanceStatus === app.BalanceStatus.Overdue;
			this.concessionAllowed = this.settingsService.isWebRequestAllowed(WebRequestType.Concession) && !this.balanceInfo.concessionApplied;
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async loadVolumes(id: number) {
		// either both dates have to be null or set
		if (this.volumesDateFrom ? !this.volumesDateTo : this.volumesDateTo) {
			return;
		}
		try {
			this.volumesLoading = true;

			let coef = this.totalWaterUseUom === app.WaterUseUom.Litres ? 1000 : 1;
			this.totalChartOptions.scales.yAxes[0].scaleLabel.labelString = this.totalWaterUseUom === app.WaterUseUom.Litres ? "L" : "kL";
			let volumes = await this.accountClient.getVolumes(id, this.volumesDateFrom, this.volumesDateTo, this.isDatesUsageFilter ? null : this.billsUsageFilter);
			this.volumes = {
				labels: volumes.map(x => x.cycleDesc),
				datasets: [
					{
						backgroundColor: this.settingsService.browserSettings.colours.primaryColour,
						data: volumes.map(x => x.volume * coef),
						datalabels: this.chartDataLabels
					}
				]
			};
			if (this.isResidentialCustomer) {
				let peopleInHousehold = this.peopleInHousehold ? this.peopleInHousehold : 1;
				this.avgVolumes = JSON.parse(JSON.stringify(this.volumes));
				this.avgVolumes.datasets[0].data = volumes.map(x => Math.round(x.volume * 1000 / peopleInHousehold / au.moment(x.dateTo).diff(x.dateFrom, "days")));
				this.refreshAvgChart = true;
			}

			if (this.isDatesUsageFilter && !this.volumesDateFrom && volumes.length > 0) {
				this.suppressVolumesDateFromChanged = true;
				this.volumesDateFrom = au.moment(volumes[0].dateFrom).toDate();
				this.suppressVolumesDateToChanged = true;
				this.volumesDateTo = au.moment(volumes[volumes.length - 1].dateTo).toDate();
			}
		}
		finally {
			this.volumesLoading = false;
		}
	}

	refreshAvgChart: boolean;
	onChartTabSelected(e: CustomEvent) {
		if (this.avgChartVm && e.detail === "#avg-tab" && this.refreshAvgChart) {
			this.refreshAvgChart = false;
			this.taskQueue.queueTask(() => this.avgChartVm.refreshChart());
		}
	}

	attached() {
		// copy the height of the second chart to prevent scroll position jumping
		// it happens because browser does not know the real height of the chart when it is hidden
		// and scrolls down to the bottom of the page it assumes
		if (this.avgChart) {
			this.taskQueue.queueTask(() => this.avgChart.style.height = `${this.totalChart.clientHeight}px`);
		}
	}

	async getPdf(ih: app.InvoiceHeader) {
		this.alertService.usingProgress(async () => {
			await this.invoiceService.openPdf(ih.pk);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async requestPaymentExtension(): Promise<any> {
		await this.paymentService.requestPaymentExtension(this.balanceInfo);
	}

	async showReceipt(p: app.AccountPayment): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.payment = await this.paymentClient.get(p.paymentId);
			this.taskQueue.queueMicroTask(() => this.modal.open());
		}, e => this.alertService.error("Could not retrieve payment receipt"));
	}

	showPendingDDWarning() {
		this.alertService.alert(this.i18n.tr("Account.DirectDebitPendingWarning"), "schedule", "orange");
	}

	savePayments() {
		this.payments.forEach(x => {
			x.fullAccountNo = this.selectedAccount.fullAccountNo;
			x.fullAddress = this.selectedAccount.fullAddress;
		});
		this.paymentService.savePayments(this.payments);
	}

	getAccounts(p: au.ILookupOptionsFunctionParameter<app.AccountInfo>): Promise<app.AccountInfo[]> {
		if (p.value) {
			return Promise.resolve([this.accounts.find(x => x.pkAccount === p.value.pkAccount)]);
		}
		else {
			let filter = p.filter ? p.filter.toUpperCase() : null;
			return Promise.resolve(this.accounts.filter(x => !filter || x.fullAddress.toUpperCase().includes(filter) || x.fullAccountNo.includes(filter)));
		}
	}

	getAccountName(a: app.AccountInfo) {
		return `${a.fullAddress}, ${a.fullAccountNo}`;
	}

	goToAccount() {
		this.router.navigateToRoute(app.Route.account, { pkAccount: this.selectedAccount.pkAccount });
	}
}