import * as app from "app";

export interface IPaymentStatus {
	code: app.PaymentStatus;
	description: string;
}

export const PaymentStatuses: IPaymentStatus[] = [
	{ code: app.PaymentStatus.Approved, description: "Approved" },
	{ code: app.PaymentStatus.ConditionallyApproved, description: "Approved*" },
	{ code: app.PaymentStatus.Failed, description: "Failed" },
	{ code: app.PaymentStatus.Indeterminate, description: "Indeterminate" },
	{ code: app.PaymentStatus.NotSent, description: "Not sent" },
	{ code: app.PaymentStatus.RolledBack, description: "Rolled back" },
	{ code: app.PaymentStatus.Finalised, description: "Finalised" }
];