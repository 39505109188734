import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

declare module "../../services/api" {
	// tslint:disable:interface-name
	interface AccountPayment {
		fullAccountNo?: string;
		fullAddress?: string;
	}
	// tslint:enable:interface-name
}

@au.autoinject
export class AccountPayments extends app.PaginatedBase {
	constructor(private accountClient: app.AccountClient, alertService: at.AlertService, private dataStorageService: app.DataStorageService, private taskQueue: au.TaskQueue,
		private paymentClient: app.PaymentClient, private i18n: au.I18N, private paymentService: app.PaymentService, private settingsService: app.SettingsService) {
		super(alertService);
	}

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.accountPaymentsFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.accountPaymentsFilterLines = value;
	}

	accounts: app.AccountInfo[];

	@au.observable
	selectedAccount: app.AccountInfo;
	selectedAccountChanged() {
		if (this.isActivated) {
			this.search();
		}
	}

	payments: app.AccountPayment[];
	payment: app.Payment;
	modal: au.MdModal;
	dateFormat = this.settingsService.browserSettings.dateFormat;
	allowAllInSearch = this.settingsService.browserSettings.accountPayments.allowAllInSearch;

	async activate(params: IHaveId): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.accounts = await this.accountClient.index();
			this.allowAllInSearch = this.allowAllInSearch && (this.accounts.length > 1) && (this.accounts.length < 10);
			if (params && params.id) {
				this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
			}
			else {
				this.selectedAccount = this.allowAllInSearch ? null : this.accounts[0];
			}
			this.taskQueue.queueTask(() => this.search());
			this.isActivated = true;
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfAccountPayment> {
		let fi = new app.FilterItem();
		fi.name = "Account";
		fi.operator = app.FilterOperator.Is;
		fi.value = this.selectedAccount ? this.selectedAccount.pkAccount : null;
		request.filterItems.push(fi);
		let response = await this.accountClient.getPayments(request);
		this.payments = response.items;
		this.payments.forEach(x => {
			let a = this.accounts.find(y => y.pkAccount === x.pkAccount);
			x.fullAccountNo = a.fullAccountNo;
			x.fullAddress = a.fullAddress;
		});
		return response;
	}

	async showReceipt(p: app.AccountPayment): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.payment = await this.paymentClient.get(p.paymentId);
			this.taskQueue.queueMicroTask(() => this.modal.open());
		}, e => this.alertService.error("Could not retrieve payment receipt"));
	}

	getAccountDisplayName(ai: app.AccountInfo) {
		return `${ai.fullAccountNo} - ${ai.fullAddress}`;
	}

	showPendingDDWarning() {
		this.alertService.alert(this.i18n.tr("Account.DirectDebitPendingWarning"), "schedule", "orange");
	}

	savePayments() {
		this.paymentService.savePayments(this.payments);
	}
}