import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

declare module "../../services/api" {
	// tslint:disable:interface-name
	interface InvoiceHeader {
		fullAccountNo?: string;
		fullAddress?: string;
	}
	// tslint:enable:interface-name
}

@au.autoinject
export class Invoices extends app.PaginatedBase {
	constructor(private accountClient: app.AccountClient, alertService: at.AlertService, private dataStorageService: app.DataStorageService,
		private invoiceClient: app.InvoiceClient, private invoiceService: app.InvoiceService, private taskQueue: au.TaskQueue, private settingsService: app.SettingsService) {
		super(alertService);
	}

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.invoicesFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.invoicesFilterLines = value;
	}

	accounts: app.AccountInfo[];

	@au.observable
	selectedAccount: app.AccountInfo;
	selectedAccountChanged() {
		if (this.isActivated) {
			this.search();
		}
	}

	invoices: app.InvoiceHeader[];
	showInvoiceDates = this.settingsService.browserSettings.account.showInvoiceDates;
	dateFormat = this.settingsService.browserSettings.dateFormat;
	colourInvoices = this.settingsService.browserSettings.account.colourInvoices;

	async activate(params: IHaveId): Promise<any> {
		await this.alertService.usingProgress(async () => {
			this.accounts = await this.accountClient.index();
			if (params && params.id) {
				this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
			}
			else {
				this.selectedAccount = this.accounts[0];
			}
			this.taskQueue.queueTask(() => this.search());
			this.isActivated = true;
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfInvoiceHeader> {
		let fi = new app.FilterItem();
		fi.name = "Account";
		fi.operator = app.FilterOperator.Is;
		fi.value = this.selectedAccount ? this.selectedAccount.pkAccount : null;
		request.filterItems.push(fi);
		let response = await this.invoiceClient.getInvoices(request);
		this.invoices = response.items;
		this.invoices.forEach(x => {
			let a = this.accounts.find(y => y.pkAccount === x.fkAccount);
			x.fullAccountNo = a.fullAccountNo;
			x.fullAddress = a.fullAddress;
		});
		return response;
	}

	async getPdf(ih: app.InvoiceHeader) {
		this.alertService.usingProgress(async () => {
			await this.invoiceService.openPdf(ih.pk);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	getAccountDisplayName(ai: app.AccountInfo) {
		return `${ai.fullAccountNo} - ${ai.fullAddress}`;
	}
}