import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class EmailInvoices {
	constructor(private userClient: app.UserClient, private alertService: at.AlertService, validationControllerFactory: at.ValidationControllerFactory,
		private router: au.Router, private settingsService: app.SettingsService, private i18n: au.I18N, private accountClient: app.AccountClient, private authService: app.IAuthService) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<EmailInvoices, app.AccountInfo>(x => x.selectedAccount).required()
			.then().satisfies(x => !x.isClosed && x.isConnected).withMessage("must not be closed")
			.then().satisfies(async x => !await this.userClient.isTherePendingEmailInvoicesRequest(x.pkAccount)).withMessage(this.i18n.tr("Error.WebRequestAlreadySumbmitted"))
			.then().satisfies(async x => !await this.userClient.isInvoicePrintTypeEmail(x.pkAccount)).withMessage(this.i18n.tr("EmailInvoices.AlreadySetup"))
			.on(EmailInvoices);
	}

	validationController: au.ValidationController;
	accounts: app.AccountInfo[];
	selectedAccount: app.AccountInfo;
	token: at.ITokenPayload;

	async canActivate(params: IHaveId) {
		return await this.alertService.usingProgress(async () => {
			this.accounts = (await this.accountClient.index()).filter(x => !x.isClosed && x.isConnected && x.fkAccountStatus !== app.AccountStatus.WaitingForClosure);
			if (!this.accounts.length) {
				await this.alertService.alert(this.i18n.tr("EmailInvoices.NoEligibleAccounts"), "warning", "orange");
				return false;
			}
			if (params && params.id) {
				this.selectedAccount = this.accounts.find(x => x.pkAccount === parseInt(params.id));
				if (!this.selectedAccount) {
					await this.alertService.alert("This account is not eligible", "warning", "orange");
					return false;
				}
			}

			if (this.accounts.length === 1 && !this.selectedAccount) {
				if (await this.userClient.isTherePendingEmailInvoicesRequest(this.accounts[0].pkAccount)) {
					await this.alertService.alert(this.i18n.tr("EmailInvoices.PendingApplication"), "warning", "orange");
					return false;
				}
				if (await this.userClient.isInvoicePrintTypeEmail(this.accounts[0].pkAccount)) {
					await this.alertService.alert(this.i18n.tr("EmailInvoices.AlreadySetupDialog"), "warning", "orange");
					return false;
				}
				this.selectedAccount = this.accounts[0];
			}
			this.token = this.authService.getTokenPayload();
		}, async e => { await this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e); return false; });
	}

	async submit(): Promise<any> {
		if (!(await this.validationController.validate()).valid) {
			return;
		}
		if (!await this.alertService.confirm(this.i18n.tr("EmailInvoices.Confirmation"), "warning", "orange")) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			await this.userClient.emailInvoices(this.selectedAccount.pkAccount);
			await this.alertService.alert(this.i18n.tr("EmailInvoices.SubmitSuccess"));
			if (this.accounts.length === 1) {
				this.router.navigateToRoute(app.Route.home);
			}
		}, e => this.alertService.error(this.i18n.tr("EmailInvoices.SubmitError")));
	}

}