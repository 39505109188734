import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { getTypes, IChartType } from './i-chart-type';
import { setTimeout } from 'timers';

@au.autoinject
export class VolumesPeriod {
	constructor(private meterClient: app.MeterClient, private alertService: at.AlertService, private settingsService: app.SettingsService,
		private accountClient: app.AccountClient, private authService: app.IAuthService, private validationControllerFactory: at.ValidationControllerFactory,
		private dateService: at.DateService) {
		this.validationController = this.validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<VolumesPeriod, Date>(x => x.from).required().satisfies(x => au.moment(x).isBefore(this.to)).withMessage("must be before 'From'")
			.ensure(x => x.to).required().satisfies(x => au.moment(x).isAfter(this.from)).withMessage("must be after 'To'")
			.on(this);
	}

	validationController: au.ValidationController;
	meters: app.MeterInfo[] = [];
	minVolumeDate: Date;

	@au.observable
	meter: app.MeterInfo;
	async meterChanged() {
		this.peopleInHousehold = await this.accountClient.getPeopleInHousehold(this.meter.account.pkAccount);
		this.scheduleShow();
	}

	@au.observable
	from: Date;
	async fromChanged() {
		this.validationController.validate({ object: this, propertyName: "to" });
		this.scheduleShow();
	}

	@au.observable
	to: Date;
	async toChanged() {
		this.validationController.validate({ object: this, propertyName: "from" });
		this.scheduleShow();
	}

	types: IChartType[];
	chart: any;
	isResidentialCustomer: boolean;

	@au.observable
	type: IChartType;
	async typeChanged() {
		this.from = this.type.getFrom();
		this.to = new Date();
		this.scheduleShow();
	}

	dailyUsageTarget = this.settingsService.browserSettings.account.dailyUsageTarget;

	@au.observable
	peopleInHousehold: number;
	async peopleInHouseholdChanged() {
		if (this.meter) {
			await this.accountClient.updatePeopleInHousehold(new app.UpdatePeopleInHouseholdRequest({ pkAccount: this.meter.account.pkAccount, peopleInHousehold: this.peopleInHousehold }));
		}
		this.scheduleShow();
	}

	volumes: Chart.ChartData = { labels: [], datasets: [] };
	chartOptions: Chart.ChartOptions = {
		legend: {
			display: false
		},
		scales: {
			yAxes: [{ stacked: true, ticks: { min: 0, suggestedMax: 1 } as any, scaleLabel: { display: true } }]
		},
		tooltips: {
			enabled: true
		}
	};
	chartDataLabels: Chart.ChartDataSets["datalabels"] = {
		anchor: "end",
		align: "end",
		color: "black",
		display: false
	};

	async activate(): Promise<any> {
		this.isResidentialCustomer = this.authService.getTokenPayload().role === app.Roles.residentialCustomer;
		this.types = getTypes(this.isResidentialCustomer, this.settingsService.browserSettings.meterVolume.showDaily);
		this.minVolumeDate = au.moment(await this.dateService.getServerDate()).subtract(this.settingsService.aquaRateSettings.volumeHistoryYears, "years").toDate();
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.meterClient.getConnected().then(x => this.meters = x)
			]);
			this.type = this.types[0];
			if (this.meters.length === 1) {
				this.meter = this.meters[0];
			}
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	timeout: NodeJS.Timeout;
	scheduleShow() {
		if (this.timeout) {
			return;
		}
		this.timeout = setTimeout(() => {
			this.show();
			this.timeout = null;
		}, 1000);
	}

	async show() {
		if (!this.meter || !(await this.validationController.validate()).valid) {
			return;
		}
		await this.alertService.usingProgress(async () => {
			let volumes = await this.meterClient.getPeriodVolumes(this.meter.meter.pk, this.from, this.to, this.type.volumePeriodTypeCode);
			this.chartOptions.scales.yAxes[0].scaleLabel.labelString = this.type.units;
			this.chartOptions.annotation = (this.type.showTarget && this.dailyUsageTarget) ? {
				annotations: [{
					drawTime: "afterDatasetsDraw",
					type: "line",
					mode: "horizontal",
					scaleID: "y-axis-0",
					value: this.dailyUsageTarget,
					borderColor: this.settingsService.browserSettings.colours.errorColour,
					borderWidth: 2,
					label: {
						backgroundColor: this.settingsService.browserSettings.colours.errorColour,
						content: this.dailyUsageTarget + " L",
						enabled: true,
						cornerRadius: 2
					},
				}]
			} : undefined;
			if (this.chart) {
				this.chart.detached();
				this.chart.attached();
			}
			let people = this.peopleInHousehold ? this.peopleInHousehold : 1;
			this.volumes = {
				labels: volumes.map(x => au.moment(x.pkDate).format(this.type.labelFormat)),
				datasets: [
					{
						backgroundColor: this.settingsService.browserSettings.colours.primaryColour,
						data: volumes.map(x => this.type.convertVolume(x.volume, x.pkDate, people)),
						datalabels: this.chartDataLabels
					}
				]
			};
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

}