import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class RoleCheckStep implements au.PipelineStep {
	constructor(private authService: app.IAuthService, private settingsService: app.SettingsService) { }

	async run(navigationInstruction: au.NavigationInstruction, next: au.Next): Promise<any> {
		let token = this.authService.getTokenPayload();
		if (navigationInstruction.getAllInstructions().some(i => !isRouteAllowed(i.config, token, this.settingsService))) {
			return next.cancel(new au.RedirectToRoute(token ? app.Route.forbidden : app.Route.expired));
		}
		return next();
	}
}

export function isRouteAllowed(config: at.IAppRouteConfig, token: at.ITokenPayload, settingsService: app.SettingsService): boolean {
	if (config.webRequestType && !settingsService.isWebRequestAllowed(config.webRequestType)) {
		return false;
	}
	if (token) {
		switch (config.auth) {
			case undefined:
			case at.AuthStatus.All:
				return true;
			case at.AuthStatus.Authenticated: {
				if (config.permission !== undefined) {
					let permission = app.PermissionCode[config.permission];
					return token.permission instanceof String ? token.permission === permission : token.permission.includes(permission);
				}
				else if (config.role !== undefined) {
					let tokenRoles = typeof (token.role) === "string" ? [token.role] : token.role;
					return !!tokenRoles.find(x => config.role.includes(x));
				}
				else {
					return true;
				}
			}
			default: return false;
		}
	}
	else {
		return config.auth === undefined || config.auth === at.AuthStatus.All || config.auth === at.AuthStatus.NonAuthenticated;
	}
}