import * as au from "aurelia";
import * as app from "app";

export class PostCompleteStep {
	run(instruction: au.NavigationInstruction, next: au.Next) {
		if (instruction.config.settings && !instruction.config.settings.noScrollToTop) {
			window.scrollTo(0, 0);
		}

		return next();
	}
}