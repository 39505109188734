export class Route {
	static login: string = "login";
	static loginB2C: string = "login-b2c";
	static logout: string = "logout";
	static register: string = "register";
	static home: string = "home";
	static customer: string = "customer";
	static bulkCustomer: string = "bulk-customer";
	static agent: string = "agent";
	static agencyManager: string = "agency-manager";
	static conveyancer: string = "conveyancer";
	static users: string = "users";
	static profile: string = "profile";
	static forbidden: string = "forbidden";
	static notfound: string = "notfound";
	static confirmEmail: string = "confirm-email";
	static changeEmail: string = "change-email";
	static resetPassword: string = "reset-password";
	static account: string = "account";
	static editUser: string = "edit-user";
	static expired: string = "expired";
	static farewell: string = "farewell";
	static changeAddress: string = "change-address";
	static changeName: string = "change-name";
	static meterReading: string = "meter-reading";
	static inviteUser: string = "invite-user";
	static organisations: string = "organisations";
	static editOrganisation: string = "edit-organisation";
	static newTenant: string = "new-tenant";
	static vacatingTenant: string = "vacating-tenant";
	static infoStatement: string = "info-statement";
	static payments: string = "payments";
	static accountPayment: string = "account-payment";
	static accountPayments: string = "account-payments";
	static emailInvoices: string = "email-invoices";
	static directDebit: string = "direct-debit";
	static role: string = "role";
	static roles: string = "roles";
	static editSettings: string = "edit-settings";
	static adminDashboard: string = "admin-dashboard";
	static volumesPeriod: string = "volumes-period";
	static invoices: string = "invoices";
	static loginB2CError: string = "login-b2c-error";
	static registerB2C: string = "register-b2c";
	static balanceCheck: string = "balance-check";
	static changeCustomerDetails: string = "change-customer-details";
	static concession: string = "concession";
	static paymentArrangement: string = "payment-arrangement";
}
