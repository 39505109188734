import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Login {
	constructor(private authService: app.IAuthService, private router: au.Router, private alertService: at.AlertService, private eventAggregator: au.EventAggregator,
		private emailClient: app.EmailClient, validationControllerFactory: at.ValidationControllerFactory, private i18n: au.I18N, private msalService: app.MsalService) {
		this.logger = au.getLogger(this.constructor.name);
		this.validationController = validationControllerFactory.createForCurrentScope();
		au.ValidationRules
			.ensure<Login, string>(x => x.email).email().required()
			.ensure(x => x.password).required()
			.on(this);
	}

	email: string;
	isEmailValid: boolean;
	password: string;
	rememberMe: boolean;
	logger: au.Logger;
	validationController: au.ValidationController;
	emailConfirmationNeeded: boolean;
	changeEmailParams: app.IChangeEmailRequest;
	title: string = "Login";
	unconfirmedEmailModal: au.MdModal;

	async canActivate(params: any, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<boolean | au.RedirectToRoute> {
		if (this.msalService.isEnabled()) {
			return new au.RedirectToRoute(app.Route.loginB2C);
		}
		if (await this.authService.isAuthenticatedAsync()) {
			this.eventAggregator.publish("auth:login"); // let nav bar know that we're logged in
			return new au.RedirectToRoute(app.Route.home);
		}
		else {
			return true;
		}
	}

	async activate(params: any) {
		if (params && params.emailConfirmationNeeded === "true") {
			this.emailConfirmationNeeded = true;
		}
		this.changeEmailParams = params.changeEmailParams;
		if (this.changeEmailParams) {
			this.title = "Please login to change your email"
		}
	}

	attached() {
		if (this.emailConfirmationNeeded) {
			this.alertService.alert(this.i18n.tr("Login.ConfirmEmailWarning"), "warning", "orange");
		}
	}

	loggingIn: boolean;
	async login() {
		if (this.loggingIn) {
			return;
		}
		this.loggingIn = true;
		let errors = await this.validationController.validate();
		if (!errors.valid)
			return;

		try {
			this.alertService.showProgress();
			await this.authService.login({ User: this.email, Password: this.password, RememberMe: this.rememberMe });
			if (this.changeEmailParams) {
				this.router.navigateToRoute(app.Route.changeEmail, this.changeEmailParams);
			}
		}
		catch (e) {
			var ex = e as at.Exception;
			if (ex instanceof at.UnconfirmedEmailException) {
				this.unconfirmedEmailModal.open();
			}
			else if (ex instanceof at.InvalidCredentialsException) {
				this.alertService.error(this.i18n.tr("Login.InvalidCredentials"));
			}
			else if (ex instanceof app.LockedOutException) {
				this.alertService.error(this.i18n.tr("Login.LockedOut"));
			}
			else if (ex instanceof app.InactiveUserException) {
				this.alertService.error(this.i18n.tr("Login.Inactive"));
			}
			else {
				this.alertService.error("Error occurred while logging you in. Please try again later.");
			}
		}
		finally {
			this.alertService.hideProgress();
			this.loggingIn = false;
		}
	}

	async sendConfirmation() {
		try {
			this.alertService.showProgress();
			await this.emailClient.sendConfirmationEmail(this.email);
			this.alertService.alert(this.i18n.tr("Login.LinkHasBeenSent"));
		}
		catch (e) {
			this.alertService.error("Error sending the link");
		}
		finally {
			this.alertService.hideProgress();
		}
	}

	async sendResetPassword() {
		let errors = await this.validationController.validate({ object: this, propertyName: "email" });
		if (!errors.valid) {
			this.alertService.error("Please enter valid email");
			return;
		}

		try {
			this.alertService.showProgress();
			await this.emailClient.sendResetPasswordEmail(this.email);
			this.alertService.alert(this.i18n.tr("Login.LinkHasBeenSent"));
		}
		catch (e) {
			this.alertService.error("Error sending the link");
		}
		finally {
			this.alertService.hideProgress();
		}
	}

	capsLockOn: boolean;
	passwordKeyUp(event: KeyboardEvent) {
		this.capsLockOn = event.getModifierState("CapsLock");
	}

}