import * as app from "app";
import * as au from "aurelia";

@au.autoinject
export class AccountBalanceInfoToDueTextValueConverter {
	constructor(private i18n: au.I18N) { }

	toView(value: app.AccountBalanceInfo, date: Date): string {
		if (value.lastInvoice.directDebitFlag === "Y") {
			if (au.moment(date).isAfter(value.lastInvoice.dateDue)) {
				return `${au.numeral(value.lastInvoice.amtTotal).format("$0,0.00")} via direct debit on ${value.lastInvoice.dateDue.toLocaleDateString("en-AU")}`;
			}
			else {
				return `${au.numeral(value.lastInvoice.amtTotal).format("$0,0.00")} will be direct debited from your bank account on ${value.lastInvoice.dateDue.toLocaleDateString("en-AU")}`;
			}
		}
		else if (value.payArrangement && value.payArrangement.fkPayFrequency !== "I" /* In Full */) {
			return `${au.numeral(value.lastInvoice.amtTotal).format("$0,0.00")} issued on ${value.lastInvoice.dateIssued.toLocaleDateString("en-AU")}`;
		}
		else {
			if (value.lastInvoice.amtTotal > 0) {
				return `${au.numeral(value.lastInvoice.amtTotal).format("$0,0.00")} due on ${value.lastInvoice.dateDue.toLocaleDateString("en-AU")}`;
			}
			else {
				return this.i18n.tr("Account.InvoiceInCreditText", { replace: { amount: -value.lastInvoice.amtTotal, date: value.lastInvoice.dateIssued } });
			}
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}