import * as au from "aurelia";

@au.autoinject
export class EditPageButtons {
	constructor(private element: Element) {
	}

	@au.bindable
	title: string;

	@au.bindable
	canSave: boolean = true;

	@au.bindable
	canRevert: boolean = true;

	@au.bindable
	canDelete: boolean = false;

	@au.bindable
	isChanged: boolean = false;

	save() {
		au.fireEvent(this.element, "save");
	}

	revert() {
		au.fireEvent(this.element, "revert");
	}

	delete() {
		au.fireEvent(this.element, "delete");
	}
}