import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Roles {
	constructor(private roleClient: app.RoleClient, private router: au.Router, private alertService: at.AlertService) { }

	roles: app.Role[];

	async activate() {
		await this.alertService.usingProgress(async () => {
			this.roles = await this.roleClient.index();
		}, e => this.alertService.error("Error fetching roles"));
	}

	edit(role: app.Role) {
		this.router.navigateToRoute(app.Route.role, { id: role.id });
	}
}
