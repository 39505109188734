import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class Organisations extends app.PaginatedBase {
	constructor(private organisationClient: app.OrganisationClient, alertService: at.AlertService, private router: au.Router, private dataStorageService: app.DataStorageService) {
		super(alertService);
	}

	organisations: app.Organisation2[];

	get savedFilterLines(): at.IFilterLine[] {
		return this.dataStorageService.data.organisationsFilterLines;
	}
	set savedFilterLines(value: at.IFilterLine[]) {
		this.dataStorageService.data.organisationsFilterLines = value;
	}

	async activate() {
		await this.alertService.usingProgress(async () => {
			if (this.savedFilterLines && this.savedFilterLines.length) {
				this.filterLines = this.savedFilterLines;
			}
			await this.search();
			this.isActivated = true;
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	async fetch(request: app.SearchRequest): Promise<app.SearchResponseOfOrganisation> {
		let response = await this.organisationClient.index(request);
		this.organisations = response.items;
		return response;
	}

	edit(organisation: app.Organisation2) {
		this.router.navigateToRoute(app.Route.editOrganisation, <IHaveId>{ id: organisation.id.toString() });
	}
}