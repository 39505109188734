import * as au from "aurelia";
import * as app from "app";

export interface IChartType {
	name: string;
	volumePeriodTypeCode: string;
	units: string;
	convertVolume: (v: number, d: Date, people: number) => number;
	showTarget: boolean;
	getFrom: () => Date;
	labelFormat: string;
}

export function getTypes(isResidentialCustomer: boolean, showDaily: boolean): IChartType[] {
	let res = [
		{
			name: "Monthly", volumePeriodTypeCode: "M", units: "kL", convertVolume: v => v,
			showTarget: false, getFrom: () => au.moment().add(-13, "month").toDate(), labelFormat: "MM/YYYY"
		},
		{
			name: "Monthly Average", volumePeriodTypeCode: "M", units: "L", convertVolume: (v, d, people) => v / au.moment(d).daysInMonth() * 1000 / people,
			showTarget: isResidentialCustomer, getFrom: () => au.moment().add(-13, "month").toDate(), labelFormat: "MM/YYYY"
		}
	];
	if (showDaily) {
		res.push({
			name: "Daily", volumePeriodTypeCode: "D", units: "L", convertVolume: (v, d, people) => v * 1000 / people,
			showTarget: isResidentialCustomer, getFrom: () => au.moment().add(-1, "month").toDate(), labelFormat: "L"
		});
	}
	return res;
}
