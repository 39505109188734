import * as au from "aurelia";
import * as app from "app";

@au.autoinject
export class PaymentStatus {
	constructor(private paymentService: app.PaymentService) {
		this.elementId = PaymentStatus.id++;
	}

	static id: number = 0;
	elementId: number;

	@au.bindable
	payment: app.Payment;
	paymentChanged() {
		if (this.payment) {
			this.paymentStatus = this.paymentService.getStatusSummary(this.payment);
		}
	}

	paymentStatus: IPaymentStatusSummary;
}