import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class LoginB2C {
	constructor(private eventAggregator: au.EventAggregator, private msalService: app.MsalService, private alertService: at.AlertService,
		private router: au.Router) {
		this.logger = au.getLogger("LoginB2C");
	}

	logger: au.Logger;
	title: string = "Login";
	params: any;

	async canActivate(params: any, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<boolean | au.RedirectToRoute> {
		this.params = params;
		if (await this.msalService.isAuthenticatedAsync()) {
			this.eventAggregator.publish("auth:login"); // let nav bar know that we're logged in
			return new au.RedirectToRoute(app.Route.home);
		}
		else {
			if (params.login) {
				this.login();
			}
			return true;
		}
	}

	async login() {
		this.alertService.showProgress();
		this.msalService.loginRedirect();
	}
}