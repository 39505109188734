import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import { InfoStatementPorg } from "custom-elements/info-statement-porg/info-statement-porg";
import { InfoStatementTitle } from "custom-elements/info-statement-title/info-statement-title";
import { CreditCard } from "custom-elements/credit-card/credit-card";

interface IFileRowEx extends at.IFileRow {
	pk?: number;
	rowid?: string;
}

@au.autoinject
export class InfoStatement {
	constructor(validationControllerFactory: at.ValidationControllerFactory, private alertService: at.AlertService, private webRequestClient: app.WebRequestClient,
		private router: au.Router, private propertyClient: app.PropertyClient, private settingsService: app.SettingsService, private paymentClient: app.PaymentClient,
		private paymentService: app.PaymentService, private taskQueue: au.TaskQueue, private i18n: au.I18N) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.requestRules = au.ValidationRules
			.ensure<app.SubmitInfoStatementRequest, number>(x => x.fkProperty).required().when(x => this.addressLookup)
			.rules;
		this.rules = au.ValidationRules
			.ensure<app.WebRequestInfoStmt, string>(x => x.fk_unit_type).required().when(x => !this.addressLookup && !!x.unit_no)
			.ensure(x => x.unit_no).maxLength(12)
			.ensure(x => x.street_no).required().when(x => !this.addressLookup).maxLength(12)
			.ensure(x => x.street).required().when(x => !this.addressLookup).maxLength(80)
			.ensure(x => x.fk_street_suffix).required().when(x => !this.addressLookup)
			.ensure(x => x.fk_suburb).required().when(x => !this.addressLookup)
			.ensure(x => x.solicitor_ref).required()
			.ensure(x => x.date_settling).required()
			.rules;
		this.logger = au.getLogger("InfoStatement");
	}

	logger: au.Logger;
	detailsTab: string = "detailsTab";
	titlesTab: string = "titlesTab";
	ownersTab: string = "ownersTab";
	purchasersTab: string = "purchasersTab";
	paymentTab: string = "paymentTab";
	validationController: au.ValidationController;
	request: app.SubmitInfoStatementRequest;
	rules: au.Rule<app.WebRequestInfoStmt, any>[][];
	requestRules: au.Rule<app.SubmitInfoStatementRequest, any>[][];
	isReadonly: boolean;
	unitTypes: app.UnitType[];
	feeTypes: app.WebRequestFeeType[];
	porgTypes: app.PorgType[];
	planTypes: app.PlanType[];
	parishes: app.Parish[];
	streetSuffixes: app.StreetSuffix[];
	suburbs: app.Suburb[];
	addressLookup: boolean = true;
	maxFileSize = this.settingsService.browserSettings.maxFileSize;
	files: IFileRowEx[] = [];
	owners: app.WebRequestInfoStmtPorg[];
	ownerVMs: InfoStatementPorg[] = [];
	purchasers: app.WebRequestInfoStmtPorg[];
	purchaserVMs: InfoStatementPorg[] = [];
	titleVMs: InfoStatementTitle[] = [];
	isPaymentRequired: boolean;
	lastPayment: app.Payment;
	canPay: boolean;
	paymentStatus: IPaymentStatusSummary;
	surcharges: app.Surcharge[];

	tabs: au.MdTabs;
	tabsElement: Element;
	settings = this.settingsService.browserSettings;
	allowedExtensions = this.settingsService.browserSettings.allowedFileExtensions;

	tokenData: PayWay.ITokenData;
	creditCard: CreditCard;

	determineActivationStrategy() {
		return au.activationStrategy.invokeLifecycle;
	}

	async activate(params: IHaveGuid, routeConfig: au.RouteConfig, navigationInstruction: au.NavigationInstruction): Promise<any> {
		await this.alertService.usingProgress(async () => {
			await Promise.all([
				this.propertyClient.getUnitTypes().then(x => this.unitTypes = x),
				this.webRequestClient.getFeeTypes().then(x => this.feeTypes = x),
				this.webRequestClient.getPorgTypes().then(x => this.porgTypes = x),
				this.webRequestClient.getPlanTypes().then(x => this.planTypes = x),
				this.webRequestClient.getParishes().then(x => this.parishes = x),
				this.propertyClient.getStreetSuffixes().then(x => this.streetSuffixes = x),
				this.propertyClient.getSuburbs().then(x => this.suburbs = x),
				this.webRequestClient.isInfoStatementPaymentRequired().then(x => this.isPaymentRequired = x),
				this.paymentClient.getSurcharges().then(x => this.surcharges = x)
			]);
			if (params.guid) {
				let wr: app.WebRequest;
				await Promise.all([
					await this.webRequestClient.getInfoStatementRequest(params.guid).then(x => wr = x),
					await this.paymentClient.getLastInfoStatementPayment(params.guid).then(x => this.setLastPayment(x))
				]);
				this.request = new app.SubmitInfoStatementRequest(wr);
				this.files = this.request.attachments.map(x => ({ fileName: x.fileName, pk: x.pk, rowid: x.rowid }));
				this.isReadonly = wr.fkRequestStatus !== WebRequestStatus.Incomplete; // Only incomplete can be edited
				this.addressLookup = !!this.request.fkProperty;
			}
			else {
				this.request = app.SubmitInfoStatementRequest.fromJS({
					InfoStatement: {
						Porgs: [{ Owner_flag: true, isSelected: true }, { isSelected: true }],
						Titles: [{ isSelected: true }]
					}
				});
				this.files = [];
				this.isReadonly = false;
				this.addressLookup = true;
			}
			this.canPay = this.isPaymentRequired && this.paymentService.canMakeAnotherPayment(this.lastPayment);
			this.owners = this.request.infoStatement.porgs.filter(x => x.owner_flag);
			this.purchasers = this.request.infoStatement.porgs.filter(x => !x.owner_flag);
			if (!this.isReadonly) {
				this.validationController.addObject(this.request.infoStatement, this.rules);
				this.validationController.addObject(this.request, this.requestRules);
			}
			this.taskQueue.queueTask(() => this.validationController.reset());
		}, e => this.alertService.error(e.message));
	}

	getProperties(p: au.ILookupOptionsFunctionParameter<number>): Promise<app.PropertyInfo[]> {
		if (p.filter !== undefined) {
			return this.propertyClient.find(p.filter);
		}
		else {
			return p.value ? this.propertyClient.getPropertyInfo(p.value).then(x => [x]) : Promise.resolve([]);
		}
	}

	getUnitTypes(p: au.ILookupOptionsFunctionParameter<string>): Promise<app.UnitType[]> {
		if (p.filter !== undefined) {
			return Promise.resolve(this.unitTypes.filter(x => x.description.toUpperCase().includes(p.filter.toUpperCase())));
		}
		else {
			return Promise.resolve(p.value ? this.unitTypes.filter(x => x.code === p.value) : []);
		}
	}

	getStreetSuffixes(p: au.ILookupOptionsFunctionParameter<string>): Promise<app.StreetSuffix[]> {
		if (p.filter !== undefined) {
			return Promise.resolve(this.streetSuffixes.filter(x => x.description.toUpperCase().includes(p.filter.toUpperCase())));
		}
		else {
			return Promise.resolve(p.value ? this.streetSuffixes.filter(x => x.code === p.value) : []);
		}
	}

	getSuburbs(p: au.ILookupOptionsFunctionParameter<string>): Promise<app.Suburb[]> {
		if (p.filter !== undefined) {
			return Promise.resolve(this.suburbs.filter(x => (x.description.toUpperCase() + x.postcode.toString()).includes(p.filter.toUpperCase())));
		}
		else {
			return Promise.resolve(p.value ? this.suburbs.filter(x => x.code === p.value) : []);
		}
	}

	suburbToString(suburb: app.Suburb) {
		return `${suburb.description}, ${suburb.postcode}`;
	}

	toggleAddressLookup() {
		this.addressLookup = !this.addressLookup;
		if (!this.addressLookup) {
			this.request.fkProperty = undefined;
		}
		else {
			this.request.infoStatement.fk_unit_type = this.request.infoStatement.unit_no = this.request.infoStatement.street_no = this.request.infoStatement.street
				= this.request.infoStatement.fk_street_suffix = this.request.infoStatement.fk_suburb = this.request.infoStatement.post_code = undefined;
		}
	}

	async validateDetails(): Promise<boolean> {
		let requestResult = (await this.validationController.validate({ object: this.request, rules: this.requestRules })).valid;
		let result = (await this.validationController.validate({ object: this.request.infoStatement, rules: this.rules })).valid;
		if (!result || !requestResult) {
			this.alertService.error("Please correct the errors");
			return false;
		}
		if (this.files.length === 0) {
			this.alertService.error("Please add a property title");
			return false;
		}
		if (this.files.length > this.settingsService.browserSettings.maxFilesPerRequest) {
			this.alertService.error(this.i18n.tr("MaxFileCountError"));
			return;
		}
		if (this.files.find(x => x.isTooBig)) {
			this.alertService.error(this.i18n.tr("MaxFileSizeError"));
			return false;
		}
		if (this.files.find(x => !this.settingsService.isFileAllowed(x.fileName))) {
			this.alertService.error("Not allowed file selected");
			return false;
		}
		return true;
	}


	async detailsNext() {
		if (await this.validateDetails()) {
			this.selectTab(this.titlesTab);
		}
	}

	titlesBack() {
		this.selectTab(this.detailsTab);
	}

	async validateTitles(): Promise<boolean> {
		if (this.request.infoStatement.titles.length === 0) {
			this.alertService.error("Please add at least one title");
			return false;
		}
		let results = await Promise.all(this.titleVMs.map(x => x.validationController.validate()));
		if (results.find(x => !x.valid)) {
			this.alertService.error("Please correct the errors");
			return false;
		}
		let emptyTitle = this.request.infoStatement.titles.find(x => !x.volume_no && !x.folio_no && !x.lot_no && !x.crown_allotment && !x.section);
		if (emptyTitle) {
			this.alertService.error("The title is empty");
			this.selectTitle(emptyTitle);
			return false;
		}
		return true;
	}

	async titlesNext() {
		if (await this.validateTitles()) {
			this.selectTab(this.ownersTab);
		}
	}

	ownersBack() {
		this.selectTab(this.titlesTab);
	}

	async validateOwners(): Promise<boolean> {
		if (this.owners.length === 0) {
			this.alertService.error("Please add at least one owner");
			return false;
		}
		let results = await Promise.all(this.ownerVMs.map(x => x.validationController.validate()));
		if (results.find(x => !x.valid)) {
			this.alertService.error("Please correct the errors");
			return false;
		}
		return true;
	}

	async ownersNext() {
		if (await this.validateOwners()) {
			this.selectTab(this.purchasersTab);
		}
	}

	purchasersBack() {
		this.selectTab(this.ownersTab);
	}

	async validatePurchasers(): Promise<boolean> {
		if (this.purchasers.length === 0) {
			this.alertService.error("Please add at least one purchaser");
			return false;
		}
		let results = await Promise.all(this.purchaserVMs.map(x => x.validationController.validate()));
		if (results.find(x => !x.valid)) {
			this.alertService.error("Please correct the errors");
			return false;
		}
		return true;
	}

	async purchasersNext() {
		if (this.isReadonly) {
			this.selectTab(this.paymentTab);
		}
		else {
			await this.save(true);
		}
	}

	paymentBack() {
		this.selectTab(this.purchasersTab);
	}

	async save(submit: boolean) {
		if (submit) {
			if (!await this.validateDetails()) {
				this.selectTab(this.detailsTab);
				return;
			}
			if (!await this.validateTitles()) {
				this.selectTab(this.titlesTab);
				return;
			}
			if (!await this.validateOwners()) {
				this.selectTab(this.ownersTab);
				return;
			}
			if (!await this.validatePurchasers()) {
				this.selectTab(this.purchasersTab);
				return;
			}

			if (!await this.alertService.confirm("You will not be able to alter the request after submission. Continue?")) {
				return;
			}
		}

		this.request.infoStatement.porgs = this.owners.concat(this.purchasers);
		try {
			this.alertService.showProgress();
			this.request.submit = submit;
			// resend existing attachment infos
			this.request.attachments = this.files.filter(x => !!x.pk).map(x => app.WebRequestAttachment.fromJS({ Pk: x.pk, FileName: x.fileName, Rowid: x.rowid }));
			let response = await this.webRequestClient.submitInfoStatement(JSON.stringify(this.request), this.files.filter(x => !x.pk).map(x => ({ data: x.file, fileName: x.fileName })));
			this.request.guid = response.guid;
			this.request.rowid = response.rowid;

			if (submit) {
				if (!this.isPaymentRequired) {
					await this.submitted();
					return;
				}
				else {
					this.selectTab(this.paymentTab);
					this.isReadonly = true;
				}
			}

			this.refresh();
		}
		catch (e) {
			this.alertService.error(this.i18n.tr("InfoStatement.SubmitError"));
		}
		finally {
			this.alertService.hideProgress();
		}
	}

	async submitted() {
		this.alertService.hideProgress();
		await this.alertService.alert(this.i18n.tr("InfoStatement.SubmitSuccess"), "done", "green");
		this.router.navigateToRoute(app.Route.home);
	}

	refresh() {
		this.router.navigateToRoute(app.Route.infoStatement, <IHaveGuid>{ guid: this.request.guid }, { replace: true });
	}

	async pay() {
		let tokenData: app.IPaymentTokenInfo;
		try {
			tokenData = await this.creditCard.getPaymentTokenInfo();
			this.logger.debug("tokenData", tokenData);
		}
		catch (e) {
			this.alertService.error("Please make sure credit card details are correct");
			return;
		}

		this.alertService.showProgress();

		try {
			if (await this.paymentClient.doIndeterminateInfoStatementPaymentsExist(this.request.guid) &&
				!await this.alertService.confirm("Possible duplicate payment attempt. Would you like to continue?", "warning", "orange")) {
				return;
			}

			let payment = await this.paymentClient.takeInfoStatementPayment(new app.TakeInfoStatementPaymentRequest({
				webRequestGuid: this.request.guid,
				paymentTokenInfo: new app.PaymentTokenInfo({
					token: tokenData.token,
					cardholderName: tokenData.cardholderName,
					cardScheme: tokenData.cardScheme,
					maskedCardNumber: tokenData.maskedCardNumber,
					surchargePercentage: tokenData.surchargePercentage
				})
			}));
			this.setLastPayment(payment);
			switch (payment.status) {
				case app.PaymentStatus.Approved: await this.submitted(); break;
				default: this.refresh();
			}
		}
		catch (e) {
			this.alertService.error(e.message);
		}
		finally {
			this.alertService.hideProgress();
		}
	}

	selectTab(tab: string) {
		if (!this.isReadonly) {
			document.querySelector(`#${tab}-header`).classList.remove("disabled");
		}
		this.tabs.select(tab);
		if (!this.isReadonly) {
			document.querySelector(`#${tab}-header`).classList.add("disabled");
		}

		// bring headers into view
		this.tabsElement.scrollIntoView();
		var scrolledY = window.scrollY;

		if (scrolledY) {
			window.scroll(0, scrolledY - au.DOM.querySelector("md-navbar>div").clientHeight);
		}
	}

	unselectTitle() {
		this.request.infoStatement.titles.forEach(x => (<any>x).isSelected = false);
	}

	unselectOwner() {
		this.owners.forEach(x => (<any>x).isSelected = false);
	}

	unselectPurchaser() {
		this.purchasers.forEach(x => (<any>x).isSelected = false);
	}

	selectTitle(t: app.WebRequestInfoStmtTitle) {
		this.unselectTitle();
		(<any>t).isSelected = true;
		// this is needed to make checkboxes work
		return true;
	}

	selectOwner(p: app.WebRequestInfoStmtPorg) {
		this.unselectOwner();
		(<any>p).isSelected = true;
		return true;
	}

	selectPurchaser(p: app.WebRequestInfoStmtPorg) {
		this.unselectPurchaser();
		(<any>p).isSelected = true;
		return true;
	}

	addTitle() {
		this.unselectTitle();
		let t = new app.WebRequestInfoStmtTitle();
		(<any>t).isSelected = true;
		this.request.infoStatement.titles.push(t);
	}

	removeTitle(t: app.WebRequestInfoStmtTitle) {
		this.request.infoStatement.titles.splice(this.request.infoStatement.titles.indexOf(t), 1);
		this.validationController.removeObject(t);
	}

	addOwner() {
		this.unselectOwner();
		let p = app.WebRequestInfoStmtPorg.fromJS({ Owner_flag: true });
		(<any>p).isSelected = true;
		this.owners.push(p);
	}

	removeOwner(p: app.WebRequestInfoStmtPorg) {
		this.owners.splice(this.owners.indexOf(p), 1);
	}

	addPurchaser() {
		this.unselectPurchaser();
		let p = new app.WebRequestInfoStmtPorg();
		(<any>p).isSelected = true;
		this.purchasers.push(p);
	}

	removePurchaser(p: app.WebRequestInfoStmtPorg) {
		this.purchasers.splice(this.purchasers.indexOf(p), 1);
	}

	setLastPayment(p: app.Payment) {
		this.lastPayment = p;
		if (p) {
			this.paymentStatus = this.paymentService.getStatusSummary(p);
		}
	}

}