import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class BulkCustomer {
	constructor(private accountClient: app.AccountClient, private alertService: at.AlertService, private invoiceService: app.InvoiceService, private router: au.Router) {
	}

	accountSummaries: app.AccountBalanceInfo[] = [];
	Routes = app.Route;

	@au.observable
	activeOnly: boolean;
	async activeOnlyChanged() {
		if (this.activated) {
			this.load();
		}
	}

	activated: boolean;
	async activate() {
		this.activeOnly = true;
		await this.load();
		await this.invoiceService.checkEBilling(this.accountSummaries);
		this.activated = true;
	}

	async load() {
		return this.alertService.usingProgress(async () => {
			this.accountSummaries = await this.accountClient.getAccountSummaries(this.activeOnly, false);
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}

	goToAccount(a: app.AccountBalanceInfo) {
		this.router.navigateToRoute(app.Route.account, { pkAccount: a.pkAccount });
	}
}