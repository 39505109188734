import * as app from "app";

export class AccountBalanceInfoToInvoiceMethodValueConverter {
	toView(value: app.AccountBalanceInfo | app.AccountInfo): string {
		switch (value.fkInvoicePrintType) {
			case "EMAIL":
				return "Email";
			case "PRINT":
			case "NOPRINT":
			default:
				return `Post - <a href="#/${app.Route.emailInvoices}?id=${value.pkAccount}">Switch to email bills now</a>`;
		}
	}

	fromView(value: string): number {
		throw new Error("Not implemented");
	}
}