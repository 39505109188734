import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

export abstract class EditBase {
	constructor(protected alertService: at.AlertService, protected validationControllerFactory: at.ValidationControllerFactory, protected authService: app.IAuthService) {
		this.validationController = validationControllerFactory.createForCurrentScope();
	}

	isChanged: boolean = false;
	id: string;
	validationController: au.ValidationController;
	isSaving: boolean;
	isReverting: boolean;
	isDeleting: boolean;

	async activate(params: IHaveId, routeConfig: au.RouteConfig): Promise<any> {
		this.id = params.id;
		await this.revert();
	}

	async canDeactivate(): Promise<boolean> {
		if (this.isChanged && await this.authService.isAuthenticatedAsync()) {
			let response = await this.alertService.confirm("You have unsaved changes. Would you like to leave the page?");
			this.isChanged = !response; // reset the flag so that redirects do not trigger the message again
			return response;
		}
		else {
			return true;
		}
	}

	changed() {
		this.isChanged = true;
	}

	async abstract load(id: string);

	async abstract saveInternal(): Promise<string>;

	async save() {
		let errors = await this.validationController.validate();
		if (!errors.valid) {
			return;
		}

		await this.alertService.usingProgress(async () => {
			this.id = await this.saveInternal();
			this.isChanged = false;
		}, e => this.alertService.error(e.message ? e.message : "Error occurred while saving changes"))
	}

	async revert() {
		await this.alertService.usingProgress(async () => {
			this.isReverting = true;
			await this.load(this.id);
			this.validationController.reset();
			this.isChanged = false;
		}, e => this.alertService.error("Error occurred while loading the page"))
	}
}