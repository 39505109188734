import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

declare module "aurelia-materialize-bridge/dist/commonjs/augmentation/element" {
	interface IElementAu {
		"settings-editor": IController<any>;
	}
}

@au.autoinject
export class EditSettings {
	constructor(private settingsClient: app.SettingsClient, private alertService: at.AlertService, private authService: app.IAuthService) { }

	info: app.SettingInfo;
	editor: any;

	async activate() {
		await this.alertService.usingProgress(async () => {
			let response = await this.settingsClient.getDynamicSettings();
			this.info = response.info;
		}, e => this.alertService.error(e));
	}

	async canDeactivate() {
		var settings = this.editor.settings as at.SettingVM[];
		if (!settings || !await this.authService.isAuthenticatedAsync()) {
			return true;
		}
		let editedInput: HTMLElement;
		for (var s of settings) {
			editedInput = this.isSettingVMEdited(s);
			if (editedInput) {
				break;
			}
		}
		if (editedInput) {
			if (!await this.alertService.confirm("Would you like to cancel unsaved changes and leave the page?", "warning", "orange")) {
				editedInput.focus();
				editedInput.scrollIntoView();
				// adjust for header hight
				window.scrollBy(0, -70);
				return false;
			}
		}

	}

	isSettingVMEdited(s: at.SettingVM): HTMLElement {
		if (s.isEdited) {
			let input = (s.variantEditor.querySelector("input,textarea") as HTMLElement);
			return input;
		}
		else if (s.isObject) {
			var editorVM = s.editor.au["settings-editor"].viewModel;
			var settings = editorVM.settings as at.SettingVM[];
			if (settings) {
				for (var x of settings) {
					let input = this.isSettingVMEdited(x);
					if (input) {
						if (!s.isExpanded) {
							s.toggleExpanded();
						}
						return input;
					}
				}
			}
		}
		return undefined;
	}

	async save(s: at.SettingVM) {
		await s.save(async (key, value) => {
			await this.settingsClient.updateDynamicSetting(new app.UpdateDynamicSettingRequest({ key, value }));
		});
	}

	histories: app.SettingHistory[];
	historyModal: au.MdModal;
	async history(s: at.SettingVM) {
		await this.alertService.usingProgress(async () => {
			this.histories = await this.settingsClient.getHistory(s.key);
			this.historyModal.open();
		}, e => this.alertService.error(e));
	}

	inputs: HTMLTextAreaElement[] = [];
	copy(input: HTMLTextAreaElement) {
		input.select();
		document.execCommand("copy");
	}
}