import "isomorphic-fetch";
import "check-browser";
import "materialize-css";
import "aurelia-bootstrapper";
import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";
import "./helpers/append-polyfill";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-annotation";
import "aurelia-materialize-bridge/dist/native-modules/augmentation/aurelia-typed-observable";
import { PLATFORM } from "./aurelia";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build

export async function configure(aurelia: au.Aurelia) {
	let globalResources = [
		"attributes/toast-hint",
		"converters/balance",
		"converters/invoice-type",
		"converters/balance-status-to-colour",
		"converters/account-balance-info",
		"converters/account-balance-info-to-class",
		"converters/account-balance-info-to-invoice-method",
		"converters/account-balance-info-to-due-text",
		"converters/role-to-menu-size",
		"converters/payment-status",
		"converters/debt-option",
		"converters/account-summaries-filter",
		"custom-elements/app-colours/app-colours",
		"custom-elements/edit-page-buttons/edit-page-buttons",
		"custom-elements/edit-address/edit-address",
		"custom-elements/select-account/select-account",
		"custom-elements/tenant/tenant",
		"custom-elements/last-tenancy-requests/last-tenancy-requests",
		"custom-elements/info-statement-porg/info-statement-porg",
		"custom-elements/info-statement-title/info-statement-title",
		"custom-elements/quick-stream/quick-stream",
		"custom-elements/bpoint/bpoint",
		"custom-elements/credit-card/credit-card",
		"custom-elements/payment/payment",
		"custom-elements/pay-arrangement/pay-arrangement",
		"custom-elements/payment-status/payment-status",
		"custom-elements/date-range/date-range",
		"custom-elements/dashboard-card/dashboard-card",
		"custom-elements/script-injector/script-injector",
		"views/admin-dashboard/widgets/registrations-widget",
		"views/admin-dashboard/widgets/running-registrations-widget",
		"views/admin-dashboard/widgets/web-requests-widget",
		"views/admin-dashboard/widgets/page-visits-widget",
		"views/admin-dashboard/widgets/payments-widget",
		"views/admin-dashboard/widgets/payments-count-widget",
	];

	if (IS_DEV_BUILD) {
		aurelia.use.developmentLogging();
	}

	// since at.AuthService inherits au.AuthService the following makes sure that only one instance exists in the container
	// the placement is critical because the service gets created during aurelia-authentication configuration
	let atAuthService = aurelia.container.get(at.AuthService);
	aurelia.container.registerInstance(au.AuthService, atAuthService);

	aurelia
		.use
		.standardConfiguration()
		.plugin(PLATFORM.moduleName("aurelia-inputmask"), { showMaskOnHover: false })
		.plugin(PLATFORM.moduleName("aurelia-validation"))
		.plugin(PLATFORM.moduleName("aurelia-materialize-bridge"), bridge => {
			bridge.useAll();
		})
		.plugin(PLATFORM.moduleName("aurelia-authentication"), c => c.configure({
			baseUrl: null, // important for correct relative URLs below
			loginUrl: "/auth/generateToken",
			storageKey: `${location.origin}${location.pathname}${location.pathname.endsWith("/") ? "" : "/"}_token_v3`.toLowerCase(),
			accessTokenProp: "token",
			refreshTokenUrl: "/auth/refreshToken",
			refreshTokenProp: "refreshToken",
			useRefreshToken: true,
			autoUpdateToken: true,
			profileUrl: "/user/getSelf",
			expiredRedirect: "#/expired",
			logoutOnInvalidToken: true,
			storageChangedReload: true
		} as au.BaseConfig))
		.plugin(PLATFORM.moduleName("aurelia-chart"))
		.plugin(PLATFORM.moduleName("aurelia-i18n"), (instance: au.I18N) => {
			(instance.i18next as any).use(au.I18NextXhrBackend);
			return instance.setup({
				backend: { loadPath: "./locale/{{lng}}" },
				lng: "en",
				fallbackLng: false,
				debug: false,
				interpolation: {
					format: function (value: any, format: string, lng: string) {
						if (format === "currency") {
							return au.numeral(value).format("$0,0.00");
						}
						if (value instanceof Date) {
							let parts = format.split(":");
							return au.moment(value).add(parts.length > 1 ? parseInt(parts[1]) : 0, "days").format(parts[0]);
						}
						return value;
					}

				}
			});
		})
		.plugin(PLATFORM.moduleName("aurelia-toolkit"))
		.globalResources(globalResources);

	au.ato.usePropertyTypeForObservable(true);

	aurelia.container.autoRegister(at.IServerDateProvider, app.ServerDateProvider);
	aurelia.container.registerInstance(ApplicationInsights, new ApplicationInsights({ config: { instrumentationKey: undefined } }));
	await aurelia.start();
	au.ValidationMessageProvider.prototype.getDisplayName = function () { return "" };
	for (let key in au.validationMessages) {
		let message = au.validationMessages[key];
		if (message.endsWith(".")) {
			au.validationMessages[key] = message.substr(0, message.length - 1);
		}
	}
	au.moment.locale("en-AU");
	// TODO not sure if this is needed
	// await new Promise(resolve => $(document).ready(() => resolve()));
	aurelia.setRoot("views/root/root");
}
