import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class AdminDashboard {
	constructor(private diagnosticsClient: app.DiagnosticsClient, private alertService: at.AlertService) { }

	versions: app.GetVersionsResponse;

	async activate() {
		await this.alertService.usingProgress(async () => {
			this.versions = await this.diagnosticsClient.getVersions();
		}, e => this.alertService.criticalError(app.Strings.errorWhileLoadingPage, e));
	}
}