import * as au from "aurelia";
import * as app from "app";
import * as at from "aurelia-toolkit";

@au.autoinject
export class ServerDateProvider implements at.IServerDateProvider {
	constructor(private dateClient: app.DateClient) {

	}

	async getServerDate(): Promise<Date> {
		return au.moment((await this.dateClient.getServerDate()).date).toDate();
	}
}